import { Component, OnInit } from '@angular/core';
import { AccountService, Categories, Channels, Languages, PushNotificationService, UsersService } from '@app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-add-push-notification',
  templateUrl: './add-push-notification.component.html',
  styleUrls: ['./add-push-notification.component.less']
})
export class AddPushNotificationComponent implements OnInit {
  titleAlert: string = '* This field is required';
  routeUrl: any;
  submitted: boolean = false;
  
  accountType: any[] = [
    { id:1, name: "PERSONAL"},
    { id:2, name: "BUSINESS"},
    { id:3, name: "GOOGLE"}
  ]
  deviceType: any[] = [
    { id:1, name: "ALL"},
    { id:2, name: "IOS"},
    { id:3, name: "ANDROID"}
  ]
  callToActionType: any[] = [
    { id:1, name: "TRADE"},
    { id:2, name: "SOCIAL"},
    { id:3, name: "BUSINESS"},
    { id:4, name: "CHAT"},
    { id:5, name: "PROFILE"},
    { id:6, name: "SET_LOCATION"},
  ]

  status: any[] = [
    { id:1, name: "ACTIVE"},
    { id:2, name: "INACTIVE"},
    { id:2, name: "COMPLETED"},
    { id:2, name: "DELETED"}
  ]
  languages: Languages[];
  channels: Channels[];
  categories: Categories[];
  countries: any[];
  currentPage: number = 0;

  showDropdown:boolean = false;
  clickedOptions:any = [];
  toBeSentToIds: any = [];
  users: any;
  notificationId: string;
  action: string = "add";
  membersList: any;
  constructor( private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private pushNotification: PushNotificationService,
    private userServices: UsersService
  ) {
    this.routeUrl = this.router.url;
  }
  addEditForm: FormGroup;
  ngOnInit() {
    
    this.addEditForm = this.fb.group({
      name: ['', Validators.required],
      title: ['', Validators.required],
      content: ['', Validators.required],
      scheduledAt: ['', Validators.required],
      toBeSentToIds: [null],
      callToAction: ['TRADE', Validators.required],
      deviceType: ['ALL', Validators.required],
      iso3CountryCode: ['IND', Validators.required],
      accountType: ['PERSONAL', Validators.required],
      channelType: ['PUSH', Validators.required],
      // businessCategory: [null],
      status: ['ACTIVE', Validators.required],
      translations: ['English', Validators.required]
    })
    this.pushNotification.getCountryList().subscribe((data) => {
      this.languages = [...data.languages];
      this.channels = [...data.channels];
      this.categories = [...data.categories];
      this.countries = [...data.countries];
    });

    this.userServices.getMembers(this.currentPage)
      .pipe(first())
        .subscribe({
            next: (users) => {
                this.users = users;
            },
            error: error => {
                console.log(error);
            }
    });

    this.route.queryParams.subscribe(params => {
      this.notificationId = params['id'];
    });
    console.log(("Notification Id : "+ this.notificationId));

    if(this.notificationId !== null && this.notificationId !== '' && this.notificationId !== undefined){
      this.action = "edit";
      let notification = JSON.parse(localStorage.getItem('notification'))
      // console.log("Notification", notification);
      var d = new Date(notification?.scheduledAt);
      d.setHours(d.getHours() + 5);
      d.setMinutes(d.getMinutes() + 30);
      this.addEditForm.get('name').setValue(notification?.name);
      this.addEditForm.get('title').setValue(notification?.title);
      this.addEditForm.get('content').setValue(notification?.content);
      this.addEditForm.get('scheduledAt').setValue(this.getFormattedDate(d));
      this.addEditForm.get('callToAction').setValue(notification?.callToAction);
      this.addEditForm.get('deviceType').setValue(notification?.deviceType);
      this.addEditForm.get('iso3CountryCode').setValue(notification?.iso3CountryCode);
      this.addEditForm.get('translations').setValue(notification?.translations[0]?.name);
      this.addEditForm.get('toBeSentToIds').setValue(notification?.toBeSentToIds);
      this.addEditForm.get('status').setValue(notification?.status);
      this.addEditForm.get('channelType').setValue(notification?.channelType);
      this.addEditForm.get('accountType').setValue(notification?.accountType);
      for(let i in notification?.toBeSentToIds){
        // console.log("Id :"+notification?.toBeSentToIds[i]);
        this.userServices.getMemberById(notification?.toBeSentToIds[i])
                .subscribe(x => this.clickedOptions.push(x),
                error => {  console.log(error);  });
      }
    }
  }

  get f(){
    return this.addEditForm.controls;
  }

  searchTargetUsers(value:any){
    this.userServices.topSearch(value)
    .subscribe({
       next:(data) => {
         console.log('ley',data);
         this.toBeSentToIds = data["members"];
     },
     error: error => {
         console.log("name search error ::: ", error);
     }
    });
  }

  onSubmit(): void {
    console.log(this.action);
    
    let givenDatetime = this.addEditForm?.value?.scheduledAt;
    
    // alert(this.addEditForm.value?.translations)
    // let language = this.addEditForm.value?.translations;
    let obj = this.languages.filter(x => x?.name === this.addEditForm.value?.translations);
    var d = new Date(givenDatetime);
    d.setHours(d.getHours() - 5);
    d.setMinutes(d.getMinutes() - 30);
    this.submitted = true;
    if(this.addEditForm.valid){
      const memberIds = this.clickedOptions.map(obj => obj.memberId);
      let formData = this.addEditForm?.value;
      formData["toBeSentToIds"] = memberIds;
      formData["scheduledAt"] = this.getFormattedDate(d)
      formData["translations"] = obj;
      if(this.action === 'add'){
        this.pushNotification.postPushNotification(this.addEditForm.value)
       .subscribe({
          next:(data) => {
            console.log(data);
            this.snackBar.open("Notification saved successfully!", "", {
              duration: 2000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: ['Red']
            });
            this.router.navigate(['/push-notification'])
        },
        error: error => {
            this.snackBar.open("Something went wrong, please try again later", "", {
              duration: 2000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: ['Red']
            });
            console.log(error);
        }
       });
      }
      else if(this.action === 'edit'){
        formData["notificationId"] = this.notificationId;
        this.pushNotification.updatePushNotification(this.addEditForm.value, this.notificationId)
          .subscribe({
              next:(data) => {
                console.log(data);
                this.snackBar.open("Notification updated successfully!", "", {
                  duration: 2000,
                  horizontalPosition: 'left',
                  verticalPosition: 'bottom',
                  panelClass: ['Red']
                });
                this.router.navigate(['/push-notification'])
            },
            error: error => {
                this.snackBar.open("Something went wrong, please try again later", "", {
                  duration: 2000,
                  horizontalPosition: 'left',
                  verticalPosition: 'bottom',
                  panelClass: ['Red']
                });
                console.log(error);
            }
          });
      }
      
      // console.log(JSON.stringify(formData));
      
       
    }
  }

  getFormattedDate(originalDateString) {
    // Create a Date object from the string
    var originalDate = new Date(originalDateString);

    // Extract date components
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1 and pad with zeros
    var day = originalDate.getDate().toString().padStart(2, '0');
    var hours = originalDate.getHours().toString().padStart(2, '0');
    var minutes = originalDate.getMinutes().toString().padStart(2, '0');

    // Create the formatted date string
    var formattedDateString = `${year}-${month}-${day}T${hours}:${minutes}`;

    console.log(formattedDateString);
    return formattedDateString;
}

  

  cancel(){
    this.router.navigate(['/push-notification'])
  }
  logout() {
    this.accountService.logout();
  }

  // selectedValue: string;

  // onValueChange(newValue: string) {
  //   // newValue contains the value typed in the search box
  //   console.log('Value changed to:', newValue);
  //   console.log(this.selectedValue);
    
  // }

  getTargetUsersList(event:any){
    let value = event.target.value;
    if(value !== ''){
      this.showDropdown = true;
      this.searchTargetUsers(value)
      // this.userService.topSearch(event.target.value).subscribe((result: any) => {
      //   this.searchlist = result?.members
      // });
    } else {
      this.showDropdown = false;
    }
  }

  selectName(item: any) {
    this.showDropdown = false;
    const result = this.findObjectInList(this.clickedOptions, item);
    if (result === false) {
      this.clickedOptions.push(item);
    } else {
      const index = this.clickedOptions?.indexOf(item);
      if (index !== -1) {
        this.clickedOptions?.splice(index, 1);
      }
    }
     const targetUsersInput = document.getElementById('targetUsers') as HTMLInputElement;
    if (targetUsersInput) {
      targetUsersInput.value = null;
    }
  }

  findObjectInList(list, targetObject) {
    return list.some(obj => obj.memberId === targetObject.memberId);
  }

  isItemExists(item: any){
    let flag = this.clickedOptions?.find(x => x?.memberId === item.memberId)
    return flag;
  }

  removeOption(option: string) {
    const index = this.clickedOptions.indexOf(option);
    if (index !== -1) {
      this.clickedOptions.splice(index, 1);
    }
  }

}


