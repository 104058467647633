import { Component, ViewChild } from '@angular/core';
import { User } from '@app/_models';
import { AccountService, UsersService } from '@app/_services';
import { filter, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({ templateUrl: 'user.component.html' })
export class UserComponent {
    users:any;
    ELEMENT_DATA: USER[] = [];
    // dataSource;
    // displayedColumns = [];
    @ViewChild(MatSort) sort: MatSort;
    // @ViewChild(MatPaginator) paginator: MatPaginator;

    isLoading = false;
    totalRows = 0;
    pageSize = 20;
    currentPage = 0;
    pageSizeOptions: number[] = [20, 50, 75, 100];
    routeUrl:any;

    displayedColumns: string[] = ['userName', 'name', 'countryCode', 'mobileNo', 'emailId', 'accountType', 'createdAt', 'lastLoggedInAt', 'timezone', 'device.deviceType', 'memberId'];
    dataSource: MatTableDataSource<USER> = new MatTableDataSource();

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    constructor(
        private userServices: UsersService,
        private accountService: AccountService,
        private snackBar: MatSnackBar,
        private router: Router
    ) {
        this.routeUrl = this.router.url;
    }

    ngOnInit() {
        this.createTable();
    }

    createTable() {
        this.isLoading = true;
        this.userServices.getMembers(this.currentPage)
            .pipe(first())
            .subscribe({
                next: (users) => {
                    this.users = users;
                    this.dataSource.data = this.users.data;
                    setTimeout(() => {
                        this.paginator.pageIndex = this.currentPage;
                        this.paginator.length = this.users.recordsTotal;
                    });
                    this.isLoading = false;
                },
                error: error => {
                    console.log(error);
                    this.isLoading = false;
                }
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
        // this.dataSource.filter = filterValue.trim().toLowerCase();
        this.isLoading = true;
        // let filter = 'userName:eq:'+filterValue;
        this.userServices.getMembersFilter(this.currentPage, filterValue)
            .pipe(first())
            .subscribe({
                next: (users) => {
                    this.users = users;
                    this.dataSource.data = this.users.data;
                    setTimeout(() => {
                        this.paginator.pageIndex = this.currentPage;
                        this.paginator.length = this.users.recordsTotal;
                    });
                    this.isLoading = false;
                },
                error: error => {
                    console.log(error);
                    this.isLoading = false;
                }
        });
    }

    deleteRecord(id) {
        this.isLoading = true;
        this.userServices.deleteMember(id)
            .pipe(first())
            .subscribe({
                next: (records) => {
                    this.snackBar.open('User Deleted Successfully', '', {
                        panelClass: 'snackbarcls',
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 3000,
                    });                  
                    this.createTable();
                    this.isLoading = false;
                },
                error: error => {
                    console.log(error);
                    this.isLoading = false;
                }
        });
    }

    pageChanged(event: PageEvent) {
        console.log({ event });
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.createTable();
    }

    logout() {
        this.accountService.logout();
    }
}

interface USER {
    userName: string,
    name: string,
    countryCode: string,
    mobileNo: number,
    emailId: string,
    accountType: string,
    createdAt: string,
    lastLoggedInAt:  string,
    timezone: string,
    device: string,
    memberId: string
}