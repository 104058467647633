import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { HomeComponent } from './home';
import { UserComponent } from './user-component';
import { TradeComponent } from './trade-component';
import { SocialComponent } from './social-component';
import { PostReportComponent } from './postreport-component';
import { ImageViolationComponent } from './imageviolation-component';
import { AnalyticsComponent } from './analytics-component';
import { EngagementComponent } from './engagement-component';
import { AuthGuard } from './_helpers';
import { BusinessBannersComponent } from './business-banners/business-banners.component';
import { AddEditBusinessBannerComponent } from './add-edit-business-banner/add-edit-business-banner.component';
import { SocialBannersComponent } from './social-banners/social-banners.component';
import { TradeBannersComponent } from './trade-banners/trade-banners.component';
import { PushNotificationListComponent } from './push-notification-list/push-notification-list.component';
import { AddPushNotificationComponent } from './add-push-notification/add-push-notification.component';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const usersModule = () => import('./users/users.module').then(x => x.UsersModule);

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'users', component: UserComponent, canActivate: [AuthGuard] },
    { path: 'trade', component: TradeComponent, canActivate: [AuthGuard] },
    { path: 'social', component: SocialComponent, canActivate: [AuthGuard] },
    { path: 'post-report', component: PostReportComponent, canActivate: [AuthGuard] },
    { path: 'image-violation', component: ImageViolationComponent, canActivate: [AuthGuard] },
    { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuard] },
    { path: 'engagement', component: EngagementComponent, canActivate: [AuthGuard] },
    { path: 'social-banners', component: SocialBannersComponent, canActivate: [AuthGuard]},
    { path: 'trade-banners', component: TradeBannersComponent, canActivate: [AuthGuard]},
    { path: 'business-banners', component: BusinessBannersComponent, canActivate: [AuthGuard] },
    { path: 'push-notification', component: PushNotificationListComponent, canActivate: [AuthGuard] },
    { path: 'add-push-notification', component: AddPushNotificationComponent, canActivate: [AuthGuard] },
    { path: 'add-edit-business-banner', component: AddEditBusinessBannerComponent },
    // { path: 'users', loadChildren: usersModule, canActivate: [AuthGuard] },
    { path: 'account', loadChildren: accountModule },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }