<div class="header navbar-fixed-top">
    <nav class="p-x-0">
        <div class="container-fluid p-x-0">
            <div class="navbar-header">
                <a class="navbar-brand pa-0" href="#"><span class="nav-toggle-bar"><img src="assets/images/logo.png"
                            alt="MeeKet"></span></a>
                <h2>Trade Banners</h2>
            </div>
            <ul class="nav navbar-nav right-nav pull-right">
                <li class="prof-dropdown">
                    <div class="btn-group">
                        <a href="" data-toggle="dropdown" class="top-link dropdown-toggle">
                            <span>
                                <img src="assets/images/avatar.png" alt="" class="pull-left">
                                <!-- <i class="fa fa-user pull-left" aria-hidden="true"></i> -->
                            </span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" role="menu">
                            <li><a (click)="logout()"><i class="fa fa-sign-out" aria-hidden="true"></i> Sign out</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</div>

<div class="sidenav menu-bar active">
    <a routerLink="/home" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/' || routeUrl === '/home'}">
        <span title="Dashboard"><i class="fa fa-tachometer" aria-hidden="true"></i> <span>Dashboard</span></span>
    </a>
    <a routerLink="/users" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/users'}">
        <span title="Users"><i class="fa fa-user" aria-hidden="true"></i> <span>Users</span></span>
    </a>
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/analytics' || routeUrl === '/engagement'}">
            <span title="Engagement">
                <i class="fa fa-pie-chart" aria-hidden="true"></i>
                <span>Engagement</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/analytics" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/analytics'}">
                <span title="Engagement Analytics"><i class="fa fa-line-chart" aria-hidden="true"></i>
                    <span>Analytics</span></span>
            </a>
            <a routerLink="/engagement" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/engagement'}">
                <span title="Engagement Overview"><i class="fa fa-clock-o" aria-hidden="true"></i>
                    <span>Overview</span></span>
            </a>
        </div>
    </div>
    <a routerLink="/trade" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/trade'}">
        <span title="Trade"><i class="fa fa-heart" aria-hidden="true"></i> <span>Trade</span></span>
    </a>
    <a routerLink="/social" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/social'}">
        <span title="Social"><i class="fa fa-dot-circle-o" aria-hidden="true"></i> <span>Social</span></span>
    </a>
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/post-report' || routeUrl === '/image-violation'}">
            <span title="Post">
                <i class="fa fa-square" aria-hidden="true"></i>
                <span>Post</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/post-report" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/post-report'}">
                <span title="Report"><i class="fa fa-file-archive-o" aria-hidden="true"></i> <span>Report</span></span>
            </a>
            <a href="#">
                <span title="Content"><i class="fa fa-file-text" aria-hidden="true"></i> <span>Content</span></span>
            </a>
            <a routerLink="/image-violation" routerLinkActive="on"
                [ngClass]="{'active': routeUrl === '/image-violation'}">
                <span title="Image Violations"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Image
                        Violations</span></span>
            </a>
        </div>
        
    </div>

     <!--- Deva-->
     <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/post-report' || routeUrl === '/image-violation'}">
            <span title="Banners">
                <i class="fa fa-folder-open" aria-hidden="true"></i>
                <span>Banners</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/business-banners" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/business-banners'}">
                <span title="Business-Banners"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Business-Banners</span></span>
            </a>
            <a routerLink="/trade-banners" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/trade-banners'}">
                <span title="Trade-Banners"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Trade-Banners</span></span>
            </a>
            <a routerLink="/social-banners" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/social-banners'}">
                <span title="Social-Banners"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Social-Banners</span></span>
            </a>
            <!-- <a routerLink="/add-edit-business-banner" routerLinkActive="on" >
                <span title=" Add-business-banner"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span> add-business-banner</span></span>
            </a> -->
           
        </div>
    </div>
</div>
<!-- <button (click)="router.navigate(['/master']);">
        <span>Go to New Page</span>
     </button> -->

<section id="intro" class="page-sec active" style="display: flex;flex-direction: column;min-width: 300px;">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="SearchPanel">
        <div class="SearchCover">
            <mat-form-field appearance="standard">
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
            </mat-form-field>
        </div>
        <div class="addEditCover">
            <button (click)="addBusinessBanner($event)" type="button" id="add-busines"
                mat-raised-button color="primary" style="float: right">Add New</button>

            <!-- <a routerLink="/add-edit-business-banner" routerLinkActive="on">
                <span title=" Add-business-banner"><span> Add New</span></span>
            </a> -->
        </div>
    </div>

    <table mat-table [dataSource]="dataSource">


        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element">
                <!-- <img src="{{element.images[0].url}}" width="64"/> -->{{element.type}}
            </td>
        </ng-container>
        <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef> Image </th>
            <td mat-cell *matCellDef="let element">
                <img src="{{element.url}}" width="64" />
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element"><i (click)="editBusinessBanner(element)" class="fa fa-pencil fa-lg" style="color:rgb(89, 31, 223);cursor: pointer;"></i>
                &nbsp; <i (click)="deleteBusinessBanner($event, element)" class="fa fa-trash-o fa-lg" style="color:red;cursor: pointer;"></i> </td>
        </ng-container>

        <!-- <ng-container matColumnDef="listingId">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element" class="text-right"> 
                <div class="detail-sec">
                    <span class="more-icon dropdown-toggle" data-toggle="dropdown">
                    </span>
                    <ul class="dropdown-menu" role="menu"><li>
                        <a (click)="deleteRecord(element.listingId)">
                            <i class="fa fa-trash" aria-hidden="true"> 
                            </i> Delete</a>
                        </li>
                        </ul></div> </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
    </mat-paginator>

</section>
