import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Business } from '../_models/banner';

@Injectable({ providedIn: 'root' })
export class BannerService {
    private userSubject: BehaviorSubject<Business>;
    public user: Observable<Business>;
    localData: any;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        // this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        // this.user = this.userSubject.asObservable();
        this.localData = JSON.parse(localStorage.getItem('user'));
    }

    // public get userValue(): User {
    //     return this.userSubject.value;
    // }
    getpushnotifictionList(pageNo, pageSize){
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<Business[]>(`${environment.apiUrl}/api/v1.0/schedules/notification?page=${pageNo}&size=${pageSize}`, { headers });
    }

    getBusinessList() {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<Business[]>(`${environment.apiUrl}/api/v1.0/admin/settings?bannerType=BUSINESS&status=ACTIVE`, { headers });
    }

    getsocialList() {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<Business[]>(`${environment.apiUrl}/api/v1.0/admin/settings?bannerType=SOCIAL&status=ACTIVE`, { headers });
    }

    gettradeList() {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<Business[]>(`${environment.apiUrl}/api/v1.0/admin/settings?bannerType=TRADE&status=ACTIVE`, { headers });
    }

    FileUpload(FileObj) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.post<any[]>(`${environment.apiUrl}/api/v1.0/files`, FileObj, { headers });
    }

    saveBusinessItem(Params) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.put<any[]>(`${environment.apiUrl}/api/v1.0/admin/settings/`+ Params.appSettingId +`?action=ADD`, Params, { headers });
    }

    editBusinessItem(Params) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.put<any[]>(`${environment.apiUrl}/api/v1.0/admin/settings/`+ Params.appSettingId +`?action=UPDATE`, Params, { headers });
    }

    deleteBusinessItem(Params) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.put<any[]>(`${environment.apiUrl}/api/v1.0/admin/settings/`+ Params.appSettingId +`?action=DELETE`, Params, { headers }); 
    }
}