<!-- <div class="p-4">
    <div class="container">
        <h1>Hi {{user.firstName}}!</h1>
        <p>You're logged in with Angular 10!!</p>
        <p><a routerLink="/users">Manage Users</a></p>
    </div>
</div> -->

<div class="header navbar-fixed-top">
    <nav class="p-x-0">
      <div class="container-fluid p-x-0">
        <div class="navbar-header">
            <a class="navbar-brand pa-0" href="#"><span class="nav-toggle-bar"><img src="assets/images/logo.png" alt="MeeKet"></span></a>
            <h2>Trade</h2>
        </div>
        <ul class="nav navbar-nav right-nav pull-right">
            <li class="prof-dropdown">
                <div class="btn-group">
                    <a href="" data-toggle="dropdown" class="top-link dropdown-toggle">
                        <span>
                            <img src="assets/images/avatar.png" alt="" class="pull-left">
                            <!-- <i class="fa fa-user pull-left" aria-hidden="true"></i> -->
                        </span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                        <li><a (click)="logout()"><i class="fa fa-sign-out" aria-hidden="true"></i> Sign out</a></li>
                    </ul>
                </div>
            </li>
        </ul>
      </div>
    </nav>
</div>

<div class="sidenav menu-bar active">
    <a routerLink="/home" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/' || routeUrl === '/home'}">
        <span title="Dashboard"><i class="fa fa-tachometer" aria-hidden="true"></i> <span>Dashboard</span></span>
    </a>
    <a routerLink="/users" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/users'}">
        <span title="Users"><i class="fa fa-user" aria-hidden="true"></i> <span>Users</span></span>
    </a>
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/analytics' || routeUrl === '/engagement'}">
            <span title="Engagement">
                <i class="fa fa-pie-chart" aria-hidden="true"></i>
                <span>Engagement</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/analytics" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/analytics'}">
                <span title="Engagement Analytics"><i class="fa fa-line-chart" aria-hidden="true"></i> <span>Analytics</span></span>
            </a>
            <a routerLink="/engagement" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/engagement'}">
                <span title="Engagement Overview"><i class="fa fa-clock-o" aria-hidden="true"></i> <span>Overview</span></span>
            </a>
        </div>
    </div>
    <a routerLink="/trade" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/trade'}">
        <span title="Trade"><i class="fa fa-heart" aria-hidden="true"></i> <span>Trade</span></span>
    </a>
    <a routerLink="/social" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/social'}">
        <span title="Social"><i class="fa fa-dot-circle-o" aria-hidden="true"></i> <span>Social</span></span>
    </a>
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/post-report' || routeUrl === '/image-violation'}">
            <span title="Post">
                <i class="fa fa-square" aria-hidden="true"></i>
                <span>Post</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/post-report" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/post-report'}">
                <span title="Report"><i class="fa fa-file-archive-o" aria-hidden="true"></i> <span>Report</span></span>
            </a>
            <a href="#">
                <span title="Content"><i class="fa fa-file-text" aria-hidden="true"></i> <span>Content</span></span>
            </a>
            <a routerLink="/image-violation" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/image-violation'}">
                <span title="Image Violations"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Image Violations</span></span>
            </a>
        </div>
    </div>
</div>

<style>
    .mat-table {
        overflow: auto;
        max-height: 500px;
    }
</style>
<section id="intro" class="page-sec active" style="display: flex;flex-direction: column;min-width: 300px;">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <mat-form-field appearance="standard">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
    </mat-form-field>
    <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="member.userName">
            <th mat-header-cell *matHeaderCellDef> Username </th>
            <td mat-cell *matCellDef="let element"> {{element.member.userName}} </td>
        </ng-container>

        <ng-container matColumnDef="images[0].url">
        <th mat-header-cell *matHeaderCellDef> Image </th>
        <td mat-cell *matCellDef="let element"> <img src="{{element.images[0].url}}" width="64"/></td>
        </ng-container>

        <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Title </th>
        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td mat-cell *matCellDef="let element"> {{element.category}} </td>
        </ng-container>

        <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef> Price </th>
        <td mat-cell *matCellDef="let element"> {{element.price}} {{element.currency}} </td>
        </ng-container>

        <ng-container matColumnDef="likeCount">
        <th mat-header-cell *matHeaderCellDef> Likes </th>
        <td mat-cell *matCellDef="let element"> {{element.likeCount}} </td>
        </ng-container>

        <ng-container matColumnDef="messageCount">
            <th mat-header-cell *matHeaderCellDef> Chats </th>
            <td mat-cell *matCellDef="let element"> {{element.messageCount}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="listingId">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element" class="text-right"> <div class="detail-sec"><span class="more-icon dropdown-toggle" data-toggle="dropdown"></span><ul class="dropdown-menu" role="menu"><li><a (click)="deleteRecord(element.listingId)"><i class="fa fa-trash" aria-hidden="true"></i> Delete</a></li></ul></div> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
    </mat-paginator>
    <!-- <div class="container-fluid p-x-0">
        <div class="row">
            <div class="col-md-12">
                <div class="content-sec">
                    <div class="col-xs-12 cont-left-box">
                        <div class="col-md-12 p-x-0">
                            <div class="cs-tbl">
                                <table id="customerDatatable" class="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>Registered Date/Time</th>
                                            <th>Status</th>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Theresa
                                            </td>
                                            <td>9876543210</td>
                                            <td>username@gmail.com</td>
                                            <td>Tue, 19-Mar-2019 05:11 pm</td>
                                            <td><span class="open-tag">Not in Use</span></td>
                                            <td class="text-right">
                                                <div class="detail-sec">
                                                    <span class="more-icon dropdown-toggle" data-toggle="dropdown"></span>
                                                    <ul class="dropdown-menu" role="menu">
                                                        <li><a href="#"><i class="fa fa-pencil" aria-hidden="true"></i> Edit</a></li>
                                                        <li><a href="#"><i class="fa fa-trash" aria-hidden="true"></i> Delete</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Leo Peter
                                            </td>
                                            <td>9867543421</td>
                                            <td>username@gmail.com</td>
                                            <td>Tue, 19-Mar-2019 05:11 pm</td>
                                            <td><span class="pending-tag">In Active</span></td>
                                            <td class="text-right">
                                                <div class="detail-sec">
                                                    <span class="more-icon dropdown-toggle" data-toggle="dropdown"></span>
                                                    <ul class="dropdown-menu" role="menu">
                                                        <li><a href="#"><i class="fa fa-pencil" aria-hidden="true"></i> Edit</a></li>
                                                        <li><a href="#"><i class="fa fa-trash" aria-hidden="true"></i> Delete</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Micheal
                                            </td>
                                            <td>9867555421</td>
                                            <td>username@gmail.com</td>
                                            <td>Tue, 19-Mar-2019 05:11 pm</td>
                                            <td><span class="done-tag">Active</span></td>
                                            <td class="text-right">
                                                <div class="detail-sec">
                                                    <span class="more-icon dropdown-toggle" data-toggle="dropdown"></span>
                                                    <ul class="dropdown-menu" role="menu">
                                                        <li><a href="#"><i class="fa fa-pencil" aria-hidden="true"></i> Edit</a></li>
                                                        <li><a href="#"><i class="fa fa-trash" aria-hidden="true"></i> Delete</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                John
                                            </td>
                                            <td>9867543488</td>
                                            <td>username@gmail.com</td>
                                            <td>Tue, 19-Mar-2019 05:11 pm</td>
                                            <td><span class="done-tag">Active</span></td>
                                            <td class="text-right">
                                                <div class="detail-sec">
                                                    <span class="more-icon dropdown-toggle" data-toggle="dropdown"></span>
                                                    <ul class="dropdown-menu" role="menu">
                                                        <li><a href="#"><i class="fa fa-pencil" aria-hidden="true"></i> Edit</a></li>
                                                        <li><a href="#"><i class="fa fa-trash" aria-hidden="true"></i> Delete</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                John Patrick
                                            </td>
                                            <td>7667543421</td>
                                            <td>username@gmail.com</td>
                                            <td>Tue, 19-Mar-2019 05:11 pm</td>
                                            <td><span class="open-tag">Open</span></td>
                                            <td class="text-right">
                                                <div class="detail-sec">
                                                    <span class="more-icon dropdown-toggle" data-toggle="dropdown"></span>
                                                    <ul class="dropdown-menu" role="menu">
                                                        <li><a href="#"><i class="fa fa-pencil" aria-hidden="true"></i> Edit</a></li>
                                                        <li><a href="#"><i class="fa fa-trash" aria-hidden="true"></i> Delete</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</section>