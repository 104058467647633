import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { fakeBackendProvider } from './_helpers';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatSelectModule } from "@angular/material/select";
import { ChartsModule } from 'ng2-charts';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HomeComponent } from './home';
import { UserComponent } from './user-component';
import { TradeComponent } from './trade-component';
import { SocialComponent } from './social-component';
import { PostReportComponent } from './postreport-component';
import { ImageViolationComponent } from './imageviolation-component';
import { AnalyticsComponent } from './analytics-component';
import { EngagementComponent } from './engagement-component';
import { BusinessBannersComponent } from './business-banners/business-banners.component';
import { MatButtonModule } from '@angular/material/button';
import { SocialBannersComponent } from './social-banners/social-banners.component';
import { TradeBannersComponent } from './trade-banners/trade-banners.component';
import { AddEditBusinessBannerComponent } from './add-edit-business-banner/add-edit-business-banner.component';
import {MatToolbarModule} from '@angular/material/toolbar';;
import { ConfirmAlertComponent } from './_components/confirm-alert/confirm-alert.component'
;
import { EditBusinessBannerComponent } from './edit-business-banner/edit-business-banner.component'
;
import { PushNotificationListComponent } from './push-notification-list/push-notification-list.component';
import { AddPushNotificationComponent } from './add-push-notification/add-push-notification.component';
import {NgSelectModule} from '@ng-select/ng-select';
@NgModule({
    imports: [
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        RouterModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatSnackBarModule,
        ChartsModule,
        MatButtonModule,
        MatToolbarModule,
        NgSelectModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        UserComponent,
        TradeComponent,
        SocialComponent,
        PostReportComponent,
        ImageViolationComponent,
        AnalyticsComponent,
        EngagementComponent,
        BusinessBannersComponent,
        SocialBannersComponent,
        TradeBannersComponent ,
        AddEditBusinessBannerComponent ,
        ConfirmAlertComponent,
        EditBusinessBannerComponent,
        PushNotificationListComponent,
        AddPushNotificationComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        // provider used to create fake backend
        fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };