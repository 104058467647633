<!-- <div class="p-4">
    <div class="container">
        <h1>Hi {{user.firstName}}!</h1>
        <p>You're logged in with Angular 10!!</p>
        <p><a routerLink="/users">Manage Users</a></p>
    </div>
</div> -->

<div class="header navbar-fixed-top">
    <nav class="p-x-0">
      <div class="container-fluid p-x-0">
        <div class="navbar-header">
            <a class="navbar-brand pa-0" href="#"><span class="nav-toggle-bar"><img src="assets/images/logo.png" alt="MeeKet"></span></a>
            <h2>Dashboard</h2>
        </div>
        <ul class="nav navbar-nav right-nav pull-right">
            <li class="prof-dropdown">
                <div class="btn-group">
                    <a href="" data-toggle="dropdown" class="top-link dropdown-toggle">
                        <span>
                            <img src="assets/images/avatar.png" alt="" class="pull-left">
                            <!-- <i class="fa fa-user pull-left" aria-hidden="true"></i> -->
                        </span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                        <li><a (click)="logout()"><i class="fa fa-sign-out" aria-hidden="true"></i> Sign out</a></li>
                    </ul>
                </div>
            </li>
        </ul>
      </div>
    </nav>
</div>

<div class="sidenav menu-bar active">
    <a routerLink="/home" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/' || routeUrl === '/home'}">
        <span title="Dashboard"><i class="fa fa-tachometer" aria-hidden="true"></i> <span>Dashboard</span></span>
    </a>
    <a routerLink="/users" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/users'}">
        <span title="Users"><i class="fa fa-user" aria-hidden="true"></i> <span>Users</span></span>
    </a>
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/analytics' || routeUrl === '/engagement'}">
            <span title="Engagement">
                <i class="fa fa-pie-chart" aria-hidden="true"></i>
                <span>Engagement</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/analytics" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/analytics'}">
                <span title="Engagement Analytics"><i class="fa fa-line-chart" aria-hidden="true"></i> <span>Analytics</span></span>
            </a>
            <a routerLink="/engagement" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/engagement'}">
                <span title="Engagement Overview"><i class="fa fa-clock-o" aria-hidden="true"></i> <span>Overview</span></span>
            </a>
        </div>
    </div>
    <a routerLink="/trade" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/trade'}">
        <span title="Trade"><i class="fa fa-heart" aria-hidden="true"></i> <span>Trade</span></span>
    </a>
    <a routerLink="/social" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/social'}">
        <span title="Social"><i class="fa fa-dot-circle-o" aria-hidden="true"></i> <span>Social</span></span>
    </a>
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/post-report' || routeUrl === '/image-violation'}">
            <span title="Post">
                <i class="fa fa-square" aria-hidden="true"></i>
                <span>Post</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/post-report" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/post-report'}">
                <span title="Report"><i class="fa fa-file-archive-o" aria-hidden="true"></i> <span>Report</span></span>
            </a>
            <a href="#">
                <span title="Content"><i class="fa fa-file-text" aria-hidden="true"></i> <span>Content</span></span>
            </a>
            <a routerLink="/image-violation" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/image-violation'}">
                <span title="Image Violations"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Image Violations</span></span>
            </a>
        </div>
    </div>
    <!--- Deva-->
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/post-report' || routeUrl === '/image-violation'}">
            <span title="Banners">
                <i class="fa fa-folder-open" aria-hidden="true"></i>
                <span>Banners</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/business-banners" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/business-banners'}">
                <span title="Business-Banners"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Business-Banners</span></span>
            </a>
            <a routerLink="/trade-banners" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/trade-banners'}">
                <span title="Trade-Banners"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Trade-Banners</span></span>
            </a>
            <a routerLink="/social-banners" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/social-banners'}">
                <span title="Social-Banners"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Social-Banners</span></span>
            </a>
            <!-- <a routerLink="/add-edit-business-banner" routerLinkActive="on" >
                <span title=" Add-business-banner"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span> add-business-banner</span></span>
            </a> -->
           
        </div>
    </div>
    <a routerLink="/push-notification" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/push-notification'}">
        <span title="Push Notification"><i class="fa fa-bell" aria-hidden="true"></i><span>NotiFication</span></span>
    </a>
    <!-- <a routerLink="/add-push-notification" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/add-push-notification'}">
        <span title="add-push-notification"><i class="fa fa-bell" aria-hidden="true"></i><span>add-push-notification</span></span>
    </a> -->
</div>

<section id="intro" class="page-sec active">
    <div class="container-fluid p-x-0">
        <div class="row">
            <div class="col-md-12">
                <h2 class="page-title">Analysis</h2>
                <div class="content-sec">
                    <div class="col-xs-12 cont-left-box">
                        <!-- Overall statistics -->
                        <div class="row flex overall-stats">
                            <div class="col-xs-12 col-md-3">
                                <div class="col-xs-12 p-y-2">
                                    <div class="btn-group">
                                        <!-- <a href="#" data-toggle="dropdown" class="pull-left">
                                            <i class="fa fa-th-large" aria-hidden="true"></i>
                                        </a> -->
                                        <ul class="dropdown-menu" role="menu">
                                            <li><a href="#">PERSONAL</a></li>
                                            <li><a href="#">BUSINESS</a></li>
                                        </ul>
                                    </div>
                                    <div class="btn-group pull-right flex">
                                        <!-- <div>
                                            <input type="text" name="datefilter" value="" for="dateRangePicker" class="form-control dateRangePicker" />
                                        </div> -->
                                        <!-- <a href="#" data-toggle="dropdown" class="pull-right">
                                            <i class="fa fa-sliders" aria-hidden="true"></i>
                                        </a> -->
                                        <ul class="dropdown-menu dropdown-menu-right" role="menu">
                                            <li><a href="#">Daily</a></li>
                                            <li><a href="#">Weekly</a></li>
                                            <li><a href="#">Monthly</a></li>
                                            <li><label for="dateRangePicker">Custom Period</label></li>
                                        </ul>
                                    </div>
                                </div>
                                <a routerLink="/users" routerLinkActive="on" class="col-xs-12 card">
                                    <div class="col-xs-12">
                                        <img src="assets/images/total-users.png" alt="total-users" class="pull-right">
                                    </div>
                                    <div class="col-xs-12">
                                        <h3>{{totalUser}}</h3>
                                    </div>
                                    <div class="col-xs-12 p-a-0">
                                        <h2>
                                            Total Users
                                            <i class="fa fa-arrow-right"></i>
                                        </h2>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-12 col-md-3">
                                <div class="col-xs-12 p-y-2">
                                    <div class="btn-group">
                                        <a href="#" data-toggle="dropdown" class="pull-left">
                                            <i class="fa fa-th-large" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu" role="menu">
                                            <li (click)="activeUserFilterType('PERSONAL')"><a href="#">PERSONAL</a></li>
                                            <li (click)="activeUserFilterType('BUSINESS')"><a href="#">BUSINESS</a></li>
                                        </ul>
                                    </div>
                                    <div class="btn-group pull-right flex">
                                        <div *ngIf="activeUserDateFilter"><input type="text" name="datefilter" [(ngModel)]="activeUserPeriod" value="" for="dateRangePicker" class="form-control dateRangePicker" /></div>
                                        <a href="#" data-toggle="dropdown" class="pull-right">
                                            <i class="fa fa-sliders" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-right" role="menu">
                                            <li (click)="activeUserFilter('DAILY')"><a href="#">Daily</a></li>
                                            <li (click)="activeUserFilter('WEEKLY')"><a href="#">Weekly</a></li>
                                            <li (click)="activeUserFilter('MONTHLY')"><a href="#">Monthly</a></li>
                                            <li (click)="activeUserFilter('Custom')"><label for="dateRangePicker">Custom Period</label></li>
                                        </ul>
                                    </div>
                                </div>
                                <a href="" class="col-xs-12 card">
                                    <div class="col-xs-12">
                                        <img src="assets/images/active-users.png" alt="active-users" class="pull-right">
                                    </div>
                                    <div class="col-xs-12">
                                        <h3>{{activeUser}}</h3>
                                    </div>
                                    <div class="col-xs-12 p-a-0">
                                        <h2>
                                            Active Users
                                            <!-- <i class="fa fa-arrow-right"></i> -->
                                        </h2>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-12 col-md-3">
                                <div class="col-xs-12 p-y-2">
                                    <div class="btn-group">
                                        <a href="#" data-toggle="dropdown" class="pull-left">
                                            <i class="fa fa-th-large" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu" role="menu">
                                            <li (click)="newUserFilterType('PERSONAL')"><a href="#">PERSONAL</a></li>
                                            <li (click)="newUserFilterType('BUSINESS')"><a href="#">BUSINESS</a></li>
                                        </ul>
                                    </div>
                                    <div class="btn-group pull-right flex">
                                        <div *ngIf="newUserDateFilter"><input type="text" name="datefilter" value="" for="dateRangePicker" class="form-control dateRangePicker" /></div>
                                        <a href="#" data-toggle="dropdown" class="pull-right">
                                            <i class="fa fa-sliders" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-right" role="menu">
                                            <li (click)="newUserFilter('DAILY')"><a href="#">Daily</a></li>
                                            <li (click)="newUserFilter('WEEKLY')"><a href="#">Weekly</a></li>
                                            <li (click)="newUserFilter('MONTHLY')"><a href="#">Monthly</a></li>
                                            <li (click)="newUserFilter('Custom')"><label for="dateRangePicker">Custom Period</label></li>
                                        </ul>
                                    </div>
                                </div>
                                <a href="" class="col-xs-12 card">
                                    <div class="col-xs-12">
                                        <img src="assets/images/new-users.png" alt="new-users" class="pull-right">
                                    </div>
                                    <div class="col-xs-12">
                                        <h3>{{newUser}}</h3>
                                    </div>
                                    <div class="col-xs-12 p-a-0">
                                        <h2>
                                            New Users
                                            <i class="fa fa-arrow-right"></i>
                                        </h2>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-12 col-md-3">
                                <div class="col-xs-12 p-y-2">
                                    <div class="btn-group">
                                        <a href="#" data-toggle="dropdown" class="pull-left">
                                            <i class="fa fa-th-large" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu" role="menu">
                                            <li (click)="messageFilterType('PERSONAL')"><a href="#">PERSONAL</a></li>
                                            <li (click)="messageFilterType('BUSINESS')"><a href="#">BUSINESS</a></li>
                                        </ul>
                                    </div>
                                    <div class="btn-group pull-right flex">
                                        <div *ngIf="messageDateFilter"><input type="text" name="datefilter" value="" for="dateRangePicker" class="form-control dateRangePicker" /></div>
                                        <a href="#" data-toggle="dropdown" class="pull-right">
                                            <i class="fa fa-sliders" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-right" role="menu">
                                            <li (click)="messageFilter('DAILY')"><a href="#">Daily</a></li>
                                            <li (click)="messageFilter('WEEKLY')"><a href="#">Weekly</a></li>
                                            <li (click)="messageFilter('MONTHLY')"><a href="#">Monthly</a></li>
                                            <li (click)="messageFilter('Custom')"><label for="dateRangePicker">Custom Period</label></li>
                                        </ul>
                                    </div>
                                </div>
                                <a href="" class="col-xs-12 card">
                                    <div class="col-xs-12">
                                        <img src="assets/images/messages.png" alt="messages" class="pull-right">
                                    </div>
                                    <div class="col-xs-12">
                                        <h3>{{messageCount}}</h3>
                                    </div>
                                    <div class="col-xs-12 p-a-0">
                                        <h2>
                                            Messages
                                            <!-- <i class="fa fa-arrow-right"></i> -->
                                        </h2>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <!-- Overall statistics -->
                        <div class="row flex overall-stats">
                            <div class="col-xs-12 col-md-3">
                                <div class="col-xs-12 p-y-2">
                                    <div class="btn-group">
                                        <!-- <a href="#" data-toggle="dropdown" class="pull-left">
                                            <i class="fa fa-th-large" aria-hidden="true"></i>
                                        </a> -->
                                        <ul class="dropdown-menu" role="menu">
                                            <li><a href="#">PERSONAL</a></li>
                                            <li><a href="#">BUSINESS</a></li>
                                        </ul>
                                    </div>
                                    <div class="btn-group pull-right flex">
                                        <div *ngIf="tradeDateFilter"><input type="text" name="datefilter" value="" for="dateRangePicker" class="form-control dateRangePicker" /></div>
                                        <a href="#" data-toggle="dropdown" class="pull-right">
                                            <i class="fa fa-sliders" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-right" role="menu">
                                            <li (click)="tradeFilter('DAILY')"><a href="#">Daily</a></li>
                                            <li (click)="tradeFilter('WEEKLY')"><a href="#">Weekly</a></li>
                                            <li (click)="tradeFilter('MONTHLY')"><a href="#">Monthly</a></li>
                                            <li (click)="tradeFilter('Custom')"><label for="dateRangePicker">Custom Period</label></li>
                                        </ul>
                                    </div>
                                </div>
                                <a routerLink="/trade" routerLinkActive="on" class="col-xs-12 card">
                                    <div class="col-xs-12">
                                        <img src="assets/images/trades.png" alt="trades" class="pull-right">
                                    </div>
                                    <div class="col-xs-12">
                                        <h3>{{tradeCount}}</h3>
                                    </div>
                                    <div class="col-xs-12 p-a-0">
                                        <h2>
                                            Trades
                                            <i class="fa fa-arrow-right"></i>
                                        </h2>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-12 col-md-3">
                                <div class="col-xs-12 p-y-2">
                                    <div class="btn-group">
                                        <!-- <a href="#" data-toggle="dropdown" class="pull-left">
                                            <i class="fa fa-th-large" aria-hidden="true"></i>
                                        </a> -->
                                        <ul class="dropdown-menu" role="menu">
                                            <li><a href="#">PERSONAL</a></li>
                                            <li><a href="#">BUSINESS</a></li>
                                        </ul>
                                    </div>
                                    <div class="btn-group pull-right flex">
                                        <div *ngIf="socialDateFilter"><input type="text" name="datefilter" value="" for="dateRangePicker" class="form-control dateRangePicker" /></div>
                                        <a href="#" data-toggle="dropdown" class="pull-right">
                                            <i class="fa fa-sliders" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-right" role="menu">
                                            <li (click)="socialFilter('DAILY')"><a href="#">Daily</a></li>
                                            <li (click)="socialFilter('WEEKLY')"><a href="#">Weekly</a></li>
                                            <li (click)="socialFilter('MONTHLY')"><a href="#">Monthly</a></li>
                                            <li (click)="socialFilter('Custom')"><label for="dateRangePicker">Custom Period</label></li>
                                        </ul>
                                    </div>
                                </div>
                                <a routerLink="/social" routerLinkActive="on" class="col-xs-12 card">
                                    <div class="col-xs-12">
                                        <img src="assets/images/socials.png" alt="socials" class="pull-right">
                                    </div>
                                    <div class="col-xs-12">
                                        <h3>{{socialCount}}</h3>
                                    </div>
                                    <div class="col-xs-12 p-a-0">
                                        <h2>
                                            Socials
                                            <i class="fa fa-arrow-right"></i>
                                        </h2>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-12 col-md-3 text-center">
                                <div class="card" style="margin-top: 30px;">
                                    <strong>New Users</strong>
                                    <div class="chart-box" style="height: 165px;">
                                        <div id="newUsers1">
                                            <div class="chart-wrapper">
                                                <canvas baseChart 
                                                [data]="pieChartData" 
                                                [labels]="pieChartLabels" 
                                                [chartType]="pieChartType"
                                                [options]="pieChartOptions"
                                                [plugins]="pieChartPlugins"
                                                [legend]="pieChartLegend">
                                              </canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-3 text-center">
                                <div class="card" style="margin-top: 30px;">
                                    <strong>Active Users</strong>
                                    <div class="chart-box" style="height: 165px;">
                                        <div id="activeUsers1">
                                            <div class="chart-wrapper">
                                                <canvas baseChart 
                                                [data]="pieChartData1" 
                                                [labels]="pieChartLabels1" 
                                                [chartType]="pieChartType1"
                                                [options]="pieChartOptions1"
                                                [plugins]="pieChartPlugins1"
                                                [legend]="pieChartLegend1">
                                              </canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h3 class="title-2">Recent Users</h3>
                        <mat-table #table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="{{column.id}}" *ngFor="let column of columnNames">
                              <mat-header-cell *matHeaderCellDef mat-sort-header> {{column.value}}</mat-header-cell>
                              <mat-cell *matCellDef="let element"> {{element[column.id]}}</mat-cell>
                            </ng-container>
                          
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                        <!-- Datatable -->
                        <!-- <div class="col-md-12 p-x-0">
                            <h3 class="title-2">Recent Users</h3>
                            <div class="cs-tbl">
                                <table id="customerDatatable" class="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>Registered Date/Time</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Theresa
                                                <div class="detail-sec">
                                                    <span class="detail-icon dropdown-toggle" data-toggle="dropdown"></span>
                                                    <ul class="dropdown-menu" role="menu">
                                                        <li><a href="tel:9876543210"><i class="fa fa-phone" aria-hidden="true"></i> 9876543210</a></li>
                                                        <li><a href="mailto:username@gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i> username@gmail.com</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>9876543210</td>
                                            <td>username@gmail.com</td>
                                            <td>Tue, 19-Mar-2019 05:11 pm</td>
                                            <td><span class="open-tag">Not in Use</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Leo Peter
                                                <div class="detail-sec">
                                                    <span class="detail-icon dropdown-toggle" data-toggle="dropdown"></span>
                                                    <ul class="dropdown-menu" role="menu">
                                                        <li><a href="tel:9867543421"><i class="fa fa-phone" aria-hidden="true"></i> 9867543421</a></li>
                                                        <li><a href="mailto:username@gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i> username@gmail.com</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>9867543421</td>
                                            <td>username@gmail.com</td>
                                            <td>Tue, 19-Mar-2019 05:11 pm</td>
                                            <td><span class="pending-tag">In Active</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Micheal
                                                <div class="detail-sec">
                                                    <span class="detail-icon dropdown-toggle" data-toggle="dropdown"></span>
                                                    <ul class="dropdown-menu" role="menu">
                                                        <li><a href="tel:9867555421"><i class="fa fa-phone" aria-hidden="true"></i> 9867555421</a></li>
                                                        <li><a href="mailto:username@gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i> username@gmail.com</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>9867555421</td>
                                            <td>username@gmail.com</td>
                                            <td>Tue, 19-Mar-2019 05:11 pm</td>
                                            <td><span class="done-tag">Active</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                John
                                                <div class="detail-sec">
                                                    <span class="detail-icon dropdown-toggle" data-toggle="dropdown"></span>
                                                    <ul class="dropdown-menu" role="menu">
                                                        <li><a href="tel:9867543488"><i class="fa fa-phone" aria-hidden="true"></i> 9867543488</a></li>
                                                        <li><a href="mailto:username@gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i> username@gmail.com</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>9867543488</td>
                                            <td>username@gmail.com</td>
                                            <td>Tue, 19-Mar-2019 05:11 pm</td>
                                            <td><span class="done-tag">Active</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                John Patrick
                                                <div class="detail-sec">
                                                    <span class="detail-icon dropdown-toggle" data-toggle="dropdown"></span>
                                                    <ul class="dropdown-menu" role="menu">
                                                        <li><a href="tel:7667543421"><i class="fa fa-phone" aria-hidden="true"></i> 7667543421</a></li>
                                                        <li><a href="mailto:username@gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i> username@gmail.com</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>7667543421</td>
                                            <td>username@gmail.com</td>
                                            <td>Tue, 19-Mar-2019 05:11 pm</td>
                                            <td><span class="open-tag">Open</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>