import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-business-banner',
  templateUrl: './edit-business-banner.component.html',
  styleUrls: ['./edit-business-banner.component.less']
})
export class EditBusinessBannerComponent implements OnInit {
  formGroup: FormGroup;
  loadfile: any = '../assets/images/LoadImaage.png';
  titleAlert: string = 'This field is required';

  Types: any = ['BUSINESS', 'TRADE', 'SOCIAL'];

  LangList: any = [
    { id: 1, value: '--select--', code: '' },
    { id: 2, value: 'VN', code: 'vi_VN' }
  ]
  StatusList: any = [
    {id: 1, value: 'ACTIVE'},
    {id: 2, value: 'INACTIVE'}
  ]
  selectedValue: string;
  post: any = '';
  file: any;
  data: any = {};
  constructor(
    public formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    this.data = JSON.parse(sessionStorage.getItem('busdata'));
    this.formGroup = this.formBuilder.group({
      title: [this.data.name, [Validators.required]],
      file: [this.file],
      status: [this.data.status, [Validators.required]],
      type: [this.data.type, [Validators.required]],
      trans: [this.data.translations[0].code]
    });
    if (!this.data.isNew) {
      if(this.data.url.length > 0) {
        this.loadfile = this.data.url;
      } else {
        this.loadfile = this.data.translations[0].value;
      }
    }
  }

  openSnackBar(Message) {
    this._snackBar.open(Message, "ok", {horizontalPosition: 'left',
    verticalPosition: 'bottom', panelClass:['Green']});
  }

  onNoClick(): void {
    // this.dialogRef.close();
  }
  
  ngOnInit(): void {
    
  }

  onFileChange(File) {
    // console.log(File.target.files[0].value);
    const frmObj = this.formGroup.controls;
    this.file = File.target.files[0];
    this.loadfile = URL.createObjectURL(File.target.files[0]);
    this.loadfile = this.sanitizer.bypassSecurityTrustUrl(this.loadfile);
  }

  Close() {
    // this.dialogRef.close({ isSubmit: false, file: this.file, formData: this.formGroup.value });
  }

  onSubmit(post) {
    const frmValues = this.formGroup.value;
    frmValues.file = this.data.url;
    if (this.formGroup.valid) {
      if(this.data.id.length > 0) {
        // this.dialogRef.close({ isSubmit: true, file: this.file, formData: frmValues, id: this.data.id, isNew: this.data.isNew });
      } else {
        // this.dialogRef.close({ isSubmit: true, file: this.file, formData: frmValues, id: '', isNew: this.data.isNew });
      }
    } else {
      this.formGroup.markAllAsTouched();
      this._snackBar.open("Please check all the fields", "OK", {
        duration: 2000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom'
      });
    }
  }
}
