import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@app/business-banners/business-banners.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-add-edit-business-banner',
  templateUrl: './add-edit-business-banner.component.html',
  styleUrls: ['./add-edit-business-banner.component.less']
})
export class AddEditBusinessBannerComponent implements OnInit {
  formGroup: FormGroup;
  loadfile: any = '../assets/images/LoadImaage.png';
  titleAlert: string = 'This field is required';
  DialogTitle: string = 'Business Panel';

  Types: any = ['BUSINESS', 'TRADE', 'SOCIAL'];

  LangList: any = [
    { id: 1, value: '--select--', code: '' },
    { id: 2, value: 'VN', code: 'vi_VN' },
    { id: 3, value: 'EN', code: 'en_EN' }
  ]
  StatusList: any = [
    { id: 1, value: 'ACTIVE' },
    { id: 2, value: 'INACTIVE' }
  ]
  selectedValue: string;
  post: any = '';
  fileArr: any = [{
    value: '',
    obj: ''
  },
  {
    value: '',
    obj: ''
  }];

  GfileArr: any = [
    {
      value: '../assets/images/LoadImaage.png',
      obj: ''
    }
  ];
  TransGroup: any = [];

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddEditBusinessBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    if (!this.data.isNew) {
      if (this.data.url.length > 0) {
        this.loadfile = this.data.url;
        this.GfileArr = [{
          value: this.data.url,
          obj: ''
        }];
      } else {
        this.GfileArr = [{
          value: '../assets/images/LoadImaage.png',
          obj: ''
        }];
        this.loadfile = '';
      }
    }
  }

  private createTransItemGroup(urlValue): FormGroup {
    return new FormGroup({
      'code': new FormControl(''),
      'name': new FormControl('url'),
      'value': new FormControl(urlValue)
    });
  }

  private loadAllTransItemGroup(): FormArray {
    const transArr = this.formBuilder.array([]);
    if (this.data.isNew) {
      // transArr.push(new FormGroup({
      //   'code': new FormControl(''),
      //   'name': new FormControl('url'),
      //   'value': new FormControl('../../assets/images/LoadImaage.png')
      // }));
      return transArr;
    } else {
      this.fileArr = [];
      this.data.translations.forEach(element => {
        transArr.push(new FormGroup({
          'code': new FormControl(element.code),
          'name': new FormControl(element.name),
          'value': new FormControl(element.value)
        }));
        this.fileArr.push({
          value: element.value,
          obj: ''
        });
      });
      return transArr;
    }
  }

  addTrans() {
    const transArr = this.formGroup.get('trans') as FormArray;
    transArr.push(this.createTransItemGroup('../../assets/images/LoadImaage.png'));
    this.fileArr.push({
      value: '../assets/images/LoadImaage.png',
      obj: ''
    });
  }

  removeTrans(index) {
    const trans = this.formGroup.get('trans') as FormArray
    if (trans.length > 0) {
      trans.removeAt(index);
      this.fileArr.pop(index);
    } else {
      trans.reset();
      this.fileArr = [{
        value: '../assets/images/LoadImaage.png',
        obj: ''
      }];
    }
  }

  openSnackBar(Message) {
    this._snackBar.open(Message, "ok", {
      horizontalPosition: 'left',
      verticalPosition: 'bottom', panelClass: ['Green']
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      title: [this.data.name, [Validators.required]],
      // file: [this.file],
      status: [this.data.status, [Validators.required]],
      type: [this.data.type, [Validators.required]],
      trans: this.loadAllTransItemGroup()
    });
  }
  createForm() {

  }

  GFileChange(File, frmConrol) {
    this.loadfile = URL.createObjectURL(File.target.files[0]);
    this.loadfile = this.sanitizer.bypassSecurityTrustUrl(this.loadfile);
    // frmConrol.value = this.loadfile;
    this.GfileArr[0].value = this.loadfile,
    this.GfileArr[0].obj = File.target.files[0];
  }

  onFileChange(File, index, frmConrol) {
    // console.log(File.target.files[0].value);
    this.loadfile = URL.createObjectURL(File.target.files[0]);
    // this.loadfile = this.sanitizer.bypassSecurityTrustUrl(this.loadfile);
    frmConrol.value = this.loadfile;
    this.fileArr[index].value = this.loadfile,
      this.fileArr[index].obj = File.target.files[0];
  }

  Close() {
    this.dialogRef.close({ isSubmit: false, file: this.fileArr, formData: this.formGroup.value });
  }

  onSubmit(post) {
    const frmValues = this.formGroup.value;
    frmValues.file = this.data.url;
    if (frmValues.trans.length > 0) {
      for (let index = 0; index < frmValues.trans.length; index++) {
        const element = frmValues.trans[index];
        element.file = this.fileArr[index].obj;
      }
    } else {
      frmValues.trans = [];
    }
    if (this.formGroup.valid) {
      if (this.data.id.length > 0) {
        this.dialogRef.close({ isSubmit: true, file: this.fileArr, formData: frmValues, id: this.data.id, isNew: this.data.isNew, accURL: this.loadfile, Gurl: this.GfileArr });
      } else {
        this.dialogRef.close({ isSubmit: true, file: this.fileArr, formData: frmValues, id: '', isNew: this.data.isNew, accURL: this.loadfile, Gurl: this.GfileArr });
      }
    } else {
      this.formGroup.markAllAsTouched();
      this._snackBar.open("Please check all the fields", "OK", {
        duration: 2000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom'
      });
    }
  }
}
