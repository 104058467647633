import { Component, ViewChild } from '@angular/core';
import { AccountService, UsersService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({ templateUrl: 'social.component.html' })
export class SocialComponent {
    users:any;
    ELEMENT_DATA: USER[] = [];
    @ViewChild(MatSort) sort: MatSort;

    isLoading = false;
    totalRows = 0;
    pageSize = 20;
    currentPage = 0;
    pageSizeOptions: number[] = [20, 50, 75, 100];

    displayedColumns: string[] = ['member.userName', 'images[0].url', 'description', 'likeCount', 'commentCount', 'status', 'listingId'];
    dataSource: MatTableDataSource<USER> = new MatTableDataSource();

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    routeUrl:any;

    constructor(
        private userServices: UsersService,
        private alertService: AlertService,
        private accountService: AccountService,
        private snackBar: MatSnackBar,
        private router: Router
    ) {
        this.routeUrl = this.router.url;
    }

    ngOnInit() {
        this.createTable();
    }

    createTable() {
        this.isLoading = true;
        this.userServices.getList('SOCIAL', this.currentPage)
            .pipe(first())
            .subscribe({
                next: (users) => {
                    this.users = users;
                    this.dataSource.data = this.users.data;
                    setTimeout(() => {
                        this.paginator.pageIndex = this.currentPage;
                        this.paginator.length = this.users.recordsTotal;
                    });
                    this.isLoading = false;
                },
                error: error => {
                    console.log(error);
                    this.isLoading = false;
                }
        });
    }

    pageChanged(event: PageEvent) {
        console.log({ event });
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.createTable();
    }

    deleteRecord(id) {
        this.isLoading = true;
        this.userServices.deleteListRow(id)
            .pipe(first())
            .subscribe({
                next: (records) => {
                    this.snackBar.open('Deleted Successfully', '', {
                        panelClass: 'snackbarcls',
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 3000,
                    });                  
                    this.createTable();
                    this.isLoading = false;
                },
                error: error => {
                    console.log(error);
                    this.isLoading = false;
                }
        });
    }

    logout() {
        this.accountService.logout();
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
        // this.dataSource.filter = filterValue.trim().toLowerCase();
        this.isLoading = true;
        // let filter = 'userName:eq:'+filterValue;
        this.userServices.getListingFilter(this.currentPage, 'SOCIAL', filterValue)
            .pipe(first())
            .subscribe({
                next: (users) => {
                    this.users = users;
                    this.dataSource.data = this.users.data;
                    setTimeout(() => {
                        this.paginator.pageIndex = this.currentPage;
                        this.paginator.length = this.users.recordsTotal;
                    });
                    this.isLoading = false;
                },
                error: error => {
                    console.log(error);
                    this.isLoading = false;
                }
        });
    }
}

interface USER {
    member: string,
    images: string,
    description: number,
    likeCount: string,
    commentCount: string,
    status:  string,
    listingId: string
}