import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username, password) {
        let encoded = btoa(username+":"+password);
        const headers = { 'Authorization': 'Basic '+encoded, 'accept': 'application/json', 'Content-Type': 'application/json'};
        const body = {
            requestType:"SUPERADMIN", 
            deviceId:"string", 
            deviceType:"ANDROID", 
            deviceToken:"string",
            location:{type:"Point", coordinates:[80.25934953737513,13.029071455550344]}
        };
        return this.http.post<User>(`${environment.apiUrl}/api/v1.0/members/signin`, body, { headers })
            .pipe(map(user => {
                localStorage.setItem('user', JSON.stringify(user));
                console.log("Logged In User :: ", JSON.stringify(user));
                
                this.userSubject.next(user);
                return user;
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    register(user: User) {
        return this.http.post(`${environment.apiUrl}/users/register`, user);
    }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.apiUrl}/users/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.id) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue.id) {
                    this.logout();
                }
                return x;
            }));
    }

    refreshToken() {
        let parsedData = JSON.parse(localStorage.getItem('user'));
        console.log(parsedData);
        
        let payload = {
            authId: "8888888888",
            jwtToken: parsedData.bearerToken,
            deviceId: "string"
        };
        // this.userVal = this.userSubject.value;
        let encoded = btoa(environment.appUserName+":"+environment.appPassword);
        const headers = { 'Authorization': 'Basic '+encoded, 'accept': 'application/json', 'Content-Type': 'application/json'};
        return this.http.post(`${environment.apiUrl}/api/v1.0/members/refreshToken`, payload, { headers });
    }
}