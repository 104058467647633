import { Component, ViewChild } from '@angular/core';
import { AccountService, UsersService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

@Component({ templateUrl: 'analytics.component.html' })
export class AnalyticsComponent {
    users:any;
    ELEMENT_DATA: USER[] = [];
    // dataSource;
    // displayedColumns = [];
    @ViewChild(MatSort) sort: MatSort;
    // @ViewChild(MatPaginator) paginator: MatPaginator;

    isLoading = false;
    totalRows = 0;
    pageSize = 20;
    currentPage = 0;
    pageSizeOptions: number[] = [20, 50, 75, 100];

    displayedColumns: string[] = ['screenName', 'memberCount', 'newMemberCount', 'pageViewsPerMember', 'totalEngagementDuration', 'totalViews'];
    dataSource: MatTableDataSource<USER> = new MatTableDataSource();

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    routeUrl:any;

    constructor(
        private userServices: UsersService,
        private alertService: AlertService,
        private accountService: AccountService,
        private router: Router
    ) {
        this.routeUrl = this.router.url;
    }

    ngOnInit() {
        this.createTable();
    }

    createTable() {
        this.isLoading = true;
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        var firstDay1 = new Date(firstDay);
        var firstDayString = new Date(firstDay1.getTime() - (firstDay1.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];

        var lastDay1 = new Date(lastDay);
        var lastDayString = new Date(lastDay1.getTime() - (lastDay1.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];

        this.userServices.getAnalytics(this.currentPage, firstDayString, lastDayString)
            .pipe(first())
            .subscribe({
                next: (users) => {
                    this.users = users;
                    this.dataSource.data = this.users;
                    console.log(this.dataSource.data);
                    setTimeout(() => {
                        this.paginator.pageIndex = this.currentPage;
                        this.paginator.length = this.users.length;
                    });
                    this.isLoading = false;
                },
                error: error => {
                    console.log(error);
                    this.isLoading = false;
                }
        });
    }

    pageChanged(event: PageEvent) {
        console.log({ event });
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.createTable();
    }

    logout() {
        this.accountService.logout();
    }
}

interface USER {
    screenName: string,
    memberCount: number,
    newMemberCount: string,
    pageViewsPerMember: string,
    totalEngagementDuration: string,
    totalViews: string,
}