export const environment = {
   //live
  // production: true,
  // apiUrl: 'https://api.meeket.vn'

  //developement
  production: false,
  apiUrl: 'https://dev.meeket.vn',
  appUserName:"ATV@imm001",
  appPassword:"Password@1"
};
