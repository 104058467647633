<form [formGroup]="formGroup" class="form addEditForm">

    <div class="Bodypanel">
        <mat-form-field>
            <input matInput placeholder="Title" formControlName="title">
            <mat-error *ngIf="!formGroup.controls.title.valid && formGroup.controls.title.touched">
                {{ titleAlert }}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Status" [formControl]="formGroup.controls.status">
                <mat-option *ngFor="let statusItem of StatusList" [value]="statusItem.value">
                    {{ statusItem.value }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="!formGroup.controls.status.valid && formGroup.controls.status.touched">
                {{ titleAlert }}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput disabled placeholder="Type" formControlName="type">
            <mat-error *ngIf="!formGroup.controls.type.valid && formGroup.controls.type.touched">
                {{ titleAlert }}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Translation" [formControl]="formGroup.controls.trans">
                <mat-option *ngFor="let transItem of LangList" [value]="transItem.code">
                    {{ transItem.value }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="!formGroup.controls.trans.valid && formGroup.controls.trans.touched">
                {{ titleAlert }}
            </mat-error>
        </mat-form-field>

        <div class="ImageUpload">
            <div class="ImagePreview">
                <img [src]="loadfile" />
            </div>
            <input type="file" id="file" multiple="multiple" accept=".png,.jpge/*" (change)="onFileChange($event)">
        </div>

    </div>
    <div class="desiderPanel">
        <button (click)="Close()" mat-raised-button class="button">Cancel</button>
        <button (click)="onSubmit(formGroup.value)" mat-raised-button color="primary" type="submit" class="button">Validate</button>
    </div>

</form>