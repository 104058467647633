<form [formGroup]="formGroup" class="form addEditForm">
   
    <div class="Bodypanel">
        <mat-form-field style="width: 50%">
            <input matInput placeholder="Title" formControlName="title">
            <mat-error *ngIf="!formGroup.controls.title.valid && formGroup.controls.title.touched">
                {{ titleAlert }}
            </mat-error>
        </mat-form-field><br>

        <mat-form-field style="width: 50%">
            <mat-select placeholder="Status" [formControl]="formGroup.controls.status">
                <mat-option *ngFor="let statusItem of StatusList" [value]="statusItem.value">
                    {{ statusItem.value }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="!formGroup.controls.status.valid && formGroup.controls.status.touched">
                {{ titleAlert }}
            </mat-error>
        </mat-form-field><br>

        <mat-form-field style="width: 50%">
            <input matInput disabled placeholder="Type" formControlName="type">
            <mat-error *ngIf="!formGroup.controls.type.valid && formGroup.controls.type.touched">
                {{ titleAlert }}
            </mat-error>
        </mat-form-field>

        <div class="ImageUpload">
            <div class="ImagePreview">
                <img [src]="GfileArr[0].value" />
            </div>
            <input type="file" id="file" accept=".png,.jpge/*"
                (change)="GFileChange($event, formGroup.controls['url'])">
        </div><br>
        
        <div class="" formArrayName="trans">
            <div class="" *ngFor="let transItem of formGroup.get('trans')['controls']; let i = index" [formGroupName]="i">
                <!-- <mat-form-field>
                    <mat-select placeholder="Translation" [(ngModel)]="transItem.code">
                        <mat-option *ngFor="let langItem of LangList" [value]="langItem.code">
                            {{ langItem.value }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!transItem.valid && transItem.touched">
                        {{ titleAlert }}
                    </mat-error>
                </mat-form-field> -->

                <mat-form-field style="width: 50%">
                    <mat-select placeholder="Translation" formControlName="code">
                        <mat-option *ngFor="let langItem of LangList" [value]="langItem.code">{{ langItem.value }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!transItem.valid && transItem.touched">
                        {{ titleAlert }}
                    </mat-error>
                </mat-form-field>

                <div class="ImageUpload">
                    <div class="ImagePreview">
                        <img [src]="sanitizer.bypassSecurityTrustResourceUrl(transItem.controls['value'].value)" />
                    </div>
                    <input type="file" id="file" accept=".png,.jpge/*"
                        (change)="onFileChange($event, i, transItem.controls['value'])">
                </div>
                <button (click)="removeTrans(i)" class="removeTrans" mat-raised-button color="primary">
                    remove
                </button>
            </div>
        </div>
        <button (click)="addTrans()" mat-raised-button color="primary">
            Add Translation
        </button>
    </div>
    <div class="desiderPanel">
        <button (click)="Close()" mat-raised-button class="button">Cancel</button>
        <button (click)="onSubmit(formGroup.value)" mat-raised-button color="primary" type="submit"
            class="button">Submit</button>
    </div>

</form>