import { Component, ViewChild } from '@angular/core';
import { AccountService, UsersService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    users:any;

    dataSource;
    displayedColumns = [];
    @ViewChild(MatSort) sort: MatSort;

    columnNames = [ 
        {
            id: 'userName',
            value: 'User Name',
        },
        {
            id: 'name',
            value: 'Name',
        },
        {
          id: 'mobileNo',
          value: 'Mobile',
        },
        {
          id: 'emailId',
          value: 'Email',
        },
        {
          id: 'createdAt',
          value: 'Registered Date/Time',
        },
        {
          id: 'status',
          value: 'Status',
        }
    ];

    totalUser:any;
    totalUserResponse:any;
    newUser:any;
    newUserResponse:any;
    activeUser:any;
    activeUserResponse:any;
    messageCount:any;
    messageResponse:any;
    tradeCount:any;
    tradeResponse:any;
    socialCount:any;
    socialResponse: any;
    newUserDateFilter: boolean;
    activeUserDateFilter: boolean = false;
    messageDateFilter: boolean;
    tradeDateFilter: boolean;
    socialDateFilter: boolean;
    activeUserFilterValue:any;
    activeUserFilterFrom:any;
    activeUserPeriod: string = '';
    newUserFilterValue:any;
    newUserFilterFrom:any;
    messageFilterValue:any;
    messageFilterFrom:any;
    tradeFilterFrom:any;
    socialFilterFrom:any;

    routeUrl:any;

    public pieChartOptions: ChartOptions = {
        responsive: true,
    };
    public pieChartLabels: Label[] = ['06/20/2022', '06/25/2022', '06/26/2022', '06/27/2022'];
    public pieChartData: SingleDataSet = [40, 30, 20, 10];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = false;
    public pieChartPlugins = [];

    public pieChartOptions1: ChartOptions = {
        responsive: true,
    };
    public pieChartLabels1: Label[] = ['06/20/2022', '06/21/2022', '06/22/2022', '06/23/2022', '06/24/2022'];
    public pieChartData1: SingleDataSet = [20, 35, 25, 15, 5];
    public pieChartType1: ChartType = 'pie';
    public pieChartLegend1 = false;
    public pieChartPlugins1 = [];

    constructor(
        private userServices: UsersService,
        private alertService: AlertService,
        private accountService: AccountService,
        private router: Router
    ) {
        monkeyPatchChartJsTooltip();
        monkeyPatchChartJsLegend();

        this.newUserDateFilter = false;
        // this.activeUserDateFilter = false;
        this.messageDateFilter = false;
        this.tradeDateFilter = false;
        this.socialDateFilter = false;

        this.activeUserFilterValue = 'PERSONAL';
        this.activeUserFilterFrom = 'DAILY';

        this.newUserFilterValue = 'PERSONAL';
        this.newUserFilterFrom = 'DAILY';

        this.messageFilterValue = 'PERSONAL';
        this.messageFilterFrom = 'DAILY';

        this.tradeFilterFrom = 'DAILY';

        this.socialFilterFrom = 'DAILY';

        this.routeUrl = this.router.url;
    }

    ngOnInit() {
        this.userServices.getTotalUserCount().pipe(first()).subscribe({
            next: (users) => {
                this.totalUserResponse = users;
                this.totalUser = this.totalUserResponse.analytics[0].total
            }
        });
        this.getNewUserCount();
        this.getActiveUserCount();
        this.getMessageCount();
        this.getTradeCount();
        this.getSocialCount();
        this.userServices.getMembersList()
            .pipe(first())
            .subscribe({
                next: (users) => {
                    this.users = users;
                    this.displayedColumns = this.columnNames.map(x => x.id);
                    this.createTable();
                },
                error: error => {
                }
        });
    }

    getNewUserCount() {
        this.userServices.getDashboardCount('NEW_USER', this.newUserFilterValue, this.newUserFilterFrom).pipe(first()).subscribe({
            next: (users) => {
                this.newUserResponse = users;
                this.newUser = this.newUserResponse.analytics[0].total
            }
        });
    }

    getActiveUserCount() {
        this.userServices.getDashboardCount('ACTIVE_USER', this.activeUserFilterValue, this.activeUserFilterFrom).pipe(first()).subscribe({
            next: (users) => {
                this.activeUserResponse = users;
                this.activeUser = this.activeUserResponse.analytics[0].total
            }
        });
    }

    getMessageCount() {
        this.userServices.getDashboardCount('MESSAGE', this.messageFilterValue, this.messageFilterFrom).pipe(first()).subscribe({
            next: (users) => {
                this.messageResponse = users;
                this.messageCount = this.messageResponse.analytics[0].total
            }
        });
    }

    getTradeCount() {
        this.userServices.getDashboardFilterCount('PRODUCT', this.tradeFilterFrom).pipe(first()).subscribe({
            next: (users) => {
                this.tradeResponse = users;
                this.tradeCount = this.tradeResponse.analytics[0].total
            }
        });
    }

    getSocialCount() {
        this.userServices.getDashboardFilterCount('SOCIAL', this.socialFilterFrom).pipe(first()).subscribe({
            next: (users) => {
                this.socialResponse = users;
                this.socialCount = this.socialResponse.analytics[0].total
            }
        });
    }

    activeUserFilter(value) {
        if(value === 'Custom') {
            this.activeUserDateFilter = ! this.activeUserDateFilter;
        } else {
            this.activeUserFilterFrom = value;
        }
        
        this.getActiveUserCount();
    }

    activeUserFilterType(value) {
        this.activeUserFilterValue = value;
        this.getActiveUserCount();
    }

    newUserFilter(value) {
        if(value === 'Custom') {
            this.newUserDateFilter = ! this.newUserDateFilter;
        } else {
            this.newUserFilterFrom = value;
        }
        
        this.getNewUserCount();
    }

    newUserFilterType(value) {
        this.newUserFilterValue = value;
        this.getNewUserCount();
    }

    messageFilter(value) {
        if(value === 'Custom') {
            this.messageDateFilter = ! this.messageDateFilter;
        } else {
            this.messageFilterFrom = value;
        }
        
        this.getMessageCount();
    }

    messageFilterType(value) {
        this.messageFilterValue = value;
        this.getMessageCount();
    }

    tradeFilter(value) {
        if(value === 'Custom') {
            this.tradeDateFilter = ! this.tradeDateFilter;
        } else {
            this.tradeFilterFrom = value;
        }
        
        this.getTradeCount();
    }

    socialFilter(value) {
        if(value === 'Custom') {
            this.socialDateFilter = ! this.socialDateFilter;
        } else {
            this.socialFilterFrom = value;
        }
        
        this.getSocialCount();
    }

    createTable() {
        let tableArr: Element[] = this.users.data;
        this.dataSource = new MatTableDataSource(tableArr);
        this.dataSource.sort = this.sort;
    }

    logout() {
        this.accountService.logout();
    }
}

export interface Element {
    name: string,
    mobileNo: number,
    emailId: string,
    createdAt: string,
    status: string
}