import { Component, ViewChild, OnInit } from '@angular/core';
import { AccountService, UsersService } from '@app/_services';
import { BannerService } from '../_services/banner.services';
import { first } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AddEditBusinessBannerComponent } from '@app/add-edit-business-banner/add-edit-business-banner.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmAlertComponent } from '@app/_components/confirm-alert/confirm-alert.component';

export interface DialogData {
  name: string;
  type: string;
  url: string;
  status: string;
  isNew: boolean;
  translations: any;
  appId: string;
  id: string;
  accURL: string;
}


@Component({
  selector: 'app-push-notification-list',
  templateUrl: './push-notification-list.component.html',
  styleUrls: ['./push-notification-list.component.less']
})
export class PushNotificationListComponent implements OnInit {
  users: any;
  ELEMENT_DATA: USER[] = [];
  @ViewChild(MatSort) sort: MatSort;

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];

  displayedColumns: string[] = ['name', 'type', 'url', 'dtype','status', 'action'];
  // displayedColumns: string[] = ['name', 'type', 'url', 'dtype','status'];
  dataSource: MatTableDataSource<USER> = new MatTableDataSource();
  typeValue: string = "SOCIAL";

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  routeUrl: any;
  constructor(
    // private userServices: UsersService,
    private bannerService: BannerService,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.routeUrl = this.router.url;
  }

  ngOnInit() {
    this.createTable();
  }
  createTable() {
    this.isLoading = true;
    this.bannerService.getpushnotifictionList(this.currentPage, this.pageSize)
      .pipe(first())
      .subscribe({
        next: (users) => {
          this.users = users;
          this.dataSource.data = this.users.data;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = this.users.recordsTotal;
          });
          this.isLoading = false;
        },
        error: error => {
          console.log(error);
          this.isLoading = false;
        }
      });
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;        
    this.currentPage = event.pageIndex;
    this.createTable();
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    this.createTable()
  }

  addBusinessBanner(event: any) {
    const dialogRef = this.dialog.open(AddEditBusinessBannerComponent, {
      width: '50%',
      height: '85%',
      disableClose: true,
      data: {
        isNew: true, name: "", type: this.typeValue, status: "", url: "", translations: [
          {
            code: "",
            name: "url",
            value: ""
          }
        ],
        appId: "",
        id: '',
        accURL: ""
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.isSubmit) {
        this.fileUpload(result, true);
      }
    });
  }

  fileUpload(result, isNew) {
    const params: any = {
      "appSettingId": this.users.appSettingId,
      "banners": [
        {
          "type": result.formData.type,
          "name": result.formData.title,
          "url": "",
          "isoCountryCode": null,
          "status": result.formData.status,
          "translations": result.formData.trans
        }
      ]
    };
    if (!isNew) {
      params.banners[0].id = result.id;
    }
    var ImgList = [];
    if(typeof (result.Gurl[0].obj) !== "string") {
      ImgList.push(result.Gurl[0]);
      result.file.push({obj: result.Gurl[0].obj, value: ''});
    } else {
      params.banners[0].url = result.Gurl[0].value;
    }

    if (result.file.length > 0) {
      ImgList = [];
      result.file.forEach(element => {
        if (typeof (element.obj) !== "string") {
          ImgList.push(element);
        }
      });
      if (ImgList.length > 0) {
        this.ImageUpload(result, params, isNew, ImgList);
      } else {
        if (isNew) {
          this.addBusinessReq(params);
        } else {
          this.editBusinessReq(params);
        }
      }
    } else {
      this.editBusinessReq(params);
    }
  }

  ImageUpload(result, params, isNew, FileList) {
    var fd = new FormData();
    const files = FileList.map((item) => {
      fd.append('file', item.obj);
    });
    this.bannerService.FileUpload(fd)
      .subscribe({
        next: (res) => {
          result.Gurl.forEach(transItem => {
            res.forEach(ImgItem => {
              if (transItem.obj.name === ImgItem.originalFileName) {
                params.banners[0].url = ImgItem.url;
              }
            });
          });
          this.UpdatewithImageURL(result, params, res, isNew);
        },
        error: error => {
          console.log(error);
          this.isLoading = false;
          this.snackBar.open("Something went wrong. Please check the admin", "", {
            duration: 2000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: ['Red']
          });
        }
      });
  }

  UpdatewithImageURL(result, params, res, isNew) {
    const transItemArr = [];
    // params.banners[0].url = res[0].url;
    result.formData.trans.forEach(transItem => {
      if (typeof (transItem.file) !== "string") {
        res.forEach(ImgItem => {
          if (transItem.file.name === ImgItem.originalFileName) {
            transItemArr.push({
              "code": transItem.code,
              "name": "url",
              "value": ImgItem.url
            });
          }
        });
      } else {
        transItemArr.push({
          "code": transItem.code,
          "name": "url",
          "value": transItem.value
        });
      }
      
    });
    params.banners[0].translations = transItemArr;
    if (isNew) {
      // if (result.formData.trans.length > 0) {
      //   // params.banners[0].url = '';
      //   params.banners[0].translations[0].value = res[0].url;
      // } else {
      //   params.banners[0].url = res[0].url;
      //   params.banners[0].translations[0].value = res[0].url;
      // }
      this.addBusinessReq(params);
    }
    else {
      this.editBusinessReq(params);
    }
  }

  addBusinessReq(FormData) {
    this.bannerService.saveBusinessItem(FormData)
      .subscribe({
        next: (res) => {
          this.users = res;
          this.dataSource.data = this.users.banners;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = this.dataSource.data.length;
          });
          this.isLoading = false;
          this.snackBar.open("Social Banner Saved Successfully", "", {
            duration: 2000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: ['Green']
          });
        },
        error: error => {
          console.log(error);
          this.isLoading = false;
          this.snackBar.open("Something went wrong. Please check the admin", "", {
            duration: 2000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: ['Red']
          });
        }
      });
  }

  editBusinessBanner(Item: any) {
    const dialogRef = this.dialog.open(AddEditBusinessBannerComponent, {
      width: '50%',
      height: '85%',
      disableClose: true,
      data: { isNew: false, name: Item.name, type: this.typeValue, status: Item.status, url: Item.url, translations: Item.translations, appId: Item.appSettingId, id: Item.id },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.isSubmit) {
        this.fileUpload(result, false);
      }
    });
  }

  editBusinessReq(params) {
    // if (params.translations.length > 0) {
    //   params.banners[0].url = '';
    //   params.banners[0].translations[0].value = url;
    // } else {
    //   params.banners[0].url = url;
    //   params.banners[0].translations[0].value = url;
    // }
    this.bannerService.editBusinessItem(params)
      .pipe(first())
      .subscribe({
        next: (res) => {
          this.users = res;
          this.dataSource.data = this.users.banners;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = this.dataSource.data.length;
          });
          this.isLoading = false;
          this.snackBar.open("Social Banner Updated Successfully", "", {
            duration: 2000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: ['Green']
          });
        },
        error: error => {
          console.log(error);
          this.isLoading = false;
          this.snackBar.open("Something went wrong. Please check the admin", "", {
            duration: 2000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: ['Red']
          });
        }
      });

  }

  deleteBusinessBanner(event: any, Item: any) {
    const dialogRef = this.dialog.open(ConfirmAlertComponent, {
      width: '400px',
      height: '200px',
      disableClose: true,
      data: {
        message: 'Are you confirm to delete this notification?',
        title: 'Confirm',
        formData: Item
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.flag) {
        const params = {
          appSettingId: this.users.appSettingId,
          banners: [result.formData]
        }
        this.bannerService.deleteBusinessItem(params)
          .subscribe({
            next: (res) => {
              console.log(res);
              this.users = res;
              this.dataSource.data = this.users.banners;
              setTimeout(() => {
                this.paginator.pageIndex = this.currentPage;
                this.paginator.length = this.dataSource.data.length;
              });
              this.isLoading = false;
              this.snackBar.open("Social Banner Deleted Successfully", "", {
                duration: 2000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: ['Green']
              });
            },
            error: error => {
              console.log(error);
              this.isLoading = false;
              this.snackBar.open("Something went wrong. Please check the admin", "", {
                duration: 2000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: ['Red']
              });
            }
          });
      }
    });
  }

  logout() {
    this.accountService.logout();
  }

  editNotification(item){
    console.log("item : ", item);
    this.router.navigate(['/add-push-notification'], { queryParams: { id: item?.notificationId } });
    localStorage.setItem('notification', JSON.stringify(item))
  }
}
interface USER {
  Title: string,
  BannerPicture: string,
  Status: string,
  listingId: string
}