import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AccountService } from '@app/_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private accountService: AccountService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status) && this.accountService.userValue) {
            //     // auto logout if 401 or 403 response returned from api
            //     this.accountService.logout();
            // }

            return this.accountService.refreshToken().pipe(
                switchMap((data) => {
                  localStorage.clear();
                  data["mobileNo"]="8888888888";
                  localStorage.setItem('user', JSON.stringify(data));
                  console.log(JSON.stringify(data));
      
                  const newRequest = request.clone({
                    headers: request.headers.set('Authorization', `Bearer ${data['bearerToken']}`) // add new token to headers
                  });
      
                  return next.handle(newRequest); // send modified request to server
                }),
                catchError(error => {
                  // this.accountService.logout();
                  console.log(error);
                  return throwError(error);
                })
              );
             }
            const error = err.error?.message || err.statusText;
            console.error(err);
            return throwError(error);
        }))
    }
}