import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { Business } from '../_models/banner';

@Injectable({ providedIn: 'root' })
export class PushNotificationService {
    localData: any;
    constructor(private http: HttpClient) {
        // this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        // this.user = this.userSubject.asObservable();
        this.localData = JSON.parse(localStorage.getItem('user'));
    }
    getCountryList(){
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        // return this.http.get(`${environment.apiUrl}/api/v1.0/referenceDatas`, { headers });
        return of({
            "id": "603daec95c2eac4c115ab798",
            "referenceDataId": "1",
            "categories": [
              {
                "id": null,
                "name": "Electronics",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/electronics.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Mobile Phone",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/mobile-phone.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Home Appliance",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/home-appliance.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Furnitures",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/furnitures.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Plants",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/plants.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Pet",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/pet.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Clothes(Female)",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/clothes-female.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Clothes(Male)",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/clothes-male.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Beauty",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/beauty.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Food",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/food.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Gaming",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/gaming.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Motorcycle",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/motorcycle.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Baby Products",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/baby-products.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Others",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/trade-category/others.svg",
                "type": "PRODUCT",
                "translations": null
              },
              {
                "id": null,
                "name": "Restuarants",
                "url": null,
                "type": "SOCIAL",
                "translations": null
              },
              {
                "id": null,
                "name": "Park",
                "url": null,
                "type": "SOCIAL",
                "translations": null
              },
              {
                "id": null,
                "name": "Book Shop",
                "url": null,
                "type": "SOCIAL",
                "translations": null
              },
              {
                "id": null,
                "name": "Pet Shop",
                "url": null,
                "type": "SOCIAL",
                "translations": null
              },
              {
                "id": null,
                "name": "Cafe Dessert",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/cafe-dessert.svg",
                "type": "SHOP",
                "translations": null
              },
              {
                "id": null,
                "name": "Convenience Store",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/convienience-store.svg",
                "type": "SHOP",
                "translations": null
              },
              {
                "id": null,
                "name": "Pharmacy",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/pharmacy.svg",
                "type": "SHOP",
                "translations": null
              },
              {
                "id": null,
                "name": "Supermarket",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/supermarket.svg",
                "type": "SHOP",
                "translations": null
              },
              {
                "id": null,
                "name": "Bank",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/bank.svg",
                "type": "SHOP",
                "translations": null
              },
              {
                "id": null,
                "name": "Gas Station",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/gas-station.svg",
                "type": "SHOP",
                "translations": null
              },
              {
                "id": null,
                "name": "Electronics",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/electronics.svg",
                "type": "SHOP",
                "translations": null
              },
              {
                "id": null,
                "name": "Repair",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/repair.svg",
                "type": "SHOP",
                "translations": null
              },
              {
                "id": null,
                "name": "Restaurant",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/restaurant.svg",
                "type": "SHOP",
                "translations": null
              },
              {
                "id": null,
                "name": "Office Supply",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/office-supply.svg",
                "type": "SHOP",
                "translations": null
              },
              {
                "id": null,
                "name": "Pet",
                "url": "https://meeketapi.s3.ap-southeast-1.amazonaws.com/bsns-category/pet.svg",
                "type": "SHOP",
                "translations": null
              }
            ],
            "languages": [
              {
                "code": "en",
                "name": "English",
                "value": "English"
              },
              {
                "code": "vi_VN",
                "name": "Vietnamese",
                "value": "Tiếng Việt"
              }
            ],
            "channels": [
              // {
              //   "code": "WEBSITE",
              //   "name": "Website",
              //   "value": "http://meeket.vn/"
              // },
              // {
              //   "code": "FACEBOOK",
              //   "name": "facebook",
              //   "value": "www.facebook.com/meeket.vn"
              // },
              // {
              //   "code": "INSTAGRAM",
              //   "name": "Instagram",
              //   "value": "https://www.instagram.com/meeket.vn"
              // },
              // {
              //   "code": "YOUTUBE",
              //   "name": "Youtube",
              //   "value": "https://www.youtube.com/channel/UC28vRw85D0Znf29hIxNazcA"
              // },
              // {
              //   "code": "TIKTOK",
              //   "name": "Tiktok",
              //   "value": "www.tiktok.com/@meeket.vn"
              // }
              {
                "code": "EMAIL",
                "name": "EMAIL",
                "value": "http://meeket.vn/"
              },
              {
                "code": "PUSH",
                "name": "PUSH",
                "value": "http://meeket.vn/"
              },
              {
                "code": "SMS",
                "name": "SMS",
                "value": "http://meeket.vn/"
              }
            ],
            "banners": [
              {
                "code": "BANNER",
                "name": "banner-1",
                "value": "https://dev.meeket.vn/api/v1.0/files/16581160804002863-(MEEKET) APP BANNER-01.png"
              },
              {
                "code": "BANNER",
                "name": "banner-2",
                "value": "https://dev.meeket.vn/api/v1.0/files/16581160809199469-(MEEKET) APP BANNER-02.png"
              },
              {
                "code": "BANNER",
                "name": "banner-3",
                "value": "https://dev.meeket.vn/api/v1.0/files/16581160814710767-(MEEKET) APP BANNER-03.png"
              }
            ],
            "reports": [
              {
                "code": "POST",
                "name": "It's spam",
                "value": "SPAM"
              },
              {
                "code": "POST",
                "name": "Nudity or sexual activity",
                "value": "NUDITY"
              },
              {
                "code": "POST",
                "name": "Hate speech or symbols",
                "value": "HATE_SPEECH"
              },
              {
                "code": "POST",
                "name": "I just don't like it",
                "value": "DONT_LIKE_IT"
              },
              {
                "code": "POST",
                "name": "False information",
                "value": "FALSE_NEWS"
              },
              {
                "code": "POST",
                "name": "Bullying or harassment",
                "value": "BULLYING_HARASSMENT"
              },
              {
                "code": "POST",
                "name": "Scam or fraud",
                "value": "PRODUCT_SCAM_FRAUD"
              },
              {
                "code": "POST",
                "name": "Violence or dangerous organisations",
                "value": "VIOLENCE_DANGEROUS"
              },
              {
                "code": "POST",
                "name": "Intellectual property violation",
                "value": "INTELLECTUAL_PROPERTY"
              },
              {
                "code": "POST",
                "name": "Sale of illegal or regulated goods",
                "value": "SALE_OF_ILLEGAL_REGULATED_GOODS"
              },
              {
                "code": "POST",
                "name": "Suicide or self-injury",
                "value": "SELF_INJURY"
              },
              {
                "code": "POST",
                "name": "Eating disorders",
                "value": "EATING_DISORDER"
              },
              {
                "code": "POST",
                "name": "Something else",
                "value": "SOMETHING_ELSE"
              },
              {
                "code": "MEMBER",
                "name": "It's pretending to be someone else",
                "value": "USER_IMPERSONATION"
              },
              {
                "code": "MEMBER",
                "name": "It's posting content that shouldn't be here",
                "value": "INAPPROPRIATE"
              },
              {
                "code": "MEMBER",
                "name": "It's spam",
                "value": "SPAM"
              },
              {
                "code": "MEMBER",
                "name": "I just don't like it",
                "value": "DONT_LIKE_IT"
              },
              {
                "code": "MEMBER",
                "name": "Suicide, self-injury or eating disorders",
                "value": "SELF_INJURY"
              },
              {
                "code": "MEMBER",
                "name": "Sale of illegal or regulated goods",
                "value": "SALE_OF_ILLEGAL_REGULATED_GOODS"
              },
              {
                "code": "MEMBER",
                "name": "Nudity or sexual activity",
                "value": "NUDITY"
              },
              {
                "code": "MEMBER",
                "name": "Hate speech or symbols",
                "value": "HATE_SPEECH"
              },
              {
                "code": "MEMBER",
                "name": "Violence or dangerous organisations",
                "value": "VIOLENCE_DANGEROUS"
              },
              {
                "code": "MEMBER",
                "name": "Bullying or harassment",
                "value": "BULLYING_HARASSMENT"
              },
              {
                "code": "MEMBER",
                "name": "Intellectual property violation",
                "value": "INTELLECTUAL_PROPERTY"
              },
              {
                "code": "MEMBER",
                "name": "Scam or fraud",
                "value": "PRODUCT_SCAM_FRAUD"
              },
              {
                "code": "MEMBER",
                "name": "False information",
                "value": "FALSE_NEWS"
              },
              {
                "code": "MEMBER",
                "name": "Something else",
                "value": "SOMETHING_ELSE"
              }
            ],
            "countries": [
              {
                "name": "Afghanistan",
                "iso3": "AFG",
                "iso2": "AF",
                "phoneCode": "93",
                "currency": "AFN",
                "currencyName": "Afghan afghani",
                "currencySymbol": "؋",
                "region": "Asia",
                "subRegion": "Southern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Kabul",
                    "gmtOffset": "16200",
                    "gmtOffsetName": "UTC+04:30",
                    "abbreviation": "AFT",
                    "tzName": "Afghanistan Time"
                  }
                ]
              },
              {
                "name": "Aland Islands",
                "iso3": "ALA",
                "iso2": "AX",
                "phoneCode": "+358-18",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Mariehamn",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Albania",
                "iso3": "ALB",
                "iso2": "AL",
                "phoneCode": "355",
                "currency": "ALL",
                "currencyName": "Albanian lek",
                "currencySymbol": "Lek",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Tirane",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Algeria",
                "iso3": "DZA",
                "iso2": "DZ",
                "phoneCode": "213",
                "currency": "DZD",
                "currencyName": "Algerian dinar",
                "currencySymbol": "دج",
                "region": "Africa",
                "subRegion": "Northern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Algiers",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "American Samoa",
                "iso3": "ASM",
                "iso2": "AS",
                "phoneCode": "+1-684",
                "currency": "USD",
                "currencyName": "US Dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Polynesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Pago_Pago",
                    "gmtOffset": "-39600",
                    "gmtOffsetName": "UTC-11:00",
                    "abbreviation": "SST",
                    "tzName": "Samoa Standard Time"
                  }
                ]
              },
              {
                "name": "Andorra",
                "iso3": "AND",
                "iso2": "AD",
                "phoneCode": "376",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Andorra",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Angola",
                "iso3": "AGO",
                "iso2": "AO",
                "phoneCode": "244",
                "currency": "AOA",
                "currencyName": "Angolan kwanza",
                "currencySymbol": "Kz",
                "region": "Africa",
                "subRegion": "Middle Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Luanda",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  }
                ]
              },
              {
                "name": "Anguilla",
                "iso3": "AIA",
                "iso2": "AI",
                "phoneCode": "+1-264",
                "currency": "XCD",
                "currencyName": "East Caribbean dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Anguilla",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Antarctica",
                "iso3": "ATA",
                "iso2": "AQ",
                "phoneCode": "672",
                "currency": "AAD",
                "currencyName": "Antarctican dollar",
                "currencySymbol": "$",
                "region": "Polar",
                "subRegion": null,
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Antarctica/Casey",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "AWST",
                    "tzName": "Australian Western Standard Time"
                  },
                  {
                    "zoneName": "Antarctica/Davis",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "DAVT",
                    "tzName": "Davis Time"
                  },
                  {
                    "zoneName": "Antarctica/DumontDUrville",
                    "gmtOffset": "36000",
                    "gmtOffsetName": "UTC+10:00",
                    "abbreviation": "DDUT",
                    "tzName": "Dumont d'Urville Time"
                  },
                  {
                    "zoneName": "Antarctica/Mawson",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "MAWT",
                    "tzName": "Mawson Station Time"
                  },
                  {
                    "zoneName": "Antarctica/McMurdo",
                    "gmtOffset": "46800",
                    "gmtOffsetName": "UTC+13:00",
                    "abbreviation": "NZDT",
                    "tzName": "New Zealand Daylight Time"
                  },
                  {
                    "zoneName": "Antarctica/Palmer",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "CLST",
                    "tzName": "Chile Summer Time"
                  },
                  {
                    "zoneName": "Antarctica/Rothera",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ROTT",
                    "tzName": "Rothera Research Station Time"
                  },
                  {
                    "zoneName": "Antarctica/Syowa",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "SYOT",
                    "tzName": "Showa Station Time"
                  },
                  {
                    "zoneName": "Antarctica/Troll",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  },
                  {
                    "zoneName": "Antarctica/Vostok",
                    "gmtOffset": "21600",
                    "gmtOffsetName": "UTC+06:00",
                    "abbreviation": "VOST",
                    "tzName": "Vostok Station Time"
                  }
                ]
              },
              {
                "name": "Antigua And Barbuda",
                "iso3": "ATG",
                "iso2": "AG",
                "phoneCode": "+1-268",
                "currency": "XCD",
                "currencyName": "Eastern Caribbean dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Antigua",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Argentina",
                "iso3": "ARG",
                "iso2": "AR",
                "phoneCode": "54",
                "currency": "ARS",
                "currencyName": "Argentine peso",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Argentina/Buenos_Aires",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/Catamarca",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/Cordoba",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/Jujuy",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/La_Rioja",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/Mendoza",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/Rio_Gallegos",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/Salta",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/San_Juan",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/San_Luis",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/Tucuman",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  },
                  {
                    "zoneName": "America/Argentina/Ushuaia",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "ART",
                    "tzName": "Argentina Time"
                  }
                ]
              },
              {
                "name": "Armenia",
                "iso3": "ARM",
                "iso2": "AM",
                "phoneCode": "374",
                "currency": "AMD",
                "currencyName": "Armenian dram",
                "currencySymbol": "֏",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Yerevan",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "AMT",
                    "tzName": "Armenia Time"
                  }
                ]
              },
              {
                "name": "Aruba",
                "iso3": "ABW",
                "iso2": "AW",
                "phoneCode": "297",
                "currency": "AWG",
                "currencyName": "Aruban florin",
                "currencySymbol": "ƒ",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Aruba",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Australia",
                "iso3": "AUS",
                "iso2": "AU",
                "phoneCode": "61",
                "currency": "AUD",
                "currencyName": "Australian dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Australia and New Zealand",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Antarctica/Macquarie",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "MIST",
                    "tzName": "Macquarie Island Station Time"
                  },
                  {
                    "zoneName": "Australia/Adelaide",
                    "gmtOffset": "37800",
                    "gmtOffsetName": "UTC+10:30",
                    "abbreviation": "ACDT",
                    "tzName": "Australian Central Daylight Saving Time"
                  },
                  {
                    "zoneName": "Australia/Brisbane",
                    "gmtOffset": "36000",
                    "gmtOffsetName": "UTC+10:00",
                    "abbreviation": "AEST",
                    "tzName": "Australian Eastern Standard Time"
                  },
                  {
                    "zoneName": "Australia/Broken_Hill",
                    "gmtOffset": "37800",
                    "gmtOffsetName": "UTC+10:30",
                    "abbreviation": "ACDT",
                    "tzName": "Australian Central Daylight Saving Time"
                  },
                  {
                    "zoneName": "Australia/Currie",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "AEDT",
                    "tzName": "Australian Eastern Daylight Saving Time"
                  },
                  {
                    "zoneName": "Australia/Darwin",
                    "gmtOffset": "34200",
                    "gmtOffsetName": "UTC+09:30",
                    "abbreviation": "ACST",
                    "tzName": "Australian Central Standard Time"
                  },
                  {
                    "zoneName": "Australia/Eucla",
                    "gmtOffset": "31500",
                    "gmtOffsetName": "UTC+08:45",
                    "abbreviation": "ACWST",
                    "tzName": "Australian Central Western Standard Time (Unofficial)"
                  },
                  {
                    "zoneName": "Australia/Hobart",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "AEDT",
                    "tzName": "Australian Eastern Daylight Saving Time"
                  },
                  {
                    "zoneName": "Australia/Lindeman",
                    "gmtOffset": "36000",
                    "gmtOffsetName": "UTC+10:00",
                    "abbreviation": "AEST",
                    "tzName": "Australian Eastern Standard Time"
                  },
                  {
                    "zoneName": "Australia/Lord_Howe",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "LHST",
                    "tzName": "Lord Howe Summer Time"
                  },
                  {
                    "zoneName": "Australia/Melbourne",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "AEDT",
                    "tzName": "Australian Eastern Daylight Saving Time"
                  },
                  {
                    "zoneName": "Australia/Perth",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "AWST",
                    "tzName": "Australian Western Standard Time"
                  },
                  {
                    "zoneName": "Australia/Sydney",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "AEDT",
                    "tzName": "Australian Eastern Daylight Saving Time"
                  }
                ]
              },
              {
                "name": "Austria",
                "iso3": "AUT",
                "iso2": "AT",
                "phoneCode": "43",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Western Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Vienna",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Azerbaijan",
                "iso3": "AZE",
                "iso2": "AZ",
                "phoneCode": "994",
                "currency": "AZN",
                "currencyName": "Azerbaijani manat",
                "currencySymbol": "m",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Baku",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "AZT",
                    "tzName": "Azerbaijan Time"
                  }
                ]
              },
              {
                "name": "Bahrain",
                "iso3": "BHR",
                "iso2": "BH",
                "phoneCode": "973",
                "currency": "BHD",
                "currencyName": "Bahraini dinar",
                "currencySymbol": ".د.ب",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 3,
                "timezones": [
                  {
                    "zoneName": "Asia/Bahrain",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "AST",
                    "tzName": "Arabia Standard Time"
                  }
                ]
              },
              {
                "name": "Bangladesh",
                "iso3": "BGD",
                "iso2": "BD",
                "phoneCode": "880",
                "currency": "BDT",
                "currencyName": "Bangladeshi taka",
                "currencySymbol": "৳",
                "region": "Asia",
                "subRegion": "Southern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Dhaka",
                    "gmtOffset": "21600",
                    "gmtOffsetName": "UTC+06:00",
                    "abbreviation": "BDT",
                    "tzName": "Bangladesh Standard Time"
                  }
                ]
              },
              {
                "name": "Barbados",
                "iso3": "BRB",
                "iso2": "BB",
                "phoneCode": "+1-246",
                "currency": "BBD",
                "currencyName": "Barbadian dollar",
                "currencySymbol": "Bds$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Barbados",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Belarus",
                "iso3": "BLR",
                "iso2": "BY",
                "phoneCode": "375",
                "currency": "BYN",
                "currencyName": "Belarusian ruble",
                "currencySymbol": "Br",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Europe/Minsk",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "MSK",
                    "tzName": "Moscow Time"
                  }
                ]
              },
              {
                "name": "Belgium",
                "iso3": "BEL",
                "iso2": "BE",
                "phoneCode": "32",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Western Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Brussels",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Belize",
                "iso3": "BLZ",
                "iso2": "BZ",
                "phoneCode": "501",
                "currency": "BZD",
                "currencyName": "Belize dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Central America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Belize",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Benin",
                "iso3": "BEN",
                "iso2": "BJ",
                "phoneCode": "229",
                "currency": "XOF",
                "currencyName": "West African CFA franc",
                "currencySymbol": "CFA",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Porto-Novo",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  }
                ]
              },
              {
                "name": "Bermuda",
                "iso3": "BMU",
                "iso2": "BM",
                "phoneCode": "+1-441",
                "currency": "BMD",
                "currencyName": "Bermudian dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Northern America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Atlantic/Bermuda",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Bhutan",
                "iso3": "BTN",
                "iso2": "BT",
                "phoneCode": "975",
                "currency": "BTN",
                "currencyName": "Bhutanese ngultrum",
                "currencySymbol": "Nu.",
                "region": "Asia",
                "subRegion": "Southern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Thimphu",
                    "gmtOffset": "21600",
                    "gmtOffsetName": "UTC+06:00",
                    "abbreviation": "BTT",
                    "tzName": "Bhutan Time"
                  }
                ]
              },
              {
                "name": "Bolivia",
                "iso3": "BOL",
                "iso2": "BO",
                "phoneCode": "591",
                "currency": "BOB",
                "currencyName": "Bolivian boliviano",
                "currencySymbol": "Bs.",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/La_Paz",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "BOT",
                    "tzName": "Bolivia Time"
                  }
                ]
              },
              {
                "name": "Bonaire, Sint Eustatius and Saba",
                "iso3": "BES",
                "iso2": "BQ",
                "phoneCode": "599",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Anguilla",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Bosnia and Herzegovina",
                "iso3": "BIH",
                "iso2": "BA",
                "phoneCode": "387",
                "currency": "BAM",
                "currencyName": "Bosnia and Herzegovina convertible mark",
                "currencySymbol": "KM",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Sarajevo",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Botswana",
                "iso3": "BWA",
                "iso2": "BW",
                "phoneCode": "267",
                "currency": "BWP",
                "currencyName": "Botswana pula",
                "currencySymbol": "P",
                "region": "Africa",
                "subRegion": "Southern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Gaborone",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "CAT",
                    "tzName": "Central Africa Time"
                  }
                ]
              },
              {
                "name": "Bouvet Island",
                "iso3": "BVT",
                "iso2": "BV",
                "phoneCode": "55",
                "currency": "NOK",
                "currencyName": "Norwegian Krone",
                "currencySymbol": "kr",
                "region": null,
                "subRegion": null,
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Oslo",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Brazil",
                "iso3": "BRA",
                "iso2": "BR",
                "phoneCode": "55",
                "currency": "BRL",
                "currencyName": "Brazilian real",
                "currencySymbol": "R$",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Araguaina",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "BRT",
                    "tzName": "Brasília Time"
                  },
                  {
                    "zoneName": "America/Bahia",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "BRT",
                    "tzName": "Brasília Time"
                  },
                  {
                    "zoneName": "America/Belem",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "BRT",
                    "tzName": "Brasília Time"
                  },
                  {
                    "zoneName": "America/Boa_Vista",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AMT",
                    "tzName": "Amazon Time (Brazil)[3"
                  },
                  {
                    "zoneName": "America/Campo_Grande",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AMT",
                    "tzName": "Amazon Time (Brazil)[3"
                  },
                  {
                    "zoneName": "America/Cuiaba",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "BRT",
                    "tzName": "Brasilia Time"
                  },
                  {
                    "zoneName": "America/Eirunepe",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "ACT",
                    "tzName": "Acre Time"
                  },
                  {
                    "zoneName": "America/Fortaleza",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "BRT",
                    "tzName": "Brasília Time"
                  },
                  {
                    "zoneName": "America/Maceio",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "BRT",
                    "tzName": "Brasília Time"
                  },
                  {
                    "zoneName": "America/Manaus",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AMT",
                    "tzName": "Amazon Time (Brazil)"
                  },
                  {
                    "zoneName": "America/Noronha",
                    "gmtOffset": "-7200",
                    "gmtOffsetName": "UTC-02:00",
                    "abbreviation": "FNT",
                    "tzName": "Fernando de Noronha Time"
                  },
                  {
                    "zoneName": "America/Porto_Velho",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AMT",
                    "tzName": "Amazon Time (Brazil)[3"
                  },
                  {
                    "zoneName": "America/Recife",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "BRT",
                    "tzName": "Brasília Time"
                  },
                  {
                    "zoneName": "America/Rio_Branco",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "ACT",
                    "tzName": "Acre Time"
                  },
                  {
                    "zoneName": "America/Santarem",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "BRT",
                    "tzName": "Brasília Time"
                  },
                  {
                    "zoneName": "America/Sao_Paulo",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "BRT",
                    "tzName": "Brasília Time"
                  }
                ]
              },
              {
                "name": "British Indian Ocean Territory",
                "iso3": "IOT",
                "iso2": "IO",
                "phoneCode": "246",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Indian/Chagos",
                    "gmtOffset": "21600",
                    "gmtOffsetName": "UTC+06:00",
                    "abbreviation": "IOT",
                    "tzName": "Indian Ocean Time"
                  }
                ]
              },
              {
                "name": "Brunei",
                "iso3": "BRN",
                "iso2": "BN",
                "phoneCode": "673",
                "currency": "BND",
                "currencyName": "Brunei dollar",
                "currencySymbol": "B$",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Brunei",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "BNT",
                    "tzName": "Brunei Darussalam Time"
                  }
                ]
              },
              {
                "name": "Bulgaria",
                "iso3": "BGR",
                "iso2": "BG",
                "phoneCode": "359",
                "currency": "BGN",
                "currencyName": "Bulgarian lev",
                "currencySymbol": "Лв.",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Sofia",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Burkina Faso",
                "iso3": "BFA",
                "iso2": "BF",
                "phoneCode": "226",
                "currency": "XOF",
                "currencyName": "West African CFA franc",
                "currencySymbol": "CFA",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Ouagadougou",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Burundi",
                "iso3": "BDI",
                "iso2": "BI",
                "phoneCode": "257",
                "currency": "BIF",
                "currencyName": "Burundian franc",
                "currencySymbol": "FBu",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Bujumbura",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "CAT",
                    "tzName": "Central Africa Time"
                  }
                ]
              },
              {
                "name": "Cambodia",
                "iso3": "KHM",
                "iso2": "KH",
                "phoneCode": "855",
                "currency": "KHR",
                "currencyName": "Cambodian riel",
                "currencySymbol": "KHR",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Phnom_Penh",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "ICT",
                    "tzName": "Indochina Time"
                  }
                ]
              },
              {
                "name": "Cameroon",
                "iso3": "CMR",
                "iso2": "CM",
                "phoneCode": "237",
                "currency": "XAF",
                "currencyName": "Central African CFA franc",
                "currencySymbol": "FCFA",
                "region": "Africa",
                "subRegion": "Middle Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Douala",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  }
                ]
              },
              {
                "name": "Canada",
                "iso3": "CAN",
                "iso2": "CA",
                "phoneCode": "1",
                "currency": "CAD",
                "currencyName": "Canadian dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Northern America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Atikokan",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America)"
                  },
                  {
                    "zoneName": "America/Blanc-Sablon",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  },
                  {
                    "zoneName": "America/Cambridge_Bay",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America)"
                  },
                  {
                    "zoneName": "America/Creston",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America)"
                  },
                  {
                    "zoneName": "America/Dawson",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America)"
                  },
                  {
                    "zoneName": "America/Dawson_Creek",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America)"
                  },
                  {
                    "zoneName": "America/Edmonton",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America)"
                  },
                  {
                    "zoneName": "America/Fort_Nelson",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America)"
                  },
                  {
                    "zoneName": "America/Glace_Bay",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  },
                  {
                    "zoneName": "America/Goose_Bay",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  },
                  {
                    "zoneName": "America/Halifax",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  },
                  {
                    "zoneName": "America/Inuvik",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Iqaluit",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Moncton",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  },
                  {
                    "zoneName": "America/Nipigon",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Pangnirtung",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Rainy_River",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Rankin_Inlet",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Regina",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Resolute",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/St_Johns",
                    "gmtOffset": "-12600",
                    "gmtOffsetName": "UTC-03:30",
                    "abbreviation": "NST",
                    "tzName": "Newfoundland Standard Time"
                  },
                  {
                    "zoneName": "America/Swift_Current",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Thunder_Bay",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Toronto",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Vancouver",
                    "gmtOffset": "-28800",
                    "gmtOffsetName": "UTC-08:00",
                    "abbreviation": "PST",
                    "tzName": "Pacific Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Whitehorse",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Winnipeg",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Yellowknife",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America"
                  }
                ]
              },
              {
                "name": "Cape Verde",
                "iso3": "CPV",
                "iso2": "CV",
                "phoneCode": "238",
                "currency": "CVE",
                "currencyName": "Cape Verdean escudo",
                "currencySymbol": "$",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Atlantic/Cape_Verde",
                    "gmtOffset": "-3600",
                    "gmtOffsetName": "UTC-01:00",
                    "abbreviation": "CVT",
                    "tzName": "Cape Verde Time"
                  }
                ]
              },
              {
                "name": "Cayman Islands",
                "iso3": "CYM",
                "iso2": "KY",
                "phoneCode": "+1-345",
                "currency": "KYD",
                "currencyName": "Cayman Islands dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Cayman",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Central African Republic",
                "iso3": "CAF",
                "iso2": "CF",
                "phoneCode": "236",
                "currency": "XAF",
                "currencyName": "Central African CFA franc",
                "currencySymbol": "FCFA",
                "region": "Africa",
                "subRegion": "Middle Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Bangui",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  }
                ]
              },
              {
                "name": "Chad",
                "iso3": "TCD",
                "iso2": "TD",
                "phoneCode": "235",
                "currency": "XAF",
                "currencyName": "Central African CFA franc",
                "currencySymbol": "FCFA",
                "region": "Africa",
                "subRegion": "Middle Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Ndjamena",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  }
                ]
              },
              {
                "name": "Chile",
                "iso3": "CHL",
                "iso2": "CL",
                "phoneCode": "56",
                "currency": "CLP",
                "currencyName": "Chilean peso",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "America/Punta_Arenas",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "CLST",
                    "tzName": "Chile Summer Time"
                  },
                  {
                    "zoneName": "America/Santiago",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "CLST",
                    "tzName": "Chile Summer Time"
                  },
                  {
                    "zoneName": "Pacific/Easter",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EASST",
                    "tzName": "Easter Island Summer Time"
                  }
                ]
              },
              {
                "name": "China",
                "iso3": "CHN",
                "iso2": "CN",
                "phoneCode": "86",
                "currency": "CNY",
                "currencyName": "Chinese yuan",
                "currencySymbol": "¥",
                "region": "Asia",
                "subRegion": "Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Shanghai",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "CST",
                    "tzName": "China Standard Time"
                  },
                  {
                    "zoneName": "Asia/Urumqi",
                    "gmtOffset": "21600",
                    "gmtOffsetName": "UTC+06:00",
                    "abbreviation": "XJT",
                    "tzName": "China Standard Time"
                  }
                ]
              },
              {
                "name": "Christmas Island",
                "iso3": "CXR",
                "iso2": "CX",
                "phoneCode": "61",
                "currency": "AUD",
                "currencyName": "Australian dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Australia and New Zealand",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Indian/Christmas",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "CXT",
                    "tzName": "Christmas Island Time"
                  }
                ]
              },
              {
                "name": "Cocos (Keeling) Islands",
                "iso3": "CCK",
                "iso2": "CC",
                "phoneCode": "61",
                "currency": "AUD",
                "currencyName": "Australian dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Australia and New Zealand",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Indian/Cocos",
                    "gmtOffset": "23400",
                    "gmtOffsetName": "UTC+06:30",
                    "abbreviation": "CCT",
                    "tzName": "Cocos Islands Time"
                  }
                ]
              },
              {
                "name": "Colombia",
                "iso3": "COL",
                "iso2": "CO",
                "phoneCode": "57",
                "currency": "COP",
                "currencyName": "Colombian peso",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Bogota",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "COT",
                    "tzName": "Colombia Time"
                  }
                ]
              },
              {
                "name": "Comoros",
                "iso3": "COM",
                "iso2": "KM",
                "phoneCode": "269",
                "currency": "KMF",
                "currencyName": "Comorian franc",
                "currencySymbol": "CF",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Indian/Comoro",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "Congo",
                "iso3": "COG",
                "iso2": "CG",
                "phoneCode": "242",
                "currency": "XAF",
                "currencyName": "Central African CFA franc",
                "currencySymbol": "FC",
                "region": "Africa",
                "subRegion": "Middle Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Brazzaville",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  }
                ]
              },
              {
                "name": "Cook Islands",
                "iso3": "COK",
                "iso2": "CK",
                "phoneCode": "682",
                "currency": "NZD",
                "currencyName": "Cook Islands dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Polynesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Rarotonga",
                    "gmtOffset": "-36000",
                    "gmtOffsetName": "UTC-10:00",
                    "abbreviation": "CKT",
                    "tzName": "Cook Island Time"
                  }
                ]
              },
              {
                "name": "Costa Rica",
                "iso3": "CRI",
                "iso2": "CR",
                "phoneCode": "506",
                "currency": "CRC",
                "currencyName": "Costa Rican colón",
                "currencySymbol": "₡",
                "region": "Americas",
                "subRegion": "Central America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Costa_Rica",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Cote D'Ivoire (Ivory Coast)",
                "iso3": "CIV",
                "iso2": "CI",
                "phoneCode": "225",
                "currency": "XOF",
                "currencyName": "West African CFA franc",
                "currencySymbol": "CFA",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Abidjan",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Croatia",
                "iso3": "HRV",
                "iso2": "HR",
                "phoneCode": "385",
                "currency": "HRK",
                "currencyName": "Croatian kuna",
                "currencySymbol": "kn",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Zagreb",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Cuba",
                "iso3": "CUB",
                "iso2": "CU",
                "phoneCode": "53",
                "currency": "CUP",
                "currencyName": "Cuban peso",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Havana",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "CST",
                    "tzName": "Cuba Standard Time"
                  }
                ]
              },
              {
                "name": "Curaçao",
                "iso3": "CUW",
                "iso2": "CW",
                "phoneCode": "599",
                "currency": "ANG",
                "currencyName": "Netherlands Antillean guilder",
                "currencySymbol": "ƒ",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Curacao",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Cyprus",
                "iso3": "CYP",
                "iso2": "CY",
                "phoneCode": "357",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Famagusta",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  },
                  {
                    "zoneName": "Asia/Nicosia",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Czech Republic",
                "iso3": "CZE",
                "iso2": "CZ",
                "phoneCode": "420",
                "currency": "CZK",
                "currencyName": "Czech koruna",
                "currencySymbol": "Kč",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Prague",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Democratic Republic of the Congo",
                "iso3": "COD",
                "iso2": "CD",
                "phoneCode": "243",
                "currency": "CDF",
                "currencyName": "Congolese Franc",
                "currencySymbol": "FC",
                "region": "Africa",
                "subRegion": "Middle Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Kinshasa",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  },
                  {
                    "zoneName": "Africa/Lubumbashi",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "CAT",
                    "tzName": "Central Africa Time"
                  }
                ]
              },
              {
                "name": "Denmark",
                "iso3": "DNK",
                "iso2": "DK",
                "phoneCode": "45",
                "currency": "DKK",
                "currencyName": "Danish krone",
                "currencySymbol": "Kr.",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Copenhagen",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Djibouti",
                "iso3": "DJI",
                "iso2": "DJ",
                "phoneCode": "253",
                "currency": "DJF",
                "currencyName": "Djiboutian franc",
                "currencySymbol": "Fdj",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Djibouti",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "Dominica",
                "iso3": "DMA",
                "iso2": "DM",
                "phoneCode": "+1-767",
                "currency": "XCD",
                "currencyName": "Eastern Caribbean dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Dominica",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Dominican Republic",
                "iso3": "DOM",
                "iso2": "DO",
                "phoneCode": "+1-809 and 1-829",
                "currency": "DOP",
                "currencyName": "Dominican peso",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Santo_Domingo",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "East Timor",
                "iso3": "TLS",
                "iso2": "TL",
                "phoneCode": "670",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Dili",
                    "gmtOffset": "32400",
                    "gmtOffsetName": "UTC+09:00",
                    "abbreviation": "TLT",
                    "tzName": "Timor Leste Time"
                  }
                ]
              },
              {
                "name": "Ecuador",
                "iso3": "ECU",
                "iso2": "EC",
                "phoneCode": "593",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Guayaquil",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "ECT",
                    "tzName": "Ecuador Time"
                  },
                  {
                    "zoneName": "Pacific/Galapagos",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "GALT",
                    "tzName": "Galápagos Time"
                  }
                ]
              },
              {
                "name": "Egypt",
                "iso3": "EGY",
                "iso2": "EG",
                "phoneCode": "20",
                "currency": "EGP",
                "currencyName": "Egyptian pound",
                "currencySymbol": "ج.م",
                "region": "Africa",
                "subRegion": "Northern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Cairo",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "El Salvador",
                "iso3": "SLV",
                "iso2": "SV",
                "phoneCode": "503",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Central America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/El_Salvador",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Equatorial Guinea",
                "iso3": "GNQ",
                "iso2": "GQ",
                "phoneCode": "240",
                "currency": "XAF",
                "currencyName": "Central African CFA franc",
                "currencySymbol": "FCFA",
                "region": "Africa",
                "subRegion": "Middle Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Malabo",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  }
                ]
              },
              {
                "name": "Eritrea",
                "iso3": "ERI",
                "iso2": "ER",
                "phoneCode": "291",
                "currency": "ERN",
                "currencyName": "Eritrean nakfa",
                "currencySymbol": "Nfk",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Asmara",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "Estonia",
                "iso3": "EST",
                "iso2": "EE",
                "phoneCode": "372",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Tallinn",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Ethiopia",
                "iso3": "ETH",
                "iso2": "ET",
                "phoneCode": "251",
                "currency": "ETB",
                "currencyName": "Ethiopian birr",
                "currencySymbol": "Nkf",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Addis_Ababa",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "Falkland Islands",
                "iso3": "FLK",
                "iso2": "FK",
                "phoneCode": "500",
                "currency": "FKP",
                "currencyName": "Falkland Islands pound",
                "currencySymbol": "£",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Atlantic/Stanley",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "FKST",
                    "tzName": "Falkland Islands Summer Time"
                  }
                ]
              },
              {
                "name": "Faroe Islands",
                "iso3": "FRO",
                "iso2": "FO",
                "phoneCode": "298",
                "currency": "DKK",
                "currencyName": "Danish krone",
                "currencySymbol": "Kr.",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Atlantic/Faroe",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "WET",
                    "tzName": "Western European Time"
                  }
                ]
              },
              {
                "name": "Fiji Islands",
                "iso3": "FJI",
                "iso2": "FJ",
                "phoneCode": "679",
                "currency": "FJD",
                "currencyName": "Fijian dollar",
                "currencySymbol": "FJ$",
                "region": "Oceania",
                "subRegion": "Melanesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Fiji",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "FJT",
                    "tzName": "Fiji Time"
                  }
                ]
              },
              {
                "name": "Finland",
                "iso3": "FIN",
                "iso2": "FI",
                "phoneCode": "358",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Helsinki",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "France",
                "iso3": "FRA",
                "iso2": "FR",
                "phoneCode": "33",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Western Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Paris",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "French Guiana",
                "iso3": "GUF",
                "iso2": "GF",
                "phoneCode": "594",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Cayenne",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "GFT",
                    "tzName": "French Guiana Time"
                  }
                ]
              },
              {
                "name": "French Polynesia",
                "iso3": "PYF",
                "iso2": "PF",
                "phoneCode": "689",
                "currency": "XPF",
                "currencyName": "CFP franc",
                "currencySymbol": "₣",
                "region": "Oceania",
                "subRegion": "Polynesia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Pacific/Gambier",
                    "gmtOffset": "-32400",
                    "gmtOffsetName": "UTC-09:00",
                    "abbreviation": "GAMT",
                    "tzName": "Gambier Islands Time"
                  },
                  {
                    "zoneName": "Pacific/Marquesas",
                    "gmtOffset": "-34200",
                    "gmtOffsetName": "UTC-09:30",
                    "abbreviation": "MART",
                    "tzName": "Marquesas Islands Time"
                  },
                  {
                    "zoneName": "Pacific/Tahiti",
                    "gmtOffset": "-36000",
                    "gmtOffsetName": "UTC-10:00",
                    "abbreviation": "TAHT",
                    "tzName": "Tahiti Time"
                  }
                ]
              },
              {
                "name": "French Southern Territories",
                "iso3": "ATF",
                "iso2": "TF",
                "phoneCode": "262",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Africa",
                "subRegion": "Southern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Indian/Kerguelen",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "TFT",
                    "tzName": "French Southern and Antarctic Time"
                  }
                ]
              },
              {
                "name": "Gabon",
                "iso3": "GAB",
                "iso2": "GA",
                "phoneCode": "241",
                "currency": "XAF",
                "currencyName": "Central African CFA franc",
                "currencySymbol": "FCFA",
                "region": "Africa",
                "subRegion": "Middle Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Libreville",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  }
                ]
              },
              {
                "name": "Gambia The",
                "iso3": "GMB",
                "iso2": "GM",
                "phoneCode": "220",
                "currency": "GMD",
                "currencyName": "Gambian dalasi",
                "currencySymbol": "D",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Banjul",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Georgia",
                "iso3": "GEO",
                "iso2": "GE",
                "phoneCode": "995",
                "currency": "GEL",
                "currencyName": "Georgian lari",
                "currencySymbol": "ლ",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Tbilisi",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "GET",
                    "tzName": "Georgia Standard Time"
                  }
                ]
              },
              {
                "name": "Germany",
                "iso3": "DEU",
                "iso2": "DE",
                "phoneCode": "49",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Western Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Berlin",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  },
                  {
                    "zoneName": "Europe/Busingen",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Ghana",
                "iso3": "GHA",
                "iso2": "GH",
                "phoneCode": "233",
                "currency": "GHS",
                "currencyName": "Ghanaian cedi",
                "currencySymbol": "GH₵",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Accra",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Gibraltar",
                "iso3": "GIB",
                "iso2": "GI",
                "phoneCode": "350",
                "currency": "GIP",
                "currencyName": "Gibraltar pound",
                "currencySymbol": "£",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Gibraltar",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Greece",
                "iso3": "GRC",
                "iso2": "GR",
                "phoneCode": "30",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Athens",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Greenland",
                "iso3": "GRL",
                "iso2": "GL",
                "phoneCode": "299",
                "currency": "DKK",
                "currencyName": "Danish krone",
                "currencySymbol": "Kr.",
                "region": "Americas",
                "subRegion": "Northern America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Danmarkshavn",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  },
                  {
                    "zoneName": "America/Nuuk",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "WGT",
                    "tzName": "West Greenland Time"
                  },
                  {
                    "zoneName": "America/Scoresbysund",
                    "gmtOffset": "-3600",
                    "gmtOffsetName": "UTC-01:00",
                    "abbreviation": "EGT",
                    "tzName": "Eastern Greenland Time"
                  },
                  {
                    "zoneName": "America/Thule",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Grenada",
                "iso3": "GRD",
                "iso2": "GD",
                "phoneCode": "+1-473",
                "currency": "XCD",
                "currencyName": "Eastern Caribbean dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Grenada",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Guadeloupe",
                "iso3": "GLP",
                "iso2": "GP",
                "phoneCode": "590",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Guadeloupe",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Guam",
                "iso3": "GUM",
                "iso2": "GU",
                "phoneCode": "+1-671",
                "currency": "USD",
                "currencyName": "US Dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Micronesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Guam",
                    "gmtOffset": "36000",
                    "gmtOffsetName": "UTC+10:00",
                    "abbreviation": "CHST",
                    "tzName": "Chamorro Standard Time"
                  }
                ]
              },
              {
                "name": "Guatemala",
                "iso3": "GTM",
                "iso2": "GT",
                "phoneCode": "502",
                "currency": "GTQ",
                "currencyName": "Guatemalan quetzal",
                "currencySymbol": "Q",
                "region": "Americas",
                "subRegion": "Central America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Guatemala",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Guernsey and Alderney",
                "iso3": "GGY",
                "iso2": "GG",
                "phoneCode": "+44-1481",
                "currency": "GBP",
                "currencyName": "British pound",
                "currencySymbol": "£",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Guernsey",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Guinea",
                "iso3": "GIN",
                "iso2": "GN",
                "phoneCode": "224",
                "currency": "GNF",
                "currencyName": "Guinean franc",
                "currencySymbol": "FG",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Conakry",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Guinea-Bissau",
                "iso3": "GNB",
                "iso2": "GW",
                "phoneCode": "245",
                "currency": "XOF",
                "currencyName": "West African CFA franc",
                "currencySymbol": "CFA",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Bissau",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Guyana",
                "iso3": "GUY",
                "iso2": "GY",
                "phoneCode": "592",
                "currency": "GYD",
                "currencyName": "Guyanese dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Guyana",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "GYT",
                    "tzName": "Guyana Time"
                  }
                ]
              },
              {
                "name": "Haiti",
                "iso3": "HTI",
                "iso2": "HT",
                "phoneCode": "509",
                "currency": "HTG",
                "currencyName": "Haitian gourde",
                "currencySymbol": "G",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Port-au-Prince",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Heard Island and McDonald Islands",
                "iso3": "HMD",
                "iso2": "HM",
                "phoneCode": "672",
                "currency": "AUD",
                "currencyName": "Australian dollar",
                "currencySymbol": "$",
                "region": null,
                "subRegion": null,
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Indian/Kerguelen",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "TFT",
                    "tzName": "French Southern and Antarctic Time"
                  }
                ]
              },
              {
                "name": "Honduras",
                "iso3": "HND",
                "iso2": "HN",
                "phoneCode": "504",
                "currency": "HNL",
                "currencyName": "Honduran lempira",
                "currencySymbol": "L",
                "region": "Americas",
                "subRegion": "Central America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Tegucigalpa",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Hong Kong S.A.R.",
                "iso3": "HKG",
                "iso2": "HK",
                "phoneCode": "852",
                "currency": "HKD",
                "currencyName": "Hong Kong dollar",
                "currencySymbol": "$",
                "region": "Asia",
                "subRegion": "Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Hong_Kong",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "HKT",
                    "tzName": "Hong Kong Time"
                  }
                ]
              },
              {
                "name": "Hungary",
                "iso3": "HUN",
                "iso2": "HU",
                "phoneCode": "36",
                "currency": "HUF",
                "currencyName": "Hungarian forint",
                "currencySymbol": "Ft",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Budapest",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Iceland",
                "iso3": "ISL",
                "iso2": "IS",
                "phoneCode": "354",
                "currency": "ISK",
                "currencyName": "Icelandic króna",
                "currencySymbol": "kr",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Atlantic/Reykjavik",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "India",
                "iso3": "IND",
                "iso2": "IN",
                "phoneCode": "91",
                "currency": "INR",
                "currencyName": "Indian rupee",
                "currencySymbol": "₹",
                "region": "Asia",
                "subRegion": "Southern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Kolkata",
                    "gmtOffset": "19800",
                    "gmtOffsetName": "UTC+05:30",
                    "abbreviation": "IST",
                    "tzName": "Indian Standard Time"
                  }
                ]
              },
              {
                "name": "Indonesia",
                "iso3": "IDN",
                "iso2": "ID",
                "phoneCode": "62",
                "currency": "IDR",
                "currencyName": "Indonesian rupiah",
                "currencySymbol": "Rp",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Jakarta",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "WIB",
                    "tzName": "Western Indonesian Time"
                  },
                  {
                    "zoneName": "Asia/Jayapura",
                    "gmtOffset": "32400",
                    "gmtOffsetName": "UTC+09:00",
                    "abbreviation": "WIT",
                    "tzName": "Eastern Indonesian Time"
                  },
                  {
                    "zoneName": "Asia/Makassar",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "WITA",
                    "tzName": "Central Indonesia Time"
                  },
                  {
                    "zoneName": "Asia/Pontianak",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "WIB",
                    "tzName": "Western Indonesian Time"
                  }
                ]
              },
              {
                "name": "Iran",
                "iso3": "IRN",
                "iso2": "IR",
                "phoneCode": "98",
                "currency": "IRR",
                "currencyName": "Iranian rial",
                "currencySymbol": "﷼",
                "region": "Asia",
                "subRegion": "Southern Asia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Asia/Tehran",
                    "gmtOffset": "12600",
                    "gmtOffsetName": "UTC+03:30",
                    "abbreviation": "IRDT",
                    "tzName": "Iran Daylight Time"
                  }
                ]
              },
              {
                "name": "Iraq",
                "iso3": "IRQ",
                "iso2": "IQ",
                "phoneCode": "964",
                "currency": "IQD",
                "currencyName": "Iraqi dinar",
                "currencySymbol": "د.ع",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 3,
                "timezones": [
                  {
                    "zoneName": "Asia/Baghdad",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "AST",
                    "tzName": "Arabia Standard Time"
                  }
                ]
              },
              {
                "name": "Ireland",
                "iso3": "IRL",
                "iso2": "IE",
                "phoneCode": "353",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Dublin",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Israel",
                "iso3": "ISR",
                "iso2": "IL",
                "phoneCode": "972",
                "currency": "ILS",
                "currencyName": "Israeli new shekel",
                "currencySymbol": "₪",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Jerusalem",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "IST",
                    "tzName": "Israel Standard Time"
                  }
                ]
              },
              {
                "name": "Italy",
                "iso3": "ITA",
                "iso2": "IT",
                "phoneCode": "39",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Rome",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Jamaica",
                "iso3": "JAM",
                "iso2": "JM",
                "phoneCode": "+1-876",
                "currency": "JMD",
                "currencyName": "Jamaican dollar",
                "currencySymbol": "J$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Jamaica",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Japan",
                "iso3": "JPN",
                "iso2": "JP",
                "phoneCode": "81",
                "currency": "JPY",
                "currencyName": "Japanese yen",
                "currencySymbol": "¥",
                "region": "Asia",
                "subRegion": "Eastern Asia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Asia/Tokyo",
                    "gmtOffset": "32400",
                    "gmtOffsetName": "UTC+09:00",
                    "abbreviation": "JST",
                    "tzName": "Japan Standard Time"
                  }
                ]
              },
              {
                "name": "Jersey",
                "iso3": "JEY",
                "iso2": "JE",
                "phoneCode": "+44-1534",
                "currency": "GBP",
                "currencyName": "British pound",
                "currencySymbol": "£",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Jersey",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Jordan",
                "iso3": "JOR",
                "iso2": "JO",
                "phoneCode": "962",
                "currency": "JOD",
                "currencyName": "Jordanian dinar",
                "currencySymbol": "ا.د",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 3,
                "timezones": [
                  {
                    "zoneName": "Asia/Amman",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Kazakhstan",
                "iso3": "KAZ",
                "iso2": "KZ",
                "phoneCode": "7",
                "currency": "KZT",
                "currencyName": "Kazakhstani tenge",
                "currencySymbol": "лв",
                "region": "Asia",
                "subRegion": "Central Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Almaty",
                    "gmtOffset": "21600",
                    "gmtOffsetName": "UTC+06:00",
                    "abbreviation": "ALMT",
                    "tzName": "Alma-Ata Time[1"
                  },
                  {
                    "zoneName": "Asia/Aqtau",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "AQTT",
                    "tzName": "Aqtobe Time"
                  },
                  {
                    "zoneName": "Asia/Aqtobe",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "AQTT",
                    "tzName": "Aqtobe Time"
                  },
                  {
                    "zoneName": "Asia/Atyrau",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "MSD+1",
                    "tzName": "Moscow Daylight Time+1"
                  },
                  {
                    "zoneName": "Asia/Oral",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "ORAT",
                    "tzName": "Oral Time"
                  },
                  {
                    "zoneName": "Asia/Qostanay",
                    "gmtOffset": "21600",
                    "gmtOffsetName": "UTC+06:00",
                    "abbreviation": "QYZST",
                    "tzName": "Qyzylorda Summer Time"
                  },
                  {
                    "zoneName": "Asia/Qyzylorda",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "QYZT",
                    "tzName": "Qyzylorda Summer Time"
                  }
                ]
              },
              {
                "name": "Kenya",
                "iso3": "KEN",
                "iso2": "KE",
                "phoneCode": "254",
                "currency": "KES",
                "currencyName": "Kenyan shilling",
                "currencySymbol": "KSh",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Nairobi",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "Kiribati",
                "iso3": "KIR",
                "iso2": "KI",
                "phoneCode": "686",
                "currency": "AUD",
                "currencyName": "Australian dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Micronesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Enderbury",
                    "gmtOffset": "46800",
                    "gmtOffsetName": "UTC+13:00",
                    "abbreviation": "PHOT",
                    "tzName": "Phoenix Island Time"
                  },
                  {
                    "zoneName": "Pacific/Kiritimati",
                    "gmtOffset": "50400",
                    "gmtOffsetName": "UTC+14:00",
                    "abbreviation": "LINT",
                    "tzName": "Line Islands Time"
                  },
                  {
                    "zoneName": "Pacific/Tarawa",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "GILT",
                    "tzName": "Gilbert Island Time"
                  }
                ]
              },
              {
                "name": "Kosovo",
                "iso3": "XKX",
                "iso2": "XK",
                "phoneCode": "383",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Belgrade",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Kuwait",
                "iso3": "KWT",
                "iso2": "KW",
                "phoneCode": "965",
                "currency": "KWD",
                "currencyName": "Kuwaiti dinar",
                "currencySymbol": "ك.د",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 3,
                "timezones": [
                  {
                    "zoneName": "Asia/Kuwait",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "AST",
                    "tzName": "Arabia Standard Time"
                  }
                ]
              },
              {
                "name": "Kyrgyzstan",
                "iso3": "KGZ",
                "iso2": "KG",
                "phoneCode": "996",
                "currency": "KGS",
                "currencyName": "Kyrgyzstani som",
                "currencySymbol": "лв",
                "region": "Asia",
                "subRegion": "Central Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Bishkek",
                    "gmtOffset": "21600",
                    "gmtOffsetName": "UTC+06:00",
                    "abbreviation": "KGT",
                    "tzName": "Kyrgyzstan Time"
                  }
                ]
              },
              {
                "name": "Laos",
                "iso3": "LAO",
                "iso2": "LA",
                "phoneCode": "856",
                "currency": "LAK",
                "currencyName": "Lao kip",
                "currencySymbol": "₭",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Asia/Vientiane",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "ICT",
                    "tzName": "Indochina Time"
                  }
                ]
              },
              {
                "name": "Latvia",
                "iso3": "LVA",
                "iso2": "LV",
                "phoneCode": "371",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Riga",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Lebanon",
                "iso3": "LBN",
                "iso2": "LB",
                "phoneCode": "961",
                "currency": "LBP",
                "currencyName": "Lebanese pound",
                "currencySymbol": "£",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Asia/Beirut",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Lesotho",
                "iso3": "LSO",
                "iso2": "LS",
                "phoneCode": "266",
                "currency": "LSL",
                "currencyName": "Lesotho loti",
                "currencySymbol": "L",
                "region": "Africa",
                "subRegion": "Southern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Maseru",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "SAST",
                    "tzName": "South African Standard Time"
                  }
                ]
              },
              {
                "name": "Liberia",
                "iso3": "LBR",
                "iso2": "LR",
                "phoneCode": "231",
                "currency": "LRD",
                "currencyName": "Liberian dollar",
                "currencySymbol": "$",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Monrovia",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Libya",
                "iso3": "LBY",
                "iso2": "LY",
                "phoneCode": "218",
                "currency": "LYD",
                "currencyName": "Libyan dinar",
                "currencySymbol": "د.ل",
                "region": "Africa",
                "subRegion": "Northern Africa",
                "decimalUnit": 3,
                "timezones": [
                  {
                    "zoneName": "Africa/Tripoli",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Liechtenstein",
                "iso3": "LIE",
                "iso2": "LI",
                "phoneCode": "423",
                "currency": "CHF",
                "currencyName": "Swiss franc",
                "currencySymbol": "CHf",
                "region": "Europe",
                "subRegion": "Western Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Vaduz",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Lithuania",
                "iso3": "LTU",
                "iso2": "LT",
                "phoneCode": "370",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Vilnius",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Luxembourg",
                "iso3": "LUX",
                "iso2": "LU",
                "phoneCode": "352",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Western Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Luxembourg",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Macau S.A.R.",
                "iso3": "MAC",
                "iso2": "MO",
                "phoneCode": "853",
                "currency": "MOP",
                "currencyName": "Macanese pataca",
                "currencySymbol": "$",
                "region": "Asia",
                "subRegion": "Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Macau",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "CST",
                    "tzName": "China Standard Time"
                  }
                ]
              },
              {
                "name": "Macedonia",
                "iso3": "MKD",
                "iso2": "MK",
                "phoneCode": "389",
                "currency": "MKD",
                "currencyName": "Denar",
                "currencySymbol": "ден",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Europe/Skopje",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Madagascar",
                "iso3": "MDG",
                "iso2": "MG",
                "phoneCode": "261",
                "currency": "MGA",
                "currencyName": "Malagasy ariary",
                "currencySymbol": "Ar",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Indian/Antananarivo",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "Malawi",
                "iso3": "MWI",
                "iso2": "MW",
                "phoneCode": "265",
                "currency": "MWK",
                "currencyName": "Malawian kwacha",
                "currencySymbol": "MK",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Blantyre",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "CAT",
                    "tzName": "Central Africa Time"
                  }
                ]
              },
              {
                "name": "Malaysia",
                "iso3": "MYS",
                "iso2": "MY",
                "phoneCode": "60",
                "currency": "MYR",
                "currencyName": "Malaysian ringgit",
                "currencySymbol": "RM",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Kuala_Lumpur",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "MYT",
                    "tzName": "Malaysia Time"
                  },
                  {
                    "zoneName": "Asia/Kuching",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "MYT",
                    "tzName": "Malaysia Time"
                  }
                ]
              },
              {
                "name": "Maldives",
                "iso3": "MDV",
                "iso2": "MV",
                "phoneCode": "960",
                "currency": "MVR",
                "currencyName": "Maldivian rufiyaa",
                "currencySymbol": "Rf",
                "region": "Asia",
                "subRegion": "Southern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Indian/Maldives",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "MVT",
                    "tzName": "Maldives Time"
                  }
                ]
              },
              {
                "name": "Mali",
                "iso3": "MLI",
                "iso2": "ML",
                "phoneCode": "223",
                "currency": "XOF",
                "currencyName": "West African CFA franc",
                "currencySymbol": "CFA",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Bamako",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Malta",
                "iso3": "MLT",
                "iso2": "MT",
                "phoneCode": "356",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Malta",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Man (Isle of)",
                "iso3": "IMN",
                "iso2": "IM",
                "phoneCode": "+44-1624",
                "currency": "GBP",
                "currencyName": "British pound",
                "currencySymbol": "£",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Isle_of_Man",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Marshall Islands",
                "iso3": "MHL",
                "iso2": "MH",
                "phoneCode": "692",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Micronesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Kwajalein",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "MHT",
                    "tzName": "Marshall Islands Time"
                  },
                  {
                    "zoneName": "Pacific/Majuro",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "MHT",
                    "tzName": "Marshall Islands Time"
                  }
                ]
              },
              {
                "name": "Martinique",
                "iso3": "MTQ",
                "iso2": "MQ",
                "phoneCode": "596",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Martinique",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Mauritania",
                "iso3": "MRT",
                "iso2": "MR",
                "phoneCode": "222",
                "currency": "MRO",
                "currencyName": "Mauritanian ouguiya",
                "currencySymbol": "MRU",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Nouakchott",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Mauritius",
                "iso3": "MUS",
                "iso2": "MU",
                "phoneCode": "230",
                "currency": "MUR",
                "currencyName": "Mauritian rupee",
                "currencySymbol": "₨",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Indian/Mauritius",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "MUT",
                    "tzName": "Mauritius Time"
                  }
                ]
              },
              {
                "name": "Mayotte",
                "iso3": "MYT",
                "iso2": "YT",
                "phoneCode": "262",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Indian/Mayotte",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "Mexico",
                "iso3": "MEX",
                "iso2": "MX",
                "phoneCode": "52",
                "currency": "MXN",
                "currencyName": "Mexican peso",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Central America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Bahia_Banderas",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Cancun",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Chihuahua",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Hermosillo",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Matamoros",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Mazatlan",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Merida",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Mexico_City",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Monterrey",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Ojinaga",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Tijuana",
                    "gmtOffset": "-28800",
                    "gmtOffsetName": "UTC-08:00",
                    "abbreviation": "PST",
                    "tzName": "Pacific Standard Time (North America"
                  }
                ]
              },
              {
                "name": "Micronesia",
                "iso3": "FSM",
                "iso2": "FM",
                "phoneCode": "691",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Micronesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Chuuk",
                    "gmtOffset": "36000",
                    "gmtOffsetName": "UTC+10:00",
                    "abbreviation": "CHUT",
                    "tzName": "Chuuk Time"
                  },
                  {
                    "zoneName": "Pacific/Kosrae",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "KOST",
                    "tzName": "Kosrae Time"
                  },
                  {
                    "zoneName": "Pacific/Pohnpei",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "PONT",
                    "tzName": "Pohnpei Standard Time"
                  }
                ]
              },
              {
                "name": "Moldova",
                "iso3": "MDA",
                "iso2": "MD",
                "phoneCode": "373",
                "currency": "MDL",
                "currencyName": "Moldovan leu",
                "currencySymbol": "L",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Chisinau",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Monaco",
                "iso3": "MCO",
                "iso2": "MC",
                "phoneCode": "377",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Western Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Monaco",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Mongolia",
                "iso3": "MNG",
                "iso2": "MN",
                "phoneCode": "976",
                "currency": "MNT",
                "currencyName": "Mongolian tögrög",
                "currencySymbol": "₮",
                "region": "Asia",
                "subRegion": "Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Choibalsan",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "CHOT",
                    "tzName": "Choibalsan Standard Time"
                  },
                  {
                    "zoneName": "Asia/Hovd",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "HOVT",
                    "tzName": "Hovd Time"
                  },
                  {
                    "zoneName": "Asia/Ulaanbaatar",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "ULAT",
                    "tzName": "Ulaanbaatar Standard Time"
                  }
                ]
              },
              {
                "name": "Montenegro",
                "iso3": "MNE",
                "iso2": "ME",
                "phoneCode": "382",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Podgorica",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Montserrat",
                "iso3": "MSR",
                "iso2": "MS",
                "phoneCode": "+1-664",
                "currency": "XCD",
                "currencyName": "Eastern Caribbean dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Montserrat",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Morocco",
                "iso3": "MAR",
                "iso2": "MA",
                "phoneCode": "212",
                "currency": "MAD",
                "currencyName": "Moroccan dirham",
                "currencySymbol": "DH",
                "region": "Africa",
                "subRegion": "Northern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Casablanca",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WEST",
                    "tzName": "Western European Summer Time"
                  }
                ]
              },
              {
                "name": "Mozambique",
                "iso3": "MOZ",
                "iso2": "MZ",
                "phoneCode": "258",
                "currency": "MZN",
                "currencyName": "Mozambican metical",
                "currencySymbol": "MT",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Maputo",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "CAT",
                    "tzName": "Central Africa Time"
                  }
                ]
              },
              {
                "name": "Myanmar",
                "iso3": "MMR",
                "iso2": "MM",
                "phoneCode": "95",
                "currency": "MMK",
                "currencyName": "Burmese kyat",
                "currencySymbol": "K",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Asia/Yangon",
                    "gmtOffset": "23400",
                    "gmtOffsetName": "UTC+06:30",
                    "abbreviation": "MMT",
                    "tzName": "Myanmar Standard Time"
                  }
                ]
              },
              {
                "name": "Namibia",
                "iso3": "NAM",
                "iso2": "NA",
                "phoneCode": "264",
                "currency": "NAD",
                "currencyName": "Namibian dollar",
                "currencySymbol": "$",
                "region": "Africa",
                "subRegion": "Southern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Windhoek",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "WAST",
                    "tzName": "West Africa Summer Time"
                  }
                ]
              },
              {
                "name": "Nauru",
                "iso3": "NRU",
                "iso2": "NR",
                "phoneCode": "674",
                "currency": "AUD",
                "currencyName": "Australian dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Micronesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Nauru",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "NRT",
                    "tzName": "Nauru Time"
                  }
                ]
              },
              {
                "name": "Nepal",
                "iso3": "NPL",
                "iso2": "NP",
                "phoneCode": "977",
                "currency": "NPR",
                "currencyName": "Nepalese rupee",
                "currencySymbol": "₨",
                "region": "Asia",
                "subRegion": "Southern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Kathmandu",
                    "gmtOffset": "20700",
                    "gmtOffsetName": "UTC+05:45",
                    "abbreviation": "NPT",
                    "tzName": "Nepal Time"
                  }
                ]
              },
              {
                "name": "Netherlands",
                "iso3": "NLD",
                "iso2": "NL",
                "phoneCode": "31",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Western Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Amsterdam",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "New Caledonia",
                "iso3": "NCL",
                "iso2": "NC",
                "phoneCode": "687",
                "currency": "XPF",
                "currencyName": "CFP franc",
                "currencySymbol": "₣",
                "region": "Oceania",
                "subRegion": "Melanesia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Pacific/Noumea",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "NCT",
                    "tzName": "New Caledonia Time"
                  }
                ]
              },
              {
                "name": "New Zealand",
                "iso3": "NZL",
                "iso2": "NZ",
                "phoneCode": "64",
                "currency": "NZD",
                "currencyName": "New Zealand dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Australia and New Zealand",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Auckland",
                    "gmtOffset": "46800",
                    "gmtOffsetName": "UTC+13:00",
                    "abbreviation": "NZDT",
                    "tzName": "New Zealand Daylight Time"
                  },
                  {
                    "zoneName": "Pacific/Chatham",
                    "gmtOffset": "49500",
                    "gmtOffsetName": "UTC+13:45",
                    "abbreviation": "CHAST",
                    "tzName": "Chatham Standard Time"
                  }
                ]
              },
              {
                "name": "Nicaragua",
                "iso3": "NIC",
                "iso2": "NI",
                "phoneCode": "505",
                "currency": "NIO",
                "currencyName": "Nicaraguan córdoba",
                "currencySymbol": "C$",
                "region": "Americas",
                "subRegion": "Central America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Managua",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Niger",
                "iso3": "NER",
                "iso2": "NE",
                "phoneCode": "227",
                "currency": "XOF",
                "currencyName": "West African CFA franc",
                "currencySymbol": "CFA",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Niamey",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  }
                ]
              },
              {
                "name": "Nigeria",
                "iso3": "NGA",
                "iso2": "NG",
                "phoneCode": "234",
                "currency": "NGN",
                "currencyName": "Nigerian naira",
                "currencySymbol": "₦",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Lagos",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WAT",
                    "tzName": "West Africa Time"
                  }
                ]
              },
              {
                "name": "Niue",
                "iso3": "NIU",
                "iso2": "NU",
                "phoneCode": "683",
                "currency": "NZD",
                "currencyName": "New Zealand dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Polynesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Niue",
                    "gmtOffset": "-39600",
                    "gmtOffsetName": "UTC-11:00",
                    "abbreviation": "NUT",
                    "tzName": "Niue Time"
                  }
                ]
              },
              {
                "name": "Norfolk Island",
                "iso3": "NFK",
                "iso2": "NF",
                "phoneCode": "672",
                "currency": "AUD",
                "currencyName": "Australian dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Australia and New Zealand",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Norfolk",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "NFT",
                    "tzName": "Norfolk Time"
                  }
                ]
              },
              {
                "name": "North Korea",
                "iso3": "PRK",
                "iso2": "KP",
                "phoneCode": "850",
                "currency": "KPW",
                "currencyName": "North Korean Won",
                "currencySymbol": "₩",
                "region": "Asia",
                "subRegion": "Eastern Asia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Asia/Pyongyang",
                    "gmtOffset": "32400",
                    "gmtOffsetName": "UTC+09:00",
                    "abbreviation": "KST",
                    "tzName": "Korea Standard Time"
                  }
                ]
              },
              {
                "name": "Northern Mariana Islands",
                "iso3": "MNP",
                "iso2": "MP",
                "phoneCode": "+1-670",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Micronesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Saipan",
                    "gmtOffset": "36000",
                    "gmtOffsetName": "UTC+10:00",
                    "abbreviation": "ChST",
                    "tzName": "Chamorro Standard Time"
                  }
                ]
              },
              {
                "name": "Norway",
                "iso3": "NOR",
                "iso2": "NO",
                "phoneCode": "47",
                "currency": "NOK",
                "currencyName": "Norwegian krone",
                "currencySymbol": "kr",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Oslo",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Oman",
                "iso3": "OMN",
                "iso2": "OM",
                "phoneCode": "968",
                "currency": "OMR",
                "currencyName": "Omani rial",
                "currencySymbol": ".ع.ر",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 3,
                "timezones": [
                  {
                    "zoneName": "Asia/Muscat",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "GST",
                    "tzName": "Gulf Standard Time"
                  }
                ]
              },
              {
                "name": "Pakistan",
                "iso3": "PAK",
                "iso2": "PK",
                "phoneCode": "92",
                "currency": "PKR",
                "currencyName": "Pakistani rupee",
                "currencySymbol": "₨",
                "region": "Asia",
                "subRegion": "Southern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Karachi",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "PKT",
                    "tzName": "Pakistan Standard Time"
                  }
                ]
              },
              {
                "name": "Palau",
                "iso3": "PLW",
                "iso2": "PW",
                "phoneCode": "680",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Micronesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Palau",
                    "gmtOffset": "32400",
                    "gmtOffsetName": "UTC+09:00",
                    "abbreviation": "PWT",
                    "tzName": "Palau Time"
                  }
                ]
              },
              {
                "name": "Palestinian Territory Occupied",
                "iso3": "PSE",
                "iso2": "PS",
                "phoneCode": "970",
                "currency": "ILS",
                "currencyName": "Israeli new shekel",
                "currencySymbol": "₪",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Gaza",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  },
                  {
                    "zoneName": "Asia/Hebron",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Panama",
                "iso3": "PAN",
                "iso2": "PA",
                "phoneCode": "507",
                "currency": "PAB",
                "currencyName": "Panamanian balboa",
                "currencySymbol": "B/.",
                "region": "Americas",
                "subRegion": "Central America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Panama",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Papua new Guinea",
                "iso3": "PNG",
                "iso2": "PG",
                "phoneCode": "675",
                "currency": "PGK",
                "currencyName": "Papua New Guinean kina",
                "currencySymbol": "K",
                "region": "Oceania",
                "subRegion": "Melanesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Bougainville",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "BST",
                    "tzName": "Bougainville Standard Time[6"
                  },
                  {
                    "zoneName": "Pacific/Port_Moresby",
                    "gmtOffset": "36000",
                    "gmtOffsetName": "UTC+10:00",
                    "abbreviation": "PGT",
                    "tzName": "Papua New Guinea Time"
                  }
                ]
              },
              {
                "name": "Paraguay",
                "iso3": "PRY",
                "iso2": "PY",
                "phoneCode": "595",
                "currency": "PYG",
                "currencyName": "Paraguayan guarani",
                "currencySymbol": "₲",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "America/Asuncion",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "PYST",
                    "tzName": "Paraguay Summer Time"
                  }
                ]
              },
              {
                "name": "Peru",
                "iso3": "PER",
                "iso2": "PE",
                "phoneCode": "51",
                "currency": "PEN",
                "currencyName": "Peruvian sol",
                "currencySymbol": "S/.",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Lima",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "PET",
                    "tzName": "Peru Time"
                  }
                ]
              },
              {
                "name": "Philippines",
                "iso3": "PHL",
                "iso2": "PH",
                "phoneCode": "63",
                "currency": "PHP",
                "currencyName": "Philippine peso",
                "currencySymbol": "₱",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Manila",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "PHT",
                    "tzName": "Philippine Time"
                  }
                ]
              },
              {
                "name": "Pitcairn Island",
                "iso3": "PCN",
                "iso2": "PN",
                "phoneCode": "870",
                "currency": "NZD",
                "currencyName": "New Zealand dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Polynesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Pitcairn",
                    "gmtOffset": "-28800",
                    "gmtOffsetName": "UTC-08:00",
                    "abbreviation": "PST",
                    "tzName": "Pacific Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Poland",
                "iso3": "POL",
                "iso2": "PL",
                "phoneCode": "48",
                "currency": "PLN",
                "currencyName": "Polish złoty",
                "currencySymbol": "zł",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Warsaw",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Portugal",
                "iso3": "PRT",
                "iso2": "PT",
                "phoneCode": "351",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Atlantic/Azores",
                    "gmtOffset": "-3600",
                    "gmtOffsetName": "UTC-01:00",
                    "abbreviation": "AZOT",
                    "tzName": "Azores Standard Time"
                  },
                  {
                    "zoneName": "Atlantic/Madeira",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "WET",
                    "tzName": "Western European Time"
                  },
                  {
                    "zoneName": "Europe/Lisbon",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "WET",
                    "tzName": "Western European Time"
                  }
                ]
              },
              {
                "name": "Puerto Rico",
                "iso3": "PRI",
                "iso2": "PR",
                "phoneCode": "+1-787 and 1-939",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Puerto_Rico",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Qatar",
                "iso3": "QAT",
                "iso2": "QA",
                "phoneCode": "974",
                "currency": "QAR",
                "currencyName": "Qatari riyal",
                "currencySymbol": "ق.ر",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Qatar",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "AST",
                    "tzName": "Arabia Standard Time"
                  }
                ]
              },
              {
                "name": "Reunion",
                "iso3": "REU",
                "iso2": "RE",
                "phoneCode": "262",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Indian/Reunion",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "RET",
                    "tzName": "Réunion Time"
                  }
                ]
              },
              {
                "name": "Romania",
                "iso3": "ROU",
                "iso2": "RO",
                "phoneCode": "40",
                "currency": "RON",
                "currencyName": "Romanian leu",
                "currencySymbol": "lei",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Bucharest",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Russia",
                "iso3": "RUS",
                "iso2": "RU",
                "phoneCode": "7",
                "currency": "RUB",
                "currencyName": "Russian ruble",
                "currencySymbol": "₽",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Anadyr",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "ANAT",
                    "tzName": "Anadyr Time[4"
                  },
                  {
                    "zoneName": "Asia/Barnaul",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "KRAT",
                    "tzName": "Krasnoyarsk Time"
                  },
                  {
                    "zoneName": "Asia/Chita",
                    "gmtOffset": "32400",
                    "gmtOffsetName": "UTC+09:00",
                    "abbreviation": "YAKT",
                    "tzName": "Yakutsk Time"
                  },
                  {
                    "zoneName": "Asia/Irkutsk",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "IRKT",
                    "tzName": "Irkutsk Time"
                  },
                  {
                    "zoneName": "Asia/Kamchatka",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "PETT",
                    "tzName": "Kamchatka Time"
                  },
                  {
                    "zoneName": "Asia/Khandyga",
                    "gmtOffset": "32400",
                    "gmtOffsetName": "UTC+09:00",
                    "abbreviation": "YAKT",
                    "tzName": "Yakutsk Time"
                  },
                  {
                    "zoneName": "Asia/Krasnoyarsk",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "KRAT",
                    "tzName": "Krasnoyarsk Time"
                  },
                  {
                    "zoneName": "Asia/Magadan",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "MAGT",
                    "tzName": "Magadan Time"
                  },
                  {
                    "zoneName": "Asia/Novokuznetsk",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "KRAT",
                    "tzName": "Krasnoyarsk Time"
                  },
                  {
                    "zoneName": "Asia/Novosibirsk",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "NOVT",
                    "tzName": "Novosibirsk Time"
                  },
                  {
                    "zoneName": "Asia/Omsk",
                    "gmtOffset": "21600",
                    "gmtOffsetName": "UTC+06:00",
                    "abbreviation": "OMST",
                    "tzName": "Omsk Time"
                  },
                  {
                    "zoneName": "Asia/Sakhalin",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "SAKT",
                    "tzName": "Sakhalin Island Time"
                  },
                  {
                    "zoneName": "Asia/Srednekolymsk",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "SRET",
                    "tzName": "Srednekolymsk Time"
                  },
                  {
                    "zoneName": "Asia/Tomsk",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "MSD+3",
                    "tzName": "Moscow Daylight Time+3"
                  },
                  {
                    "zoneName": "Asia/Ust-Nera",
                    "gmtOffset": "36000",
                    "gmtOffsetName": "UTC+10:00",
                    "abbreviation": "VLAT",
                    "tzName": "Vladivostok Time"
                  },
                  {
                    "zoneName": "Asia/Vladivostok",
                    "gmtOffset": "36000",
                    "gmtOffsetName": "UTC+10:00",
                    "abbreviation": "VLAT",
                    "tzName": "Vladivostok Time"
                  },
                  {
                    "zoneName": "Asia/Yakutsk",
                    "gmtOffset": "32400",
                    "gmtOffsetName": "UTC+09:00",
                    "abbreviation": "YAKT",
                    "tzName": "Yakutsk Time"
                  },
                  {
                    "zoneName": "Asia/Yekaterinburg",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "YEKT",
                    "tzName": "Yekaterinburg Time"
                  },
                  {
                    "zoneName": "Europe/Astrakhan",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "SAMT",
                    "tzName": "Samara Time"
                  },
                  {
                    "zoneName": "Europe/Kaliningrad",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  },
                  {
                    "zoneName": "Europe/Kirov",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "MSK",
                    "tzName": "Moscow Time"
                  },
                  {
                    "zoneName": "Europe/Moscow",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "MSK",
                    "tzName": "Moscow Time"
                  },
                  {
                    "zoneName": "Europe/Samara",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "SAMT",
                    "tzName": "Samara Time"
                  },
                  {
                    "zoneName": "Europe/Saratov",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "MSD",
                    "tzName": "Moscow Daylight Time+4"
                  },
                  {
                    "zoneName": "Europe/Ulyanovsk",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "SAMT",
                    "tzName": "Samara Time"
                  },
                  {
                    "zoneName": "Europe/Volgograd",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "MSK",
                    "tzName": "Moscow Standard Time"
                  }
                ]
              },
              {
                "name": "Rwanda",
                "iso3": "RWA",
                "iso2": "RW",
                "phoneCode": "250",
                "currency": "RWF",
                "currencyName": "Rwandan franc",
                "currencySymbol": "FRw",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Kigali",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "CAT",
                    "tzName": "Central Africa Time"
                  }
                ]
              },
              {
                "name": "Saint Helena",
                "iso3": "SHN",
                "iso2": "SH",
                "phoneCode": "290",
                "currency": "SHP",
                "currencyName": "Saint Helena pound",
                "currencySymbol": "£",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Atlantic/St_Helena",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Saint Kitts And Nevis",
                "iso3": "KNA",
                "iso2": "KN",
                "phoneCode": "+1-869",
                "currency": "XCD",
                "currencyName": "Eastern Caribbean dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/St_Kitts",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Saint Lucia",
                "iso3": "LCA",
                "iso2": "LC",
                "phoneCode": "+1-758",
                "currency": "XCD",
                "currencyName": "Eastern Caribbean dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/St_Lucia",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Saint Pierre and Miquelon",
                "iso3": "SPM",
                "iso2": "PM",
                "phoneCode": "508",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Americas",
                "subRegion": "Northern America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Miquelon",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "PMDT",
                    "tzName": "Pierre & Miquelon Daylight Time"
                  }
                ]
              },
              {
                "name": "Saint Vincent And The Grenadines",
                "iso3": "VCT",
                "iso2": "VC",
                "phoneCode": "+1-784",
                "currency": "XCD",
                "currencyName": "Eastern Caribbean dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/St_Vincent",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Saint-Barthelemy",
                "iso3": "BLM",
                "iso2": "BL",
                "phoneCode": "590",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/St_Barthelemy",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Saint-Martin (French part)",
                "iso3": "MAF",
                "iso2": "MF",
                "phoneCode": "590",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Marigot",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Samoa",
                "iso3": "WSM",
                "iso2": "WS",
                "phoneCode": "685",
                "currency": "WST",
                "currencyName": "Samoan tālā",
                "currencySymbol": "SAT",
                "region": "Oceania",
                "subRegion": "Polynesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Apia",
                    "gmtOffset": "50400",
                    "gmtOffsetName": "UTC+14:00",
                    "abbreviation": "WST",
                    "tzName": "West Samoa Time"
                  }
                ]
              },
              {
                "name": "San Marino",
                "iso3": "SMR",
                "iso2": "SM",
                "phoneCode": "378",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/San_Marino",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Sao Tome and Principe",
                "iso3": "STP",
                "iso2": "ST",
                "phoneCode": "239",
                "currency": "STD",
                "currencyName": "Dobra",
                "currencySymbol": "Db",
                "region": "Africa",
                "subRegion": "Middle Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Sao_Tome",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Saudi Arabia",
                "iso3": "SAU",
                "iso2": "SA",
                "phoneCode": "966",
                "currency": "SAR",
                "currencyName": "Saudi riyal",
                "currencySymbol": "﷼",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Riyadh",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "AST",
                    "tzName": "Arabia Standard Time"
                  }
                ]
              },
              {
                "name": "Senegal",
                "iso3": "SEN",
                "iso2": "SN",
                "phoneCode": "221",
                "currency": "XOF",
                "currencyName": "West African CFA franc",
                "currencySymbol": "CFA",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Dakar",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Serbia",
                "iso3": "SRB",
                "iso2": "RS",
                "phoneCode": "381",
                "currency": "RSD",
                "currencyName": "Serbian dinar",
                "currencySymbol": "din",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Belgrade",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Seychelles",
                "iso3": "SYC",
                "iso2": "SC",
                "phoneCode": "248",
                "currency": "SCR",
                "currencyName": "Seychellois rupee",
                "currencySymbol": "SRe",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Indian/Mahe",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "SCT",
                    "tzName": "Seychelles Time"
                  }
                ]
              },
              {
                "name": "Sierra Leone",
                "iso3": "SLE",
                "iso2": "SL",
                "phoneCode": "232",
                "currency": "SLL",
                "currencyName": "Sierra Leonean leone",
                "currencySymbol": "Le",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Freetown",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Singapore",
                "iso3": "SGP",
                "iso2": "SG",
                "phoneCode": "65",
                "currency": "SGD",
                "currencyName": "Singapore dollar",
                "currencySymbol": "$",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Singapore",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "SGT",
                    "tzName": "Singapore Time"
                  }
                ]
              },
              {
                "name": "Sint Maarten (Dutch part)",
                "iso3": "SXM",
                "iso2": "SX",
                "phoneCode": "1721",
                "currency": "ANG",
                "currencyName": "Netherlands Antillean guilder",
                "currencySymbol": "ƒ",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Anguilla",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Slovakia",
                "iso3": "SVK",
                "iso2": "SK",
                "phoneCode": "421",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Bratislava",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Slovenia",
                "iso3": "SVN",
                "iso2": "SI",
                "phoneCode": "386",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Ljubljana",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Solomon Islands",
                "iso3": "SLB",
                "iso2": "SB",
                "phoneCode": "677",
                "currency": "SBD",
                "currencyName": "Solomon Islands dollar",
                "currencySymbol": "Si$",
                "region": "Oceania",
                "subRegion": "Melanesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Guadalcanal",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "SBT",
                    "tzName": "Solomon Islands Time"
                  }
                ]
              },
              {
                "name": "Somalia",
                "iso3": "SOM",
                "iso2": "SO",
                "phoneCode": "252",
                "currency": "SOS",
                "currencyName": "Somali shilling",
                "currencySymbol": "Sh.so.",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Mogadishu",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "South Africa",
                "iso3": "ZAF",
                "iso2": "ZA",
                "phoneCode": "27",
                "currency": "ZAR",
                "currencyName": "South African rand",
                "currencySymbol": "R",
                "region": "Africa",
                "subRegion": "Southern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Johannesburg",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "SAST",
                    "tzName": "South African Standard Time"
                  }
                ]
              },
              {
                "name": "South Georgia",
                "iso3": "SGS",
                "iso2": "GS",
                "phoneCode": "500",
                "currency": "GBP",
                "currencyName": "British pound",
                "currencySymbol": "£",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Atlantic/South_Georgia",
                    "gmtOffset": "-7200",
                    "gmtOffsetName": "UTC-02:00",
                    "abbreviation": "GST",
                    "tzName": "South Georgia and the South Sandwich Islands Time"
                  }
                ]
              },
              {
                "name": "South Korea",
                "iso3": "KOR",
                "iso2": "KR",
                "phoneCode": "82",
                "currency": "KRW",
                "currencyName": "Won",
                "currencySymbol": "₩",
                "region": "Asia",
                "subRegion": "Eastern Asia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Asia/Seoul",
                    "gmtOffset": "32400",
                    "gmtOffsetName": "UTC+09:00",
                    "abbreviation": "KST",
                    "tzName": "Korea Standard Time"
                  }
                ]
              },
              {
                "name": "South Sudan",
                "iso3": "SSD",
                "iso2": "SS",
                "phoneCode": "211",
                "currency": "SSP",
                "currencyName": "South Sudanese pound",
                "currencySymbol": "£",
                "region": "Africa",
                "subRegion": "Middle Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Juba",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "Spain",
                "iso3": "ESP",
                "iso2": "ES",
                "phoneCode": "34",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Ceuta",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  },
                  {
                    "zoneName": "Atlantic/Canary",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "WET",
                    "tzName": "Western European Time"
                  },
                  {
                    "zoneName": "Europe/Madrid",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Sri Lanka",
                "iso3": "LKA",
                "iso2": "LK",
                "phoneCode": "94",
                "currency": "LKR",
                "currencyName": "Sri Lankan rupee",
                "currencySymbol": "Rs",
                "region": "Asia",
                "subRegion": "Southern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Colombo",
                    "gmtOffset": "19800",
                    "gmtOffsetName": "UTC+05:30",
                    "abbreviation": "IST",
                    "tzName": "Indian Standard Time"
                  }
                ]
              },
              {
                "name": "Sudan",
                "iso3": "SDN",
                "iso2": "SD",
                "phoneCode": "249",
                "currency": "SDG",
                "currencyName": "Sudanese pound",
                "currencySymbol": ".س.ج",
                "region": "Africa",
                "subRegion": "Northern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Khartoum",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EAT",
                    "tzName": "Eastern African Time"
                  }
                ]
              },
              {
                "name": "Suriname",
                "iso3": "SUR",
                "iso2": "SR",
                "phoneCode": "597",
                "currency": "SRD",
                "currencyName": "Surinamese dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Paramaribo",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "SRT",
                    "tzName": "Suriname Time"
                  }
                ]
              },
              {
                "name": "Svalbard And Jan Mayen Islands",
                "iso3": "SJM",
                "iso2": "SJ",
                "phoneCode": "47",
                "currency": "NOK",
                "currencyName": "Norwegian Krone",
                "currencySymbol": "kr",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Arctic/Longyearbyen",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Swaziland",
                "iso3": "SWZ",
                "iso2": "SZ",
                "phoneCode": "268",
                "currency": "SZL",
                "currencyName": "Lilangeni",
                "currencySymbol": "E",
                "region": "Africa",
                "subRegion": "Southern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Mbabane",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "SAST",
                    "tzName": "South African Standard Time"
                  }
                ]
              },
              {
                "name": "Sweden",
                "iso3": "SWE",
                "iso2": "SE",
                "phoneCode": "46",
                "currency": "SEK",
                "currencyName": "Swedish krona",
                "currencySymbol": "kr",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Stockholm",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Switzerland",
                "iso3": "CHE",
                "iso2": "CH",
                "phoneCode": "41",
                "currency": "CHF",
                "currencyName": "Swiss franc",
                "currencySymbol": "CHf",
                "region": "Europe",
                "subRegion": "Western Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Zurich",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Syria",
                "iso3": "SYR",
                "iso2": "SY",
                "phoneCode": "963",
                "currency": "SYP",
                "currencyName": "Syrian pound",
                "currencySymbol": "LS",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Damascus",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Taiwan",
                "iso3": "TWN",
                "iso2": "TW",
                "phoneCode": "886",
                "currency": "TWD",
                "currencyName": "New Taiwan dollar",
                "currencySymbol": "$",
                "region": "Asia",
                "subRegion": "Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Taipei",
                    "gmtOffset": "28800",
                    "gmtOffsetName": "UTC+08:00",
                    "abbreviation": "CST",
                    "tzName": "China Standard Time"
                  }
                ]
              },
              {
                "name": "Tajikistan",
                "iso3": "TJK",
                "iso2": "TJ",
                "phoneCode": "992",
                "currency": "TJS",
                "currencyName": "Tajikistani somoni",
                "currencySymbol": "SM",
                "region": "Asia",
                "subRegion": "Central Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Dushanbe",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "TJT",
                    "tzName": "Tajikistan Time"
                  }
                ]
              },
              {
                "name": "Tanzania",
                "iso3": "TZA",
                "iso2": "TZ",
                "phoneCode": "255",
                "currency": "TZS",
                "currencyName": "Tanzanian shilling",
                "currencySymbol": "TSh",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Dar_es_Salaam",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "Thailand",
                "iso3": "THA",
                "iso2": "TH",
                "phoneCode": "66",
                "currency": "THB",
                "currencyName": "Thai baht",
                "currencySymbol": "฿",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Bangkok",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "ICT",
                    "tzName": "Indochina Time"
                  }
                ]
              },
              {
                "name": "The Bahamas",
                "iso3": "BHS",
                "iso2": "BS",
                "phoneCode": "+1-242",
                "currency": "BSD",
                "currencyName": "Bahamian dollar",
                "currencySymbol": "B$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Nassau",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Togo",
                "iso3": "TGO",
                "iso2": "TG",
                "phoneCode": "228",
                "currency": "XOF",
                "currencyName": "West African CFA franc",
                "currencySymbol": "CFA",
                "region": "Africa",
                "subRegion": "Western Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Lome",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "Tokelau",
                "iso3": "TKL",
                "iso2": "TK",
                "phoneCode": "690",
                "currency": "NZD",
                "currencyName": "New Zealand dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Polynesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Fakaofo",
                    "gmtOffset": "46800",
                    "gmtOffsetName": "UTC+13:00",
                    "abbreviation": "TKT",
                    "tzName": "Tokelau Time"
                  }
                ]
              },
              {
                "name": "Tonga",
                "iso3": "TON",
                "iso2": "TO",
                "phoneCode": "676",
                "currency": "TOP",
                "currencyName": "Tongan paʻanga",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Polynesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Tongatapu",
                    "gmtOffset": "46800",
                    "gmtOffsetName": "UTC+13:00",
                    "abbreviation": "TOT",
                    "tzName": "Tonga Time"
                  }
                ]
              },
              {
                "name": "Trinidad And Tobago",
                "iso3": "TTO",
                "iso2": "TT",
                "phoneCode": "+1-868",
                "currency": "TTD",
                "currencyName": "Trinidad and Tobago dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Port_of_Spain",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Tunisia",
                "iso3": "TUN",
                "iso2": "TN",
                "phoneCode": "216",
                "currency": "TND",
                "currencyName": "Tunisian dinar",
                "currencySymbol": "ت.د",
                "region": "Africa",
                "subRegion": "Northern Africa",
                "decimalUnit": 3,
                "timezones": [
                  {
                    "zoneName": "Africa/Tunis",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Turkey",
                "iso3": "TUR",
                "iso2": "TR",
                "phoneCode": "90",
                "currency": "TRY",
                "currencyName": "Turkish lira",
                "currencySymbol": "₺",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Istanbul",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "Turkmenistan",
                "iso3": "TKM",
                "iso2": "TM",
                "phoneCode": "993",
                "currency": "TMT",
                "currencyName": "Turkmenistan manat",
                "currencySymbol": "T",
                "region": "Asia",
                "subRegion": "Central Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Ashgabat",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "TMT",
                    "tzName": "Turkmenistan Time"
                  }
                ]
              },
              {
                "name": "Turks And Caicos Islands",
                "iso3": "TCA",
                "iso2": "TC",
                "phoneCode": "+1-649",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Grand_Turk",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America)"
                  }
                ]
              },
              {
                "name": "Tuvalu",
                "iso3": "TUV",
                "iso2": "TV",
                "phoneCode": "688",
                "currency": "AUD",
                "currencyName": "Australian dollar",
                "currencySymbol": "$",
                "region": "Oceania",
                "subRegion": "Polynesia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Funafuti",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "TVT",
                    "tzName": "Tuvalu Time"
                  }
                ]
              },
              {
                "name": "Uganda",
                "iso3": "UGA",
                "iso2": "UG",
                "phoneCode": "256",
                "currency": "UGX",
                "currencyName": "Ugandan shilling",
                "currencySymbol": "USh",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Kampala",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "EAT",
                    "tzName": "East Africa Time"
                  }
                ]
              },
              {
                "name": "Ukraine",
                "iso3": "UKR",
                "iso2": "UA",
                "phoneCode": "380",
                "currency": "UAH",
                "currencyName": "Ukrainian hryvnia",
                "currencySymbol": "₴",
                "region": "Europe",
                "subRegion": "Eastern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Kiev",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  },
                  {
                    "zoneName": "Europe/Simferopol",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "MSK",
                    "tzName": "Moscow Time"
                  },
                  {
                    "zoneName": "Europe/Uzhgorod",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  },
                  {
                    "zoneName": "Europe/Zaporozhye",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "EET",
                    "tzName": "Eastern European Time"
                  }
                ]
              },
              {
                "name": "United Arab Emirates",
                "iso3": "ARE",
                "iso2": "AE",
                "phoneCode": "971",
                "currency": "AED",
                "currencyName": "United Arab Emirates dirham",
                "currencySymbol": "إ.د",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Dubai",
                    "gmtOffset": "14400",
                    "gmtOffsetName": "UTC+04:00",
                    "abbreviation": "GST",
                    "tzName": "Gulf Standard Time"
                  }
                ]
              },
              {
                "name": "United Kingdom",
                "iso3": "GBR",
                "iso2": "GB",
                "phoneCode": "44",
                "currency": "GBP",
                "currencyName": "British pound",
                "currencySymbol": "£",
                "region": "Europe",
                "subRegion": "Northern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/London",
                    "gmtOffset": "0",
                    "gmtOffsetName": "UTC±00",
                    "abbreviation": "GMT",
                    "tzName": "Greenwich Mean Time"
                  }
                ]
              },
              {
                "name": "United States",
                "iso3": "USA",
                "iso2": "US",
                "phoneCode": "1",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Northern America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Adak",
                    "gmtOffset": "-36000",
                    "gmtOffsetName": "UTC-10:00",
                    "abbreviation": "HST",
                    "tzName": "Hawaii–Aleutian Standard Time"
                  },
                  {
                    "zoneName": "America/Anchorage",
                    "gmtOffset": "-32400",
                    "gmtOffsetName": "UTC-09:00",
                    "abbreviation": "AKST",
                    "tzName": "Alaska Standard Time"
                  },
                  {
                    "zoneName": "America/Boise",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Chicago",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Denver",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Detroit",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Indiana/Indianapolis",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Indiana/Knox",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Indiana/Marengo",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Indiana/Petersburg",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Indiana/Tell_City",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Indiana/Vevay",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Indiana/Vincennes",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Indiana/Winamac",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Juneau",
                    "gmtOffset": "-32400",
                    "gmtOffsetName": "UTC-09:00",
                    "abbreviation": "AKST",
                    "tzName": "Alaska Standard Time"
                  },
                  {
                    "zoneName": "America/Kentucky/Louisville",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Kentucky/Monticello",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Los_Angeles",
                    "gmtOffset": "-28800",
                    "gmtOffsetName": "UTC-08:00",
                    "abbreviation": "PST",
                    "tzName": "Pacific Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Menominee",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Metlakatla",
                    "gmtOffset": "-32400",
                    "gmtOffsetName": "UTC-09:00",
                    "abbreviation": "AKST",
                    "tzName": "Alaska Standard Time"
                  },
                  {
                    "zoneName": "America/New_York",
                    "gmtOffset": "-18000",
                    "gmtOffsetName": "UTC-05:00",
                    "abbreviation": "EST",
                    "tzName": "Eastern Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Nome",
                    "gmtOffset": "-32400",
                    "gmtOffsetName": "UTC-09:00",
                    "abbreviation": "AKST",
                    "tzName": "Alaska Standard Time"
                  },
                  {
                    "zoneName": "America/North_Dakota/Beulah",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/North_Dakota/Center",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/North_Dakota/New_Salem",
                    "gmtOffset": "-21600",
                    "gmtOffsetName": "UTC-06:00",
                    "abbreviation": "CST",
                    "tzName": "Central Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Phoenix",
                    "gmtOffset": "-25200",
                    "gmtOffsetName": "UTC-07:00",
                    "abbreviation": "MST",
                    "tzName": "Mountain Standard Time (North America"
                  },
                  {
                    "zoneName": "America/Sitka",
                    "gmtOffset": "-32400",
                    "gmtOffsetName": "UTC-09:00",
                    "abbreviation": "AKST",
                    "tzName": "Alaska Standard Time"
                  },
                  {
                    "zoneName": "America/Yakutat",
                    "gmtOffset": "-32400",
                    "gmtOffsetName": "UTC-09:00",
                    "abbreviation": "AKST",
                    "tzName": "Alaska Standard Time"
                  },
                  {
                    "zoneName": "Pacific/Honolulu",
                    "gmtOffset": "-36000",
                    "gmtOffsetName": "UTC-10:00",
                    "abbreviation": "HST",
                    "tzName": "Hawaii–Aleutian Standard Time"
                  }
                ]
              },
              {
                "name": "United States Minor Outlying Islands",
                "iso3": "UMI",
                "iso2": "UM",
                "phoneCode": "1",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Northern America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Pacific/Midway",
                    "gmtOffset": "-39600",
                    "gmtOffsetName": "UTC-11:00",
                    "abbreviation": "SST",
                    "tzName": "Samoa Standard Time"
                  },
                  {
                    "zoneName": "Pacific/Wake",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "WAKT",
                    "tzName": "Wake Island Time"
                  }
                ]
              },
              {
                "name": "Uruguay",
                "iso3": "URY",
                "iso2": "UY",
                "phoneCode": "598",
                "currency": "UYU",
                "currencyName": "Uruguayan peso",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Montevideo",
                    "gmtOffset": "-10800",
                    "gmtOffsetName": "UTC-03:00",
                    "abbreviation": "UYT",
                    "tzName": "Uruguay Standard Time"
                  }
                ]
              },
              {
                "name": "Uzbekistan",
                "iso3": "UZB",
                "iso2": "UZ",
                "phoneCode": "998",
                "currency": "UZS",
                "currencyName": "Uzbekistani soʻm",
                "currencySymbol": "лв",
                "region": "Asia",
                "subRegion": "Central Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Samarkand",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "UZT",
                    "tzName": "Uzbekistan Time"
                  },
                  {
                    "zoneName": "Asia/Tashkent",
                    "gmtOffset": "18000",
                    "gmtOffsetName": "UTC+05:00",
                    "abbreviation": "UZT",
                    "tzName": "Uzbekistan Time"
                  }
                ]
              },
              {
                "name": "Vanuatu",
                "iso3": "VUT",
                "iso2": "VU",
                "phoneCode": "678",
                "currency": "VUV",
                "currencyName": "Vanuatu vatu",
                "currencySymbol": "VT",
                "region": "Oceania",
                "subRegion": "Melanesia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Pacific/Efate",
                    "gmtOffset": "39600",
                    "gmtOffsetName": "UTC+11:00",
                    "abbreviation": "VUT",
                    "tzName": "Vanuatu Time"
                  }
                ]
              },
              {
                "name": "Vatican City State (Holy See)",
                "iso3": "VAT",
                "iso2": "VA",
                "phoneCode": "379",
                "currency": "EUR",
                "currencyName": "Euro",
                "currencySymbol": "€",
                "region": "Europe",
                "subRegion": "Southern Europe",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Europe/Vatican",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "CET",
                    "tzName": "Central European Time"
                  }
                ]
              },
              {
                "name": "Venezuela",
                "iso3": "VEN",
                "iso2": "VE",
                "phoneCode": "58",
                "currency": "VEF",
                "currencyName": "Bolívar",
                "currencySymbol": "Bs",
                "region": "Americas",
                "subRegion": "South America",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Caracas",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "VET",
                    "tzName": "Venezuelan Standard Time"
                  }
                ]
              },
              {
                "name": "Vietnam",
                "iso3": "VNM",
                "iso2": "VN",
                "phoneCode": "84",
                "currency": "VND",
                "currencyName": "Vietnamese đồng",
                "currencySymbol": "₫",
                "region": "Asia",
                "subRegion": "South-Eastern Asia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Asia/Ho_Chi_Minh",
                    "gmtOffset": "25200",
                    "gmtOffsetName": "UTC+07:00",
                    "abbreviation": "ICT",
                    "tzName": "Indochina Time"
                  }
                ]
              },
              {
                "name": "Virgin Islands (British)",
                "iso3": "VGB",
                "iso2": "VG",
                "phoneCode": "+1-284",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/Tortola",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Virgin Islands (US)",
                "iso3": "VIR",
                "iso2": "VI",
                "phoneCode": "+1-340",
                "currency": "USD",
                "currencyName": "United States dollar",
                "currencySymbol": "$",
                "region": "Americas",
                "subRegion": "Caribbean",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "America/St_Thomas",
                    "gmtOffset": "-14400",
                    "gmtOffsetName": "UTC-04:00",
                    "abbreviation": "AST",
                    "tzName": "Atlantic Standard Time"
                  }
                ]
              },
              {
                "name": "Wallis And Futuna Islands",
                "iso3": "WLF",
                "iso2": "WF",
                "phoneCode": "681",
                "currency": "XPF",
                "currencyName": "CFP franc",
                "currencySymbol": "₣",
                "region": "Oceania",
                "subRegion": "Polynesia",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Pacific/Wallis",
                    "gmtOffset": "43200",
                    "gmtOffsetName": "UTC+12:00",
                    "abbreviation": "WFT",
                    "tzName": "Wallis & Futuna Time"
                  }
                ]
              },
              {
                "name": "Western Sahara",
                "iso3": "ESH",
                "iso2": "EH",
                "phoneCode": "212",
                "currency": "MAD",
                "currencyName": "Moroccan Dirham",
                "currencySymbol": "MAD",
                "region": "Africa",
                "subRegion": "Northern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/El_Aaiun",
                    "gmtOffset": "3600",
                    "gmtOffsetName": "UTC+01:00",
                    "abbreviation": "WEST",
                    "tzName": "Western European Summer Time"
                  }
                ]
              },
              {
                "name": "Yemen",
                "iso3": "YEM",
                "iso2": "YE",
                "phoneCode": "967",
                "currency": "YER",
                "currencyName": "Yemeni rial",
                "currencySymbol": "﷼",
                "region": "Asia",
                "subRegion": "Western Asia",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Asia/Aden",
                    "gmtOffset": "10800",
                    "gmtOffsetName": "UTC+03:00",
                    "abbreviation": "AST",
                    "tzName": "Arabia Standard Time"
                  }
                ]
              },
              {
                "name": "Zambia",
                "iso3": "ZMB",
                "iso2": "ZM",
                "phoneCode": "260",
                "currency": "ZMW",
                "currencyName": "Zambian kwacha",
                "currencySymbol": "ZK",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 2,
                "timezones": [
                  {
                    "zoneName": "Africa/Lusaka",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "CAT",
                    "tzName": "Central Africa Time"
                  }
                ]
              },
              {
                "name": "Zimbabwe",
                "iso3": "ZWE",
                "iso2": "ZW",
                "phoneCode": "263",
                "currency": "ZWL",
                "currencyName": "Zimbabwe Dollar",
                "currencySymbol": "$",
                "region": "Africa",
                "subRegion": "Eastern Africa",
                "decimalUnit": 0,
                "timezones": [
                  {
                    "zoneName": "Africa/Harare",
                    "gmtOffset": "7200",
                    "gmtOffsetName": "UTC+02:00",
                    "abbreviation": "CAT",
                    "tzName": "Central Africa Time"
                  }
                ]
              }
            ]
          })
    }
    postPushNotification(data: Notification){
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.post(`${environment.apiUrl}/api/v1.0/schedules/notification`, data, { headers });
    }
    updatePushNotification(data: Notification, notificationId: string){
      const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
      return this.http.put(`${environment.apiUrl}/api/v1.0/schedules/notification/${notificationId}`, data, { headers });
  }
}

type Notification = {
        name: string,
        title: string,
        content: string,
        scheduledAt: string,
        callToAction: string,
        deviceType: string,
        toBeSentToIds: string[],
        status: string,
        iso3CountryCode: string,
        channelType: string,
        accountType: string,
        businessCategory: string,
        translations: Translation[]
}
type Translation = {
    code: string,
    name: string,
    value: string
}
export type Languages = {
    code: string,
    name: string,
    value: string
}
export type Channels = Languages;
export type Categories = {
    id: null | string,
    name: string,
    url: string,
    type: string,
    translations: string
};
