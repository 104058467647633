<div class="header navbar-fixed-top">
    <nav class="p-x-0">
      <div class="container-fluid p-x-0">
        <div class="navbar-header">
            <a class="navbar-brand pa-0" href="#"><span class="nav-toggle-bar"><img src="assets/images/logo.png" alt="MeeKet"></span></a>
            <h2>Engagement Overview</h2>
        </div>
        <ul class="nav navbar-nav right-nav pull-right">
            <li class="prof-dropdown">
                <div class="btn-group">
                    <a href="" data-toggle="dropdown" class="top-link dropdown-toggle">
                        <span>
                            <img src="assets/images/avatar.png" alt="" class="pull-left">
                            <!-- <i class="fa fa-user pull-left" aria-hidden="true"></i> -->
                        </span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                        <li><a (click)="logout()"><i class="fa fa-sign-out" aria-hidden="true"></i> Sign out</a></li>
                    </ul>
                </div>
            </li>
        </ul>
      </div>
    </nav>
</div>

<div class="sidenav menu-bar active">
    <a routerLink="/home" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/' || routeUrl === '/home'}">
        <span title="Dashboard"><i class="fa fa-tachometer" aria-hidden="true"></i> <span>Dashboard</span></span>
    </a>
    <a routerLink="/users" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/users'}">
        <span title="Users"><i class="fa fa-user" aria-hidden="true"></i> <span>Users</span></span>
    </a>
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/analytics' || routeUrl === '/engagement'}">
            <span title="Engagement">
                <i class="fa fa-pie-chart" aria-hidden="true"></i>
                <span>Engagement</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/analytics" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/analytics'}">
                <span title="Engagement Analytics"><i class="fa fa-line-chart" aria-hidden="true"></i> <span>Analytics</span></span>
            </a>
            <a routerLink="/engagement" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/engagement'}">
                <span title="Engagement Overview"><i class="fa fa-clock-o" aria-hidden="true"></i> <span>Overview</span></span>
            </a>
        </div>
    </div>
    <a routerLink="/trade" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/trade'}">
        <span title="Trade"><i class="fa fa-heart" aria-hidden="true"></i> <span>Trade</span></span>
    </a>
    <a routerLink="/social" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/social'}">
        <span title="Social"><i class="fa fa-dot-circle-o" aria-hidden="true"></i> <span>Social</span></span>
    </a>
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/post-report' || routeUrl === '/image-violation'}">
            <span title="Post">
                <i class="fa fa-square" aria-hidden="true"></i>
                <span>Post</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/post-report" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/post-report'}">
                <span title="Report"><i class="fa fa-file-archive-o" aria-hidden="true"></i> <span>Report</span></span>
            </a>
            <a href="#">
                <span title="Content"><i class="fa fa-file-text" aria-hidden="true"></i> <span>Content</span></span>
            </a>
            <a routerLink="/image-violation" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/image-violation'}">
                <span title="Image Violations"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Image Violations</span></span>
            </a>
        </div>
    </div>
</div>

<section id="intro" class="page-sec active">
    <div class="container-fluid p-x-0" style="width: 100%;">
        <div class="row">
            <div class="col-md-12">
                <div class="content-sec">
                    <div class="col-xs-12 cont-left-box">
                        <ul class="nav nav-pills cs-nav">
                          <li class="active">
                            <a data-toggle="pill" href="#home">
                                Average Engagement Overview
                                <h3>30m 20s</h3>
                            </a>
                        </li>
                          <li>
                            <a data-toggle="pill" href="#menu1">
                                Engaged sessions per user
                                <h3>2.6</h3>
                            </a>
                        </li>
                          <li>
                            <a data-toggle="pill" href="#menu2">
                                Average Engagement Overview
                                <h3>8m 35s</h3>
                            </a>
                        </li>
                        </ul>

                        <div class="tab-content">
                          <div id="home" class="tab-pane fade in active">
                            <div class="chart-box">
                                <div id="chartdiv" class="chartdiv">
                                    <div class="chart-wrapper">
                                        <canvas baseChart 
                                        [data]="pieChartData" 
                                        [labels]="pieChartLabels" 
                                        [chartType]="pieChartType"
                                        [options]="pieChartOptions"
                                        [plugins]="pieChartPlugins"
                                        [legend]="pieChartLegend">
                                      </canvas>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div id="menu1" class="tab-pane fade">
                            <div id="chartdiv2" class="chartdiv">
                                <div class="chart-wrapper">
                                    <canvas baseChart 
                                    [data]="pieChartData1" 
                                    [labels]="pieChartLabels1" 
                                    [chartType]="pieChartType1"
                                    [options]="pieChartOptions1"
                                    [plugins]="pieChartPlugins1"
                                    [legend]="pieChartLegend1">
                                  </canvas>
                                </div>
                            </div>
                          </div>
                          <div id="menu2" class="tab-pane fade">
                            <div id="chartdiv3" class="chartdiv">
                                <div class="chart-wrapper">
                                    <canvas baseChart 
                                    [data]="pieChartData2" 
                                    [labels]="pieChartLabels2" 
                                    [chartType]="pieChartType2"
                                    [options]="pieChartOptions2"
                                    [plugins]="pieChartPlugins2"
                                    [legend]="pieChartLegend2">
                                  </canvas>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal -->
<div id="analyticsModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Page Title Here...</h4>
            </div>
            <div class="modal-body">
            <ul class="nav nav-pills">
                <li class="active">
                    <a data-toggle="pill" href="#engTab1">
                        Average Engagement Overview
                        <h3>30m 20s</h3>
                    </a>
                </li>
                <li>
                    <a data-toggle="pill" href="#engTab2">
                        Engaged sessions per user
                        <h3>2.6</h3>
                    </a>
                </li>
                <li>
                    <a data-toggle="pill" href="#engTab3">
                        Average Engagement Overview
                        <h3>8m 35s</h3>
                    </a>
                </li>
            </ul>

            <div class="tab-content">
                <div id="engTab1" class="tab-pane fade in active">
                    <div class="chart-box" style="height: 200px; width: 400px;">
                        <h1>Chart 1</h1>
                        <div id="activeUsers"></div>
                    </div>
                </div>
                <div id="engTab2" class="tab-pane fade">
                    <h1>Chart 2</h1>
                </div>
                <div id="engTab3" class="tab-pane fade">
                    <h1>Chart 3</h1>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
    </div>

  </div>
</div>