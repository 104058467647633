import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export interface DialogData {
  message: string;
  title: string;
  formData: any;
}

@Component({
  selector: 'app-confirm-alert',
  templateUrl: './confirm-alert.component.html',
  styleUrls: ['./confirm-alert.component.less']
})
export class ConfirmAlertComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<ConfirmAlertComponent>) { }

  ngOnInit(): void {

  }

  Close() {
    this.dialogRef.close({flag: false, formData: this.data.formData});
  }

  Confirm() {
    this.dialogRef.close({flag: true, formData: this.data.formData});
  }

}
