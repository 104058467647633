import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class UsersService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    localData: any;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
        this.localData = JSON.parse(localStorage.getItem('user'));
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    getMembersList() {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v3.0/members?size=15&page=0&sortBy=memberId:desc`, { headers });
    }

    getMemberById(id) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v1.0/members/${id}`, { headers });
    }

    deleteMember(id) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.delete<User[]>(`${environment.apiUrl}/api/v1.0/members/${id}`, { headers });
    }

    getTotalUserCount() {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v1.0/dashboard?type=TOTAL_USER`, { headers });
    }

    getDashboardCount(type, filter, from) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v1.0/dashboard?type=${type}&subType=${filter}&periodType=${from}`, { headers });
    }

    getDashboardFilterCount(type, from) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v1.0/dashboard?type=${type}&periodType=${from}`, { headers });
    }

    getMembers(pageId) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v3.0/members?size=20&page=${pageId}&sortBy=memberId:desc`, { headers });
    }

    getMembersFilter(pageId, filter) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v3.0/members?size=20&page=${pageId}&query=${filter}`, { headers });
    }

    getList(page, pageId) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v2.0/listings?type=${page}&size=20&page=${pageId}`, { headers });
    }

    deleteListRow(id) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.delete<User[]>(`${environment.apiUrl}/api/v1.0/listings/${id}`, { headers });
    }

    getListingFilter(pageId, page, filter) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v2.0/listings?size=20&page=${pageId}&type=${page}&query=${filter}`, { headers });
    }

    getReport(pageId) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v2.0/listings?reportedCount=1&size=20&page=${pageId}`, { headers });
    }

    getImageVoilation(pageId) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v2.0/listings?moderatedPost=true&size=20&page=${pageId}`, { headers });
    }

    getAnalytics(pageId, firstDayString, lastDayString) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v1.0/analytics/screenEngagement?startDate=${firstDayString}&endDate=${lastDayString}&size=20&page=${pageId}`, { headers });
    }
    getBusinessbanners(pageId, firstDayString, lastDayString){
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User[]>(`${environment.apiUrl}/api/v1.0/analytics/screenEngagement?startDate=${firstDayString}&endDate=${lastDayString}&size=20&page=${pageId}`, { headers });
    }
    topSearch(term: string) {
        const headers = { 'Authorization': 'Bearer '+this.localData.bearerToken };
        return this.http.get<User>(`${environment.apiUrl}/api/v2.0/search/topSearch?includeMembers=true&query=${term}`, {headers});
    }


}