<div class="header navbar-fixed-top">
    <nav class="p-x-0">
        <div class="container-fluid p-x-0">
            <div class="navbar-header">
                <a class="navbar-brand pa-0" href="#"><span class="nav-toggle-bar"><img src="assets/images/logo.png"
                            alt="MeeKet"></span></a>
                <h2>Add Push Notification</h2>
            </div>
            <ul class="nav navbar-nav right-nav pull-right">
                <li class="prof-dropdown">
                    <div class="btn-group">
                        <a href="" data-toggle="dropdown" class="top-link dropdown-toggle">
                            <span>
                                <img src="assets/images/avatar.png" alt="" class="pull-left">
                                <!-- <i class="fa fa-user pull-left" aria-hidden="true"></i> -->
                            </span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" role="menu">
                            <li><a (click)="logout()"><i class="fa fa-sign-out" aria-hidden="true"></i> Sign out</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</div>

<div class="sidenav menu-bar active">
    <a routerLink="/home" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/' || routeUrl === '/home'}">
        <span title="Dashboard"><i class="fa fa-tachometer" aria-hidden="true"></i> <span>Dashboard</span></span>
    </a>
    <a routerLink="/users" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/users'}">
        <span title="Users"><i class="fa fa-user" aria-hidden="true"></i> <span>Users</span></span>
    </a>
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/analytics' || routeUrl === '/engagement'}">
            <span title="Engagement">
                <i class="fa fa-pie-chart" aria-hidden="true"></i>
                <span>Engagement</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/analytics" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/analytics'}">
                <span title="Engagement Analytics"><i class="fa fa-line-chart" aria-hidden="true"></i>
                    <span>Analytics</span></span>
            </a>
            <a routerLink="/engagement" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/engagement'}">
                <span title="Engagement Overview"><i class="fa fa-clock-o" aria-hidden="true"></i>
                    <span>Overview</span></span>
            </a>
        </div>
    </div>
    <a routerLink="/trade" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/trade'}">
        <span title="Trade"><i class="fa fa-heart" aria-hidden="true"></i> <span>Trade</span></span>
    </a>
    <a routerLink="/social" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/social'}">
        <span title="Social"><i class="fa fa-dot-circle-o" aria-hidden="true"></i> <span>Social</span></span>
    </a>
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/post-report' || routeUrl === '/image-violation'}">
            <span title="Post">
                <i class="fa fa-square" aria-hidden="true"></i>
                <span>Post</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/post-report" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/post-report'}">
                <span title="Report"><i class="fa fa-file-archive-o" aria-hidden="true"></i> <span>Report</span></span>
            </a>
            <a href="#">
                <span title="Content"><i class="fa fa-file-text" aria-hidden="true"></i> <span>Content</span></span>
            </a>
            <a routerLink="/image-violation" routerLinkActive="on"
                [ngClass]="{'active': routeUrl === '/image-violation'}">
                <span title="Image Violations"><i class="fa fa-file-image-o" aria-hidden="true"></i> <span>Image
                        Violations</span></span>
            </a>
        </div>

    </div>

    <!--- Deva-->
    <div class="submenu">
        <a href="javascript:;" [ngClass]="{'active': routeUrl === '/post-report' || routeUrl === '/image-violation'}">
            <span title="Banners">
                <i class="fa fa-folder-open" aria-hidden="true"></i>
                <span>Banners</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="dropdown">
            <a routerLink="/business-banners" routerLinkActive="on"
                [ngClass]="{'active': routeUrl === '/business-banners'}">
                <span title="Business-Banners"><i class="fa fa-file-image-o" aria-hidden="true"></i>
                    <span>Business-Banners</span></span>
            </a>
            <a routerLink="/trade-banners" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/trade-banners'}">
                <span title="Trade-Banners"><i class="fa fa-file-image-o" aria-hidden="true"></i>
                    <span>Trade-Banners</span></span>
            </a>
            <a routerLink="/social-banners" routerLinkActive="on"
                [ngClass]="{'active': routeUrl === '/social-banners'}">
                <span title="Social-Banners"><i class="fa fa-file-image-o" aria-hidden="true"></i>
                    <span>Social-Banners</span></span>
            </a>
        </div>
    </div>
    <a routerLink="/push-notification" routerLinkActive="on" [ngClass]="{'active': routeUrl === '/push-notification'}">
        <span title="Push NotiFication"><i class="fa fa-bell" aria-hidden="true"></i><span>NotiFication</span></span>
    </a>
    
</div>
<!-- <button (click)="router.navigate(['/master']);">
        <span>Go to New Page</span>
     </button> -->

     <section id="intro" class="page-sec active">
        <form [formGroup]="addEditForm" (ngSubmit)="onSubmit()">
            <div class="container" style="padding: 30px;border: 1px solid rgb(201, 201, 201);border-radius: 10px;">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="name">Name</label>
                            <input type="text" formControlName="name" class="form-control" placeholder="Enter name">
                            <!-- (f.content.touched || submitted) && f.content.errors?.required" -->
                            <mat-error *ngIf="f.name.errors?.required  && ( f.name.touched || submitted )">
                                Name is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="title">Notification Title</label>
                            <input type="text" formControlName="title" class="form-control"
                                placeholder="Enter Notification Title">
                            <mat-error *ngIf="f.title.errors?.required  && ( f.title.touched || submitted )">
                                Title is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="content">Notification Content (0/200)</label>
                            <textarea type="text" formControlName="content" class="form-control"
                                placeholder="Enter Notification Content"></textarea>
                            <mat-error *ngIf="f.content.errors?.required  && ( f.content.touched || submitted )">
                                Content is required
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="scheduledAt">Scheduled at</label>
                            <input class="form-control" type="datetime-local" formControlName="scheduledAt">
                            <mat-error *ngIf="f.scheduledAt.errors?.required  && ( f.scheduledAt.touched || submitted )">
                                Scheduled Time is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="callToAction">Call to action</label>
                            <ng-select [items]="callToActionType" bindLabel="name" bindValue="name"
                                formControlName="callToAction" placeholder="Select Call to Action Type"></ng-select>
                            <mat-error *ngIf="f.callToAction.errors?.required  && ( f.callToAction.touched || submitted )">
                                call To Action Type is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="toBeSentToIds">Target Users</label>
                            <!-- <ng-select [items]="toBeSentToIds" [(ngModel)]="selectedValue" (ngModelChange)="onValueChange($event)" bindLabel="name" bindValue="name" multiple="true"
                                    formControlName="toBeSentToIds" placeholder="Select Ids"></ng-select>
                                <mat-error *ngIf="f.toBeSentToIds.errors?.required  && ( f.toBeSentToIds.touched || submitted )">
                                    Target Users is required
                                </mat-error> -->
                            <input type="text" id="targetUsers" (input)="getTargetUsersList($event)" 
                                placeholder="Enter Target Users" class="form-control mt-1" maxlength="20" 
                                autocomplete="off">
                            <ul *ngIf="showDropdown" class="dropdown-menu searchList show p-2"
                                [ngClass]="{'searchList': toBeSentToIds?.length > 0, 'emptyList': toBeSentToIds?.length === 0}">
                                <div *ngIf="toBeSentToIds?.length > 0; else noSearchData">
                                    <div class="row mb-2" *ngFor="let item of toBeSentToIds"
                                        (click)="selectName(item)"
                                        onmouseover="this.style.backgroundColor='#fae2b7'; this.style.cursor='pointer';"
                                        onmouseout="this.style.backgroundColor='white';" style="margin: 3%;">
                                        <!-- <div class="col-md-3 pt-1">
                                            <img [src]="item?.profileImage ? item?.profileImage : 'assets/img/logo-square.svg'" style="width: 50px;">
                                         </div> -->
                                        <div class="col-md-9"  [ngClass]="{'active-li':isItemExists(item)}">
                                            <span class="fw-bold">{{ item?.name }}</span><br>
                                            <small class="text-muted">{{ item?.userName }}</small>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #noSearchData>
                                    <div class="p-2">
                                        <small class="text-muted">No Records Found</small>
                                    </div>
                                </ng-template>
                            </ul>
                        </div>
                        <div *ngIf="clickedOptions?.length > 0" class="options-container">
                            <b>Clicked Options:</b>
                            <div class="option-list">
                                <div *ngFor="let option of clickedOptions" class="option-item">
                                    {{ option?.name }}
                                    <span class="delete-option" (click)="removeOption(option)">&times;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="deviceType">Device Type</label>
                            <ng-select [items]="deviceType" bindLabel="name" bindValue="name" formControlName="deviceType"
                                placeholder="Select Device Type"></ng-select>
                            <mat-error *ngIf="f.deviceType.errors?.required  && ( f.deviceType.touched || submitted )">
                                Device Type is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="iso3CountryCode">Country</label>
                            <ng-select [items]="countries" bindLabel="name" bindValue="iso3"
                                formControlName="iso3CountryCode" placeholder="Select Country"></ng-select>
                            <mat-error *ngIf="f.iso3CountryCode.errors?.required  && ( f.iso3CountryCode.touched || submitted )">
                                Country is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="translations">Translations</label>
                            <ng-select [items]="languages" bindLabel="name" formControlName="translations"
                                placeholder="Select Languages"></ng-select>
                            <mat-error  *ngIf="f.translations.errors?.required  && ( f.translations.touched || submitted )">
                                Languages is required
                            </mat-error>
                        </div>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="form-group">
                            <label for="accountType">Account Type</label>
                            <ng-select [items]="accountType" bindLabel="name" bindValue="name" formControlName="accountType"
                                placeholder="Select Account Type"></ng-select>
                            <mat-error *ngIf="f.accountType.errors?.required  && ( f.accountType.touched || submitted )">
                                Account Type is required
                            </mat-error>
                        </div>
                    </div> -->
                </div>
                <div class="row">
                    <!-- <div class="col-md-4">
                        <div class="form-group">
                            <label for="channelType">Channel Type</label>
                            <ng-select [items]="channels" bindLabel="name" bindValue="name" formControlName="channelType"
                                placeholder="Select Channel Type"></ng-select>
                            <mat-error *ngIf="f.channelType.errors?.required  && ( f.channelType.touched || submitted )">
                                Channel Type is required
                            </mat-error>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-4">
                        <div class="form-group">
                            <label for="businessCategory">Business Category</label>
                            <ng-select [items]="categories" bindLabel="name" bindValue="name"
                                formControlName="businessCategory" placeholder="Select Business Category"></ng-select>
                            <mat-error *ngIf="f.businessCategory.errors?.required  && ( f.businessCategory.touched || submitted )">
                                Business Category is required
                            </mat-error>
                        </div>
                    </div> -->
                    
                <!-- </div>
                <div class="row"> -->
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="status">Status</label>
                            <ng-select [items]="status" bindLabel="name" bindValue="name" formControlName="status"
                                placeholder="Select Status"></ng-select>
                            <mat-error *ngIf="f.status.errors?.required  && ( f.status.touched || submitted )">
                                Status is required
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 text-right">
                        <div class="form-group">
                            <button type="submit" class="ms-1 btn btn-warning text-dark" style="background-color: #FFD14D; border: none;color: #000000;margin-right:5px;">SAVE</button>
                            <button (click)="cancel()" class="me-1 btn btn-secondary text-dark" style="background-color: #666666; border: none;color: #ffffff;">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- <div class="row">
            <div class="col-xs-12 text-right">
                <div class="form-group">
                    <input type="button" (click)="searchTargetUsers()" class="ms-1 btn btn-success" />
                </div>
            </div>
        </div> -->
    </section>